<div class="flex items-center">
  @for (breadcrumb of breadcrumbs; track i; let last = $last; let i = $index) {
    <div class="flex items-center">

      <span (click)="!last ? navigate(breadcrumb.route!) : null"
            class="text-caption cursor-pointer px-2.5 py-1.5 rounded-full transition-all duration-500"
            [ngClass]="[last ? 'text-black font-medium' : 'text-neutral-500 hover:bg-neutral-200/80']">
        {{ breadcrumb.title }}
      </span>

      @if (!last) {
        <span class="mx-2 text-caption text-neutral-400">
          <svg class="w-[16px] h-[16px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M0 0h24v24H0V0z" fill="none"/>
            <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" fill="currentColor"/>
        </svg>
        </span>
      }
    </div>
  }
</div>
