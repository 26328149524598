import {Component, inject, OnInit} from '@angular/core';
import {BaseDialogComponent} from "@shared/components/_base/base-dialog/base-dialog.component";
import {ButtonComponent} from "@shared/components/button/button.component";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {
  InputDateFormat,
  OutlineDateInputComponent
} from "@shared/inputs/outline-date-input/outline-date-input.component";
import {OutlineTextInputComponent} from "@shared/inputs/outline-text-input/outline-text-input.component";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {State} from "@shared/base/base-state";
import type {StartSapProgramRequest} from "@core/api/model";
import {BaseComponent} from "@shared/base/base-component";
import {ApiService} from "@core/api/api.service";
import {DialogRef} from "@angular/cdk/dialog";

@Component({
  selector: 'app-start-sap-program',
  imports: [
    BaseDialogComponent,
    ButtonComponent,
    OutlineDateInputComponent,
    OutlineTextInputComponent,
    ReactiveFormsModule
  ],
  templateUrl: './start-sap-program.component.html',
  standalone: true,
  styleUrl: './start-sap-program.component.scss'
})
export class StartSapProgramComponent extends BaseComponent implements OnInit {

  dialog = inject(MatDialog);
  data = inject(MAT_DIALOG_DATA);
  dialogRef = inject(MatDialogRef);
  formBuilder = inject(FormBuilder);
  apiService = inject(ApiService);

  createSAPProgramState = new State<any>();

  createSAPProgramForm!: FormGroup;

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.createSAPProgramForm = this.formBuilder.group({
      sapProgramStartDate: ["", Validators.required],
      sapProgramDuration: ["", Validators.required],
      testFrequency: ["", Validators.required],
      notes: ["", Validators.required]
    });
  }

  onCancelClicked() {
    this.dialog.closeAll();
  }

  onSaveClicked() {
    let request: StartSapProgramRequest = {
      driverId: this.data.driverId ?? '',
      duration: this.createSAPProgramForm.value.sapProgramDuration,
      frequency: this.createSAPProgramForm.value.testFrequency,
      startDate: this.createSAPProgramForm.value.sapProgramStartDate,
      notes: this.createSAPProgramForm.value.notes ?? ''
    }
    this.executeRequest({
      state: this.createSAPProgramState,
      request: this.apiService.startSapProgram(request),
      onSuccess: (response) => {
        this.dialogRef.close(true);
      },
      handleSuccess: true,
      successMessage: "SAP program started successfully"
    });
  }

  protected readonly InputDateFormat = InputDateFormat;
}
