import {Component, inject, input, OnInit} from '@angular/core';
import {BaseDialogComponent} from "@shared/components/_base/base-dialog/base-dialog.component";
import {FileUploaderComponent} from "@shared/inputs/file-uploader/file-uploader.component";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {ButtonComponent} from "@shared/components/button/button.component";
import {BaseComponent} from "@shared/base/base-component";
import {
  GetDriverDocumentListItem,
  UpdateDriverDocumentRequest, UpdateDriverDocumentResponse,
  UploadTempFileRequest, UploadTempFileResponse
} from "../../api/model";
import {State} from "@shared/base/base-state";
import {ApiService} from "../../api/api.service";
import {TokenStoreService} from "../../services/token/token-store.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DateUtils} from "@shared/utils/date-utils";
import {
  InputDateFormat,
  OutlineDateInputComponent
} from "@shared/inputs/outline-date-input/outline-date-input.component";
import {TextAreaInputComponent} from "@shared/inputs/text-area-input/text-area-input.component";

@Component({
  selector: 'app-document-upload',
  standalone: true,
  imports: [
    BaseDialogComponent,
    FileUploaderComponent,
    FormsModule,
    ReactiveFormsModule,
    ButtonComponent,
    OutlineDateInputComponent,
    TextAreaInputComponent,
  ],
  templateUrl: './document-upload.component.html',
  styleUrl: './document-upload.component.scss'
})
export class DocumentUploadComponent extends BaseComponent implements OnInit {

  formBuilder = inject(FormBuilder);
  apiService = inject(ApiService);
  tokenService = inject(TokenStoreService);
  data: GetDriverDocumentListItem = inject(MAT_DIALOG_DATA);
  dialogRef = inject(MatDialogRef);

  fileUploadForm!: FormGroup;

  attachFileState = new State<UploadTempFileResponse>();
  uploadState = new State<UpdateDriverDocumentResponse>();

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.fileUploadForm = this.formBuilder.group({
      fileAttachment: [null, Validators.required],
      issuedDate: [null, Validators.required],
      expiryDate: [null, Validators.required],
      completedDate: [null, Validators.required],
      reviewedDate: [null, Validators.required],
      notes: [null]
    });
  }

  onAttachFileClicked($event: File) {
    let request: UploadTempFileRequest = {
      file: $event
    }
    this.executeRequest(
      {
        state: this.attachFileState,
        request: this.apiService.uploadTempFile(request),
      }
    );
  }

  onCancelClicked() {
    this.dialogRef.close();
  }

  onUploadClicked() {
    let dateUtils = new DateUtils();
    let formValue = this.fileUploadForm.value;
    let request: UpdateDriverDocumentRequest = {
      driverId: this.data.driverId,
      driverDocumentTypeId: this.data.documentTypeId,
      filePath: this.attachFileState.response()?.filePath ?? '',
      completedDate: dateUtils.convertToDateTimeString(formValue.completedDate) ?? undefined,
      expiryDate: dateUtils.convertToDateTimeString(formValue.expiryDate) ?? undefined,
      issuedDate: dateUtils.convertToDateTimeString(formValue.issuedDate) ?? undefined,
      notes: formValue.notes,
    };

    this.executeRequest<UpdateDriverDocumentResponse>({
      state: this.uploadState,
      request: this.apiService.updateDriverDocument(request),
      onSuccess: (response) => {
        this.dialogRef.close(response.data);
      }
    });
  }

  protected readonly InputDateFormat = InputDateFormat;
}


