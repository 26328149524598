import {Component, inject, input, OnInit} from "@angular/core";
import {AppSvgIconComponent} from "@shared/components/app-svg-icon/app-svg-icon.component";
import {ButtonComponent} from "@shared/components/button/button.component";
import {OutlineTextInputComponent} from "@shared/inputs/outline-text-input/outline-text-input.component";
import {FileUploaderComponent} from "@shared/inputs/file-uploader/file-uploader.component";
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import {RadioButtonComponent} from "@shared/inputs/radio-button/radio-button.component";
import {BaseComponent} from "@shared/base/base-component";
import {CheckboxComponent} from "@shared/inputs/checkbox/checkbox.component";
import {State} from "@shared/base/base-state";
import {LookupService} from "@core/services/lookup/lookup.service";
import {ApiService} from "@core/api/api.service";
import {
  clearingHouseQueryFileUploadFrequencyResponse
} from "@core/services/driver-application/driver-application-hire/clearing-house-query-file-upload-frequency.response";
import {TokenStoreService} from "@core/services/token/token-store.service";
import {
  sapTestFrequencyResponse
} from "@core/services/driver-application/driver-application-hire/sap-test-frequency.response";
import {DateUtils} from "@shared/utils/date-utils";
import {
  GetCompanyResponse,
  GetDriverApplicationParams,
  GetDriverApplicationResponse,
  type MarkDriverApplicationHiredRequest,
  type MarkDriverApplicationHiredResponse,
  type SavePreHiringDriverApplicationInfoRequest,
  SavePreHiringDriverApplicationInfoResponse,
  UploadDriverApplicationFileRequest,
  UploadDriverApplicationFileRequestType,
  UploadDriverApplicationFileResponse
} from "@core/api/model";
import {
  sapTestDurationResponse
} from "@core/services/driver-application/driver-application-hire/sap-test-duration.response";
import {
  InputDateFormat,
  OutlineDateInputComponent
} from "@shared/inputs/outline-date-input/outline-date-input.component";
import {BaseInputComponent} from "@shared/inputs/_base/base-input/base-input.component";
import {printInvalidFields} from "@shared/utils/form-utils";

@Component({
  selector: "app-hire-driver",
  standalone: true,
  imports: [
    AppSvgIconComponent,
    ButtonComponent,
    FileUploaderComponent,
    FormsModule,
    ReactiveFormsModule,
    RadioButtonComponent,
    CheckboxComponent,
    OutlineDateInputComponent,
    BaseInputComponent,
    OutlineTextInputComponent
  ],
  templateUrl: "./hire-driver.component.html",
  styleUrl: "./hire-driver.component.scss",
})
export class HireDriverComponent extends BaseComponent implements OnInit {

  token = input<string>();
  applicationId = input<string>();
  validatorsCheck = input<boolean>(false);

  formBuilder = inject(FormBuilder);
  lookupService = inject(LookupService);
  apiService = inject(ApiService);
  tokenService = inject(TokenStoreService);

  companyInfoState = new State<GetCompanyResponse>();
  driverApplicationInfoState = new State<GetDriverApplicationResponse>();
  saveDriverHiringState = new State<SavePreHiringDriverApplicationInfoResponse>();
  clearingHouseQueryFileUploadFrequencyState = new State<clearingHouseQueryFileUploadFrequencyResponse>();
  sapTestFrequencyState = new State<sapTestFrequencyResponse>();
  clearingHouseQueryState = new State<UploadDriverApplicationFileResponse>();
  drugAlcoholPolicyState = new State<UploadDriverApplicationFileResponse>();
  drugTestResultState = new State<UploadDriverApplicationFileResponse>();
  drugTestCCFState = new State<UploadDriverApplicationFileResponse>();
  returnToDutyState = new State<UploadDriverApplicationFileResponse>();
  clearingHouseLimitedQueryPolicyState = new State<UploadDriverApplicationFileResponse>();
  dmvPrintOutOrMVRState = new State<UploadDriverApplicationFileResponse>();
  medicalExaminerCertificateState = new State<UploadDriverApplicationFileResponse>();
  screenshotOfNationalRegistryReferenceState = new State<UploadDriverApplicationFileResponse>();
  proficiencyTestResultState = new State<UploadDriverApplicationFileResponse>();
  employerPullNoticeState = new State<UploadDriverApplicationFileResponse>();
  spabCertificateState = new State<UploadDriverApplicationFileResponse>();
  t01CertificateState = new State<UploadDriverApplicationFileResponse>();
  t02CertificateState = new State<UploadDriverApplicationFileResponse>();
  onHAZMATCertificateState = new State<UploadDriverApplicationFileResponse>();
  sapTestDurationState = new State<sapTestDurationResponse>();
  hireDriverState = new State<MarkDriverApplicationHiredResponse>();

  driverHiringForm!: FormGroup;


  ngOnInit() {
    this.initForm();
    this.handleDriverApplicationId();
    this.getCompanyInfo();
    this.getDriverApplicationInfo();
    this.getclearingHouseQueryFileUploadFrequency();
    this.getSapTestDuration();
    this.getSapTestFrequency();
  }

  private initForm() {
    this.driverHiringForm = this.formBuilder.group({
      preEmploymentClearingHouseFilePath: ["", Validators.required],
      preEmploymentClearingHouseExpiryDate: ["", Validators.required],
      preEmploymentClearingHouseCompletedDate: ["", Validators.required],
      preEmploymentClearingHouseUploadFrequency: ["", Validators.required],
      driverTransportsHazmat: [false, Validators.required],
      drugAndAlcoholPolicyFilePath: ["", Validators.required],
      drugAndAlcoholPolicyDate: ["", Validators.required],
      drugTestResultFilePath: ["", Validators.required],
      dmvPrintOutOrMVRFilePath: ["", Validators.required],
      drugTestResultCompletedDate: ["", Validators.required],
      drugTestCCFFilePath: ["", Validators.required],
      drugTestCCFCompletedDate: ["", Validators.required],
      mroVerificationDate: ["", Validators.required],
      isDrugTestResultPositive: [false, Validators.required],
      isUnderSapProgram: [false],
      sapTestFrequency: [0, Validators.required],
      sapTestDuration: [0, Validators.required],
      returnToDutyFilePath: ["", Validators.required],
      clearingHouseLimitedQueryPolicyFilePath: ["", Validators.required],
      clearingHouseLimitedQueryPolicyDate: ["", Validators.required],
      clearingHouseLimitedQueryPolicyExpiryDate: ["", Validators.required],
      medicalExaminerCertificateFilePath: ["", Validators.required],
      medicalExaminerNationalRegistryScreenshotFilePath: ["", Validators.required],
      medicalExaminerCertificateExpirationDate: ["", Validators.required],
      proficiencyTestResultFilePath: ["", Validators.required],
      employerPullNoticeFilePath: ["", Validators.required],
      hasSPABCertificate: [false, Validators.required],
      hasStartedSAPProgram: [false, Validators.required],
      spabCertificateFilePath: [""],
      spabCertificateIssuedDate: [""],
      t01CertificateFilePath: [""],
      t01CertificateIssuedDate: [""],
      t02CertificateFilePath: [""],
      t02CertificateIssuedDate: [""],
      hazmatCertificateFilePath: ["", Validators.required],
      hazmatCertificateIssuedDate: ["", Validators.required],
      hazmatCertificateExpiryDate: ["", Validators.required],
      confirmationOfRequirements: ["", Validators.required],
    });
    this.checkConditionAndUpdateValidators();
  }


  checkConditionAndUpdateValidators() {
    if (this.validatorsCheck()) {
      this.driverHiringForm.get('sapTestFrequency')?.setValidators([Validators.required]);
      this.driverHiringForm.get('sapTestDuration')?.setValidators([Validators.required]);
      this.driverHiringForm.get('hasSPABCertificate')?.setValidators([Validators.required]);
      this.driverHiringForm.get('spabCertificateFilePath')?.setValidators([Validators.required]);
      this.driverHiringForm.get('spabCertificateIssuedDate')?.setValidators([Validators.required]);
      this.driverHiringForm.get('t01CertificateFilePath')?.setValidators([Validators.required]);
      this.driverHiringForm.get('t01CertificateIssuedDate')?.setValidators([Validators.required]);
      this.driverHiringForm.get('t02CertificateFilePath')?.setValidators([Validators.required]);
      this.driverHiringForm.get('t02CertificateIssuedDate')?.setValidators([Validators.required]);
      this.driverHiringForm.get('proficiencyTestResultFilePath')?.setValidators([Validators.required]);
    } else {
      this.driverHiringForm.get('sapTestFrequency')?.clearValidators();
      this.driverHiringForm.get('sapTestDuration')?.clearValidators();
      this.driverHiringForm.get('hasSPABCertificate')?.clearValidators();
      this.driverHiringForm.get('spabCertificateFilePath')?.clearValidators();
      this.driverHiringForm.get('spabCertificateIssuedDate')?.clearValidators();
      this.driverHiringForm.get('t01CertificateFilePath')?.clearValidators();
      this.driverHiringForm.get('t01CertificateIssuedDate')?.clearValidators();
      this.driverHiringForm.get('t02CertificateFilePath')?.clearValidators();
      this.driverHiringForm.get('t02CertificateIssuedDate')?.clearValidators();
      this.driverHiringForm.get('proficiencyTestResultFilePath')?.clearValidators();
    }
    this.driverHiringForm.updateValueAndValidity();
  }


  private getDriverApplicationInfo() {

    let request: GetDriverApplicationParams = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
    };
    this.executeRequest<GetDriverApplicationResponse>({
      request: this.apiService.getDriverApplication(request),
      state: this.driverApplicationInfoState,
      onSuccess: response => {
        let data = response.data;
        let dateUtils = new DateUtils();
        let dmvPrintout = dateUtils.convertToMMDDYYYY(data.dmvPrintOutExpiryDate);
        let preEmploymentClearingHouseExpirationDate = dateUtils.convertToMMDDYYYY(data.preEmploymentClearingHouseExpiryDate);
        let preEmploymentClearingHouseCompleteDate = dateUtils.convertToMMDDYYYY(data.preEmploymentClearingHouseCompletedDate);
        let drugAndAlcoholDate = dateUtils.convertToMMDDYYYY(data.drugAndAlcoholPolicyDate);
        let drugTestResultCompleteDate = dateUtils.convertToMMDDYYYY(data.drugTestResultCompletedDate);
        let drugTestCCFCompleteDate = dateUtils.convertToMMDDYYYY(data.drugTestCCFCompletedDate);
        let clearingHouseLimitedQueryDate = dateUtils.convertToMMDDYYYY(data.clearingHouseLimitedQueryPolicyDate);
        let clearingHouseLimitedQueryPolicyDate = dateUtils.convertToMMDDYYYY(data.clearingHouseLimitedQueryPolicyExpiryDate);
        let mroVerifyDate = dateUtils.convertToMMDDYYYY(data.mroVerificationDate);
        let medicalExaminerCertificateExpiryDate = dateUtils.convertToMMDDYYYY(data.medicalExaminerCertificateExpiryDate);
        let spabCertificateIssueDate = dateUtils.convertToMMDDYYYY(data.spabCertificateIssuedDate);
        let t01CertificateIssueDate = dateUtils.convertToMMDDYYYY(data.t01CertificateIssuedDate);
        let t02CertificateIssueDate = dateUtils.convertToMMDDYYYY(data.t02CertificateIssuedDate);
        let hazmatCertificateIssueDate = dateUtils.convertToMMDDYYYY(data.hazmatCertificateIssuedDate);
        let hazmatCertificateExpirationDate = dateUtils.convertToMMDDYYYY(data.hazmatCertificateExpiryDate);
        let medicalCardExpirationDate = dateUtils.convertToMMDDYYYY(data.medicalCardExpirationDate)!;

        this.driverHiringForm.controls["preEmploymentClearingHouseFilePath"].setValue(data.preEmploymentClearingHouseFilePath);
        this.driverHiringForm.controls["preEmploymentClearingHouseExpiryDate"].setValue(preEmploymentClearingHouseExpirationDate);
        this.driverHiringForm.controls["preEmploymentClearingHouseCompletedDate"].setValue(preEmploymentClearingHouseCompleteDate);
        this.driverHiringForm.controls["preEmploymentClearingHouseUploadFrequency"].setValue(data.preEmploymentClearingHouseUploadFrequency);
        this.driverHiringForm.controls["drugAndAlcoholPolicyFilePath"].setValue(data.drugAndAlcoholPolicyFilePath);
        this.driverHiringForm.controls["drugAndAlcoholPolicyDate"].setValue(drugAndAlcoholDate);
        this.driverHiringForm.controls["drugTestResultFilePath"].setValue(data.drugTestResultFilePath);
        this.driverHiringForm.controls["drugTestResultCompletedDate"].setValue(drugTestResultCompleteDate);
        this.driverHiringForm.controls["drugTestCCFFilePath"].setValue(data.drugTestCCFFilePath);
        this.driverHiringForm.controls["drugTestCCFCompletedDate"].setValue(drugTestCCFCompleteDate);
        this.driverHiringForm.controls["sapTestFrequency"].setValue(data.sapTestFrequency);
        this.driverHiringForm.controls["returnToDutyFilePath"].setValue(data.returnToDutyFilePath);
        this.driverHiringForm.controls["clearingHouseLimitedQueryPolicyFilePath"].setValue(data.clearingHouseLimitedQueryPolicyFilePath);
        this.driverHiringForm.controls["clearingHouseLimitedQueryPolicyDate"].setValue(clearingHouseLimitedQueryDate);
        this.driverHiringForm.controls["clearingHouseLimitedQueryPolicyExpiryDate"].setValue(clearingHouseLimitedQueryPolicyDate);
        this.driverHiringForm.controls["dmvPrintOutOrMVRFilePath"].setValue(data.dmvPrintOutOrMVRFilePath);
        this.driverHiringForm.controls["mroVerificationDate"].setValue(mroVerifyDate);
        this.driverHiringForm.controls["medicalExaminerCertificateFilePath"].setValue(data.dotMedicalCertificateFilePath);
        this.driverHiringForm.controls["medicalExaminerNationalRegistryScreenshotFilePath"].setValue(data.medicalExaminerNationalRegistryScreenshotFilePath);
        this.driverHiringForm.controls["medicalExaminerCertificateExpiryDate"].setValue(medicalExaminerCertificateExpiryDate);
        this.driverHiringForm.controls["proficiencyTestResultFilePath"].setValue(data.proficiencyTestResultFilePath);
        this.driverHiringForm.controls["employerPullNoticeFilePath"].setValue(data.employerPullNoticeFilePath);
        this.driverHiringForm.controls["hasSPABCertificate"].setValue(data.hasSPABCertificate);
        this.driverHiringForm.controls["spabCertificateFilePath"].setValue(data.spabCertificateFilePath);
        this.driverHiringForm.controls["spabCertificateIssuedDate"].setValue(spabCertificateIssueDate);
        this.driverHiringForm.controls["t01CertificateFilePath"].setValue(data.t01CertificateFilePath);
        this.driverHiringForm.controls["t01CertificateIssuedDate"].setValue(t01CertificateIssueDate);
        this.driverHiringForm.controls["t02CertificateFilePath"].setValue(data.t02CertificateFilePath);
        this.driverHiringForm.controls["t02CertificateIssuedDate"].setValue(t02CertificateIssueDate);
        this.driverHiringForm.controls["driverTransportsHazmat"].setValue(data.driverTransportsHazmat);
        this.driverHiringForm.controls["hazmatCertificateFilePath"].setValue(data.hazmatCertificateFilePath);
        this.driverHiringForm.controls["hazmatCertificateIssuedDate"].setValue(hazmatCertificateIssueDate);
        this.driverHiringForm.controls["hazmatCertificateExpiryDate"].setValue(hazmatCertificateExpirationDate);
        this.driverHiringForm.controls["medicalCardExpirationDate"].setValue(medicalExaminerCertificateExpiryDate);
        this.driverHiringForm.controls["isUnderSapProgram"].setValue(data.isUnderSapProgram);
        this.driverHiringForm.controls["drugAndAlcoholPolicyFilePath"].setValue(data.drugAndAlcoholPolicyFilePath);
      }
    });
  }

  onStartProficiencyTest() {
    this.router.navigate(["/proficiency-test"]);
  }

  private handleDriverApplicationId() {
    if (this.token() != null) {
      this.handleToken();
    } else if (this.applicationId() != null) {
      this.tokenService.saveDriverApplicationId(this.applicationId()!);
    }
  }

  handleToken() {
    let isValidToken = this.tokenService.isValidJWTToken(this.token());
    if (isValidToken) {
      this.tokenService.saveDriverApplicationToken(this.token());
    } else {
      this.toasterService.error("Invalid Driver Application");
      this.router.navigate(["/login"]);
    }
  }

  getCompanyInfo() {
    this.executeRequest<GetCompanyResponse>({
      state: this.companyInfoState,
      request: this.apiService.getCompany(),
      onSuccess: response => {
        let data = response.data;
        this.driverHiringForm.controls['driverTransportsHazmat'].setValue(data.transportsHazmat);
      }
    });
  }

  getclearingHouseQueryFileUploadFrequency() {
    this.executeRequest({
      state: this.clearingHouseQueryFileUploadFrequencyState,
      request: this.lookupService.getClearingHouseQueryFileUploadFrequency(),
    });
  }

  getSapTestFrequency() {
    this.executeRequest({
      state: this.sapTestFrequencyState,
      request: this.lookupService.getSapTestFrequency(),
    });
  }

  getSapTestDuration() {
    this.executeRequest(
      {
        state: this.sapTestDurationState,
        request: this.lookupService.getSapTestDuration()
      }
    )
  }

  onClearingHouseFileSelected($event: File) {

    let id = this.tokenService.getDriverApplicationId();
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: id,
      file: $event,
      type: UploadDriverApplicationFileRequestType.ClearingHouseQuery,
    };
    this.executeRequest<UploadDriverApplicationFileResponse>({
      request: this.apiService.uploadDriverApplicationFile(request),
      state: this.clearingHouseQueryState,
    });
  }


  onDrugAlcoholPolicyFileSelected($event: File) {
    let id = this.tokenService.getDriverApplicationId();
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: id,
      file: $event,
      type: UploadDriverApplicationFileRequestType.DrugAlcoholPolicy,
    };
    this.executeRequest<UploadDriverApplicationFileResponse>(
      {
        state: this.drugAlcoholPolicyState,
        request: this.apiService.uploadDriverApplicationFile(request),
      }
    );
  }

  onDrugTestResultFileSelected($event: File) {
    let id = this.tokenService.getDriverApplicationId();
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: id,
      file: $event,
      type: UploadDriverApplicationFileRequestType.DrugTestResult,
    };
    this.executeRequest<UploadDriverApplicationFileResponse>(
      {
        state: this.drugTestResultState,
        request: this.apiService.uploadDriverApplicationFile(request),
      }
    );
  }

  onDrugTestCCFFileSelected($event: File) {
    let id = this.tokenService.getDriverApplicationId();
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: id,
      file: $event,
      type: UploadDriverApplicationFileRequestType.DrugTestCCF,
    };
    this.executeRequest<UploadDriverApplicationFileResponse>(
      {
        state: this.drugTestCCFState,
        request: this.apiService.uploadDriverApplicationFile(request),
      }
    );
  }

  onReturnToDutyFileSelected($event: File) {
    let id = this.tokenService.getDriverApplicationId();
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: id,
      file: $event,
      type: UploadDriverApplicationFileRequestType.ReturnToDuty,
    };
    this.executeRequest<UploadDriverApplicationFileResponse>(
      {
        state: this.returnToDutyState,
        request: this.apiService.uploadDriverApplicationFile(request),
      }
    );
  }

  onClearingHouseLimitedQueryPolicyFileSelected($event: File) {
    let id = this.tokenService.getDriverApplicationId();
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: id,
      file: $event,
      type: UploadDriverApplicationFileRequestType.ClearingHouseLimitedQueryPolicy,
    };
    this.executeRequest<UploadDriverApplicationFileResponse>(
      {
        state: this.clearingHouseLimitedQueryPolicyState,
        request: this.apiService.uploadDriverApplicationFile(request),
      }
    );
  }

  onDMVPrintOutOrMVRFileSelected($event: File) {
    let id = this.tokenService.getDriverApplicationId();
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: id,
      file: $event,
      type: UploadDriverApplicationFileRequestType.DMVPrintOutOrMVR,
    };
    this.executeRequest<UploadDriverApplicationFileResponse>(
      {
        state: this.dmvPrintOutOrMVRState,
        request: this.apiService.uploadDriverApplicationFile(request),
      }
    );
  }


  onMedicalExaminerCertificateFileSelected($event: File) {
    let id = this.tokenService.getDriverApplicationId();
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: id,
      file: $event,
      type: UploadDriverApplicationFileRequestType.DOTMedicalCertificate,
    };
    this.executeRequest<UploadDriverApplicationFileResponse>(
      {
        state: this.medicalExaminerCertificateState,
        request: this.apiService.uploadDriverApplicationFile(request),
      }
    );
  }

  onScreenshotOfNationalRegistryReferenceFileSelected($event: File) {
    let id = this.tokenService.getDriverApplicationId();
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: id,
      file: $event,
      type: UploadDriverApplicationFileRequestType.ScreenshotOfNationalRegistryReference,
    };
    this.executeRequest<UploadDriverApplicationFileResponse>(
      {
        state: this.screenshotOfNationalRegistryReferenceState,
        request: this.apiService.uploadDriverApplicationFile(request),
      }
    );
  }

  onProficiencyTestResultFileSelected($event: File) {
    let id = this.tokenService.getDriverApplicationId();
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: id,
      file: $event,
      type: UploadDriverApplicationFileRequestType.ProficiencyTestResult,
    };
    this.executeRequest<UploadDriverApplicationFileResponse>(
      {
        state: this.proficiencyTestResultState,
        request: this.apiService.uploadDriverApplicationFile(request),
      }
    );
  }

  onEmployerPullNoticeFileSelected($event: File) {
    let id = this.tokenService.getDriverApplicationId();
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: id,
      file: $event,
      type: UploadDriverApplicationFileRequestType.EmployerPullNotice,
    };
    this.executeRequest<UploadDriverApplicationFileResponse>(
      {
        state: this.employerPullNoticeState,
        request: this.apiService.uploadDriverApplicationFile(request),
      }
    );
  }

  onSPABCertificateFileSelected($event: File) {
    let id = this.tokenService.getDriverApplicationId();
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: id,
      file: $event,
      type: UploadDriverApplicationFileRequestType.SPABCertificate,
    };
    this.executeRequest<UploadDriverApplicationFileResponse>(
      {
        state: this.spabCertificateState,
        request: this.apiService.uploadDriverApplicationFile(request),
      }
    );
  }

  onT01CertificateFileSelected($event: File) {
    let id = this.tokenService.getDriverApplicationId();
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: id,
      file: $event,
      type: UploadDriverApplicationFileRequestType.T01Certificate,
    };
    this.executeRequest<UploadDriverApplicationFileResponse>(
      {
        state: this.t01CertificateState,
        request: this.apiService.uploadDriverApplicationFile(request),
      }
    );
  }

  onT02CertificateFileSelected($event: File) {
    let id = this.tokenService.getDriverApplicationId();
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: id,
      file: $event,
      type: UploadDriverApplicationFileRequestType.T02Certificate,
    };
    this.executeRequest<UploadDriverApplicationFileResponse>(
      {
        state: this.t02CertificateState,
        request: this.apiService.uploadDriverApplicationFile(request),
      }
    );
  }

  onHAZMATCertificateFileSelected($event: File) {
    let id = this.tokenService.getDriverApplicationId();
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: id,
      file: $event,
      type: UploadDriverApplicationFileRequestType.HAZMATCertificate,
    };
    this.executeRequest<UploadDriverApplicationFileResponse>({
      state: this.onHAZMATCertificateState,
      request: this.apiService.uploadDriverApplicationFile(request)
    });
  }

  onSaveChangesClicked() {
    let dateUtils = new DateUtils();
    let driverApplicationId = this.tokenService.getDriverApplicationId();

    let request: SavePreHiringDriverApplicationInfoRequest = {
      clearingHouseLimitedQueryPolicyDate: dateUtils.convertToDateTimeString(this.driverHiringForm.get('clearingHouseLimitedQueryPolicyDate')?.value ?? '')!,
      clearingHouseLimitedQueryPolicyExpiryDate: dateUtils.convertToDateTimeString(this.driverHiringForm.get('clearingHouseLimitedQueryPolicyExpiryDate')?.value ?? '')!,
      driverApplicationId: driverApplicationId,
      driverTransportsHazmat: this.driverHiringForm.get('driverTransportsHazmat')?.value ?? false,
      drugAndAlcoholPolicyDate: this.driverHiringForm.get('drugAndAlcoholPolicyDate')?.value ?? '',
      drugTestCCFCompletedDate: this.driverHiringForm.get('drugTestCCFCompletedDate')?.value ?? '',
      drugTestResultCompletedDate: this.driverHiringForm.get('drugTestResultCompletedDate')?.value ?? '',
      hasSPABCertificate: this.driverHiringForm.get('hasSPABCertificate')?.value ?? false,
      hasStartedSAPProgram: this.driverHiringForm.get('hasStartedSAPProgram')?.value ?? false,
      hazmatCertificateExpiryDate: this.driverHiringForm.get('hazmatCertificateExpiryDate')?.value ?? '',
      hazmatCertificateIssuedDate: this.driverHiringForm.get('hazmatCertificateIssuedDate')?.value ?? '',
      isDrugTestResultPositive: this.driverHiringForm.get('isDrugTestResultPositive')?.value ?? false,
      medicalExaminerCertificateExpiryDate: dateUtils.convertToDateTimeString(this.driverHiringForm.get('medicalExaminerCertificateExpiryDate')?.value ?? '')!,
      mroVerificationDate: dateUtils.convertToDateTimeString(this.driverHiringForm.get('mroVerificationDate')?.value ?? '')!,
      preEmploymentClearingHouseCompletedDate: dateUtils.convertToDateTimeString(this.driverHiringForm.get('preEmploymentClearingHouseCompletedDate')?.value ?? '')!,
      preEmploymentClearingHouseExpiryDate: dateUtils.convertToDateTimeString(this.driverHiringForm.get('preEmploymentClearingHouseExpiryDate')?.value ?? '')!,
      preEmploymentClearingHouseUploadFrequency: this.driverHiringForm.get('preEmploymentClearingHouseUploadFrequency')?.value ?? 0,
      sapTestDuration: this.driverHiringForm.get('sapTestDuration')?.value ?? 0,
      sapTestFrequency: this.driverHiringForm.get('sapTestFrequency')?.value ?? 0,
      spabCertificateIssuedDate: dateUtils.convertToDateTimeString(this.driverHiringForm.get('spabCertificateIssuedDate')?.value ?? '')!,
      t01CertificateIssuedDate: dateUtils.convertToDateTimeString(this.driverHiringForm.get('t01CertificateIssuedDate')?.value ?? '')!,
      t02CertificateIssuedDate: dateUtils.convertToDateTimeString(this.driverHiringForm.get('t02CertificateIssuedDate')?.value ?? '')!,
    };
    this.executeRequest({
      state: this.saveDriverHiringState,
      request: this.apiService.savePreHiringDriverApplicationInfo(request),
      handleSuccess: true,
      handleError: false,
    });
  }


  onHireDriverClicked() {
    // if (this.driverHiringForm.invalid) {
    //   return
    // }
    let driverApplicationId = this.tokenService.getDriverApplicationId()
    let request: MarkDriverApplicationHiredRequest = {
      driverApplicationId: driverApplicationId
    }
    this.executeRequest({
      state: this.hireDriverState,
      request: this.apiService.markDriverApplicationHired(request),
      successMessage: "Driver Hired Successfully",
      handleSuccess: true,
      handleError: false,
      onSuccess: response => {
        this.router.navigate(["/drivers"]);
      }
    });
  }

  protected readonly InputDateFormat = InputDateFormat;
}
