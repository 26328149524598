import {Component, inject, input, OnInit} from '@angular/core';
import {BaseDialogComponent} from "@shared/components/_base/base-dialog/base-dialog.component";
import {ButtonComponent} from "@shared/components/button/button.component";
import {OutlineTextInputComponent} from "@shared/inputs/outline-text-input/outline-text-input.component";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BaseComponent} from "@shared/base/base-component";
import {
  InputDateFormat,
  OutlineDateInputComponent
} from "@shared/inputs/outline-date-input/outline-date-input.component";
import type {
  CreateDriverReminderRequest,
  CreateDriverReminderResponse, GetDriverReminderListItem,
  UpdateDriverReminderRequest, UpdateDriverReminderResponse
} from "@core/api/model";
import {State} from "@shared/base/base-state";
import {ApiService} from "@core/api/api.service";
import {TokenStoreService} from "@core/services/token/token-store.service";
import {DateUtils} from "@shared/utils/date-utils";
import {TextAreaInputComponent} from "@shared/inputs/text-area-input/text-area-input.component";

@Component({
  selector: 'app-create-or-update-reminder',
  standalone: true,
  imports: [
    BaseDialogComponent,
    ButtonComponent,
    OutlineTextInputComponent,
    ReactiveFormsModule,
    MatDatepickerModule,
    OutlineDateInputComponent,
    TextAreaInputComponent
  ],
  templateUrl: './create-or-update-reminder.component.html',
  styleUrl: './create-or-update-reminder.component.scss'
})
export class CreateOrUpdateReminderComponent extends BaseComponent implements OnInit {
  formBuilder = inject(FormBuilder);
  dialogRef = inject(MatDialogRef);

  apiService = inject(ApiService);
  tokenService = inject(TokenStoreService);
  data?: { driverId: string, reminder?: GetDriverReminderListItem } = inject(MAT_DIALOG_DATA);

  createOrUpdateReminderForm!: FormGroup;

  createReminderState = new State<CreateDriverReminderResponse>();
  updateReminderState = new State<UpdateDriverReminderResponse>();

  protected readonly InputDateFormat = InputDateFormat;

  ngOnInit() {
    this.initForm();
  }


  private initForm() {
    let reminder = this.data?.reminder;

    let dateUtils = new DateUtils();
    let date = dateUtils.convertToMMDDYYYY(reminder?.reminderDate);
    let time = dateUtils.convertTohhmm(reminder?.reminderDate);

    console.log(time);

    this.createOrUpdateReminderForm = this.formBuilder.group({
      reminderDate: [date, Validators.required],
      reminderTime: [time, Validators.required],
      reminderMessage: [reminder?.description, Validators.required],
    });
  }

  onSaveClicked() {

    this.validateForm(this.createOrUpdateReminderForm);
    if (this.createOrUpdateReminderForm.invalid) {
      return;
    }

    if (this.data?.reminder) {
      this.update();
    } else {
      this.create();
    }
  }

  create() {
    let dateUtils = new DateUtils();

    let dateString = this.createOrUpdateReminderForm.controls['reminderDate'].value;
    let timeString = this.createOrUpdateReminderForm.controls['reminderTime'].value;

    const reminderDateTimeString = dateUtils.convertToDateTimeString(dateString, timeString);

    let request: CreateDriverReminderRequest = {
      driverId: this.data?.driverId ?? '',
      reminderDate: reminderDateTimeString ?? '',
      description: this.createOrUpdateReminderForm.controls['reminderMessage'].value
    };

    this.executeRequest({
      state: this.createReminderState,
      request: this.apiService.createDriverReminder(request),
      onSuccess: (response) => {
        this.dialogRef.close(true);
      }
    });
  }

  update(): void {
    let reminderId = this.data?.reminder?.id;
    let dateUtils = new DateUtils();

    let dateString = this.createOrUpdateReminderForm.controls['reminderDate'].value;
    let timeString = this.createOrUpdateReminderForm.controls['reminderTime'].value;

    const reminderDateTimeString = dateUtils.convertToDateTimeString(dateString, timeString);

    let id = reminderId;
    let request: UpdateDriverReminderRequest = {
      reminderDate: reminderDateTimeString ?? '',
      description: this.createOrUpdateReminderForm.controls['reminderMessage'].value
    }
    this.executeRequest({
      state: this.updateReminderState,
      request: this.apiService.updateDriverReminder(reminderId ?? '', request),
      onSuccess: (response) => {
        this.dialogRef.close(true);
      }
    });
  }

  closeDialog() {
    this.dialogRef.close(false);
  }
}
