import {
  Component,
  ViewChild,
  ElementRef,
  AfterViewInit,
  input,
  output,
} from "@angular/core";
import SignaturePad from "@core/components/signature-pad/utils/signature_pad";

@Component({
  selector: "app-signature-pad",
  templateUrl: "./signature-pad.component.html",
  standalone: true,
  styleUrls: ["./signature-pad.component.scss"],
  imports: []
})
export class SignaturePadComponent implements AfterViewInit {
  @ViewChild("canvas") canvasEl!: ElementRef;

  width = input<string>("500");
  height = input<string>("200");
  backgroundColor = input<string>("rgba(255,255,255,0");
  penColor = input<string>("black");

  signatureChanged = output<string>();
  signatureCleared = output<void>();

  signaturePad?: SignaturePad;


  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement, {
      backgroundColor: this.backgroundColor(),
      penColor: this.penColor(),
    });

    if(this.signatureChanged) {
      this.signaturePad.outputEmitter = this.signatureChanged;
    }
  }

  clearPad() {
    this.signaturePad!.clear();
    this.signatureCleared.emit();
  }


  protected readonly console = console;
}
