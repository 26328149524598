import {
  Component,
  inject,
  OnInit,
  output,
} from "@angular/core";
import {
  OutlineTextInputComponent
} from "@shared/inputs/outline-text-input/outline-text-input.component";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import {ButtonComponent} from "@shared/components/button/button.component";
import {FileUploaderComponent} from "@shared/inputs/file-uploader/file-uploader.component";
import {BaseComponent} from "@shared/base/base-component";
import {
  CompleteDriverApplicationRequest, type CompleteDriverApplicationResponse,
  type GetCompanyResponse, type GetDriverApplicationLicenseParams, type GetDriverApplicationLicenseResponse,
  GetDriverApplicationParams,
  GetDriverApplicationResponse,
  UploadDriverApplicationFileRequest,
  UploadDriverApplicationFileRequestType, UploadDriverApplicationFileResponse,
} from "@core/api/model";
import {State} from "@shared/base/base-state";
import {DriverApplicationService} from "@core/services/driver-application/driver-application.service";
import {TokenStoreService} from "@core/services/token/token-store.service";
import {DateUtils} from "@shared/utils/date-utils";
import {SignaturePadComponent} from "@core/components/signature-pad/signature-pad.component";
import {ApiService} from "@core/api/api.service";
import {CheckboxComponent} from "@shared/inputs/checkbox/checkbox.component";
import {printInvalidFields} from "@shared/utils/form-utils";
import {
  InputDateFormat,
  OutlineDateInputComponent
} from "@shared/inputs/outline-date-input/outline-date-input.component";

@Component({
  selector: "app-save-applicant-complete-application",
  standalone: true,
  imports: [
    OutlineTextInputComponent,
    ReactiveFormsModule,
    ButtonComponent,
    FileUploaderComponent,
    SignaturePadComponent,
    CheckboxComponent,
    OutlineDateInputComponent,
  ],
  templateUrl: "./save-applicant-complete-application.component.html",
  styleUrl: "./save-applicant-complete-application.component.scss",
})
export class SaveApplicantCompleteApplicationComponent extends BaseComponent implements OnInit {

  onNextClick = output<void>();

  formBuilder = inject(FormBuilder);
  driverApplicationService = inject(DriverApplicationService);
  tokenService = inject(TokenStoreService);
  apiService = inject(ApiService);

  completeApplicationForm!: FormGroup;

  driverApplicationInfoState = new State<GetDriverApplicationResponse>();
  uploadDmvPrintoutFileState = new State<UploadDriverApplicationFileResponse>();
  uploadDotMedicalCertificateFileState = new State<UploadDriverApplicationFileResponse>();
  uploadCurrentDrivingLicenseFileState = new State<UploadDriverApplicationFileResponse>();
  uploadCurrentDrivingLicenseFrontFileState = new State<UploadDriverApplicationFileResponse>();
  uploadCurrentDrivingLicenseBackFileState = new State<UploadDriverApplicationFileResponse>();
  uploadHazmatFileState = new State<UploadDriverApplicationFileResponse>();
  licensesState = new State<GetDriverApplicationLicenseResponse>();
  companyInfoState = new State<GetCompanyResponse>();
  completeApplicationState = new State<CompleteDriverApplicationResponse>();

  ngOnInit() {
    this.initForm();
    this.getDriverApplicationInfo();
    this.getLicenses();
    this.getCompanyInfo();
  }

  private initForm() {
    this.completeApplicationForm = this.formBuilder.group({
      licenseDotMedicalCertificateFileUrl: ["", Validators.required],
      dmvPrintoutFileUrl: ["", Validators.required],
      currentDrivingLicenseFrontFilePath: ["", Validators.required],
      currentDrivingLicenseBackFilePath: ["", Validators.required],
      licenseExpiryDate: ["", Validators.required],
      dmvPrintoutExpiryDate: ["", Validators.required],
      medicalCardExpirationDate: ["", Validators.required],
      isUnderSapProgram: [false, Validators.required],
      schoolBusFileURL: ["", Validators.required],
      schoolBusCertificateNumber: ["", Validators.required],
      schoolBusCertificateIssueDate: ["", Validators.required],
      schoolBusCertificateExpiryDate: ["", Validators.required],
      applicantName: ["", Validators.required],
      applicantSignatureDate: ["", Validators.required],
      signature: [""],
      hazmatFileURL: [""],
      acknowledgement: ["", Validators.required],
    });
  }

  private getDriverApplicationInfo() {
    let request: GetDriverApplicationParams = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
    };

    this.executeRequest<GetDriverApplicationResponse>({
      request: this.apiService.getDriverApplication(request),
      state: this.driverApplicationInfoState,
      onSuccess: response => {
        let data = response.data;
        let dateUtils = new DateUtils();
        let medicalCardExpiryDate = dateUtils.convertToMMDDYYYY(data.medicalCardExpirationDate);
        let signatureDate = dateUtils.convertToMMDDYYYY(data.applicantSignatureDate);
        let currentDrivingExpiryDate = dateUtils.convertToMMDDYYYY(data.drivingLicenseExpirationDate);
        let dmvPrintOutExpirationDate = dateUtils.convertToMMDDYYYY(data.dmvPrintOutExpiryDate);
        this.completeApplicationForm.controls['licenseDotMedicalCertificateFileUrl'].setValue(data.dotMedicalCertificateFilePath);
        this.completeApplicationForm.controls['dmvPrintoutFileUrl'].setValue(data.dmvPrintOutOrMVRFilePath);
        this.completeApplicationForm.controls['currentDrivingLicenseFrontFilePath'].setValue(data.currentDrivingLicenseFrontFilePath);
        this.completeApplicationForm.controls['currentDrivingLicenseBackFilePath'].setValue(data.currentDrivingLicenseBackFilePath);
        this.completeApplicationForm.controls['licenseExpiryDate'].setValue(currentDrivingExpiryDate);
        this.completeApplicationForm.controls['medicalCardExpirationDate'].setValue(medicalCardExpiryDate);
        this.completeApplicationForm.controls['hazmatFileURL'].setValue(data.hazmatCertificateFilePath);
        this.completeApplicationForm.controls['dmvPrintoutExpiryDate'].setValue(dmvPrintOutExpirationDate);
        this.completeApplicationForm.controls['isUnderSapProgram'].setValue(data.isUnderSapProgram);
        this.completeApplicationForm.controls['applicantName'].setValue(data.applicantName);
        this.completeApplicationForm.controls['applicantSignatureDate'].setValue(signatureDate)
        this.completeApplicationForm.controls['applicantSignature'].setValue(data.applicantSignature);
      }
    });
  }

  getCompanyInfo() {
    this.executeRequest<GetCompanyResponse>({
      state: this.companyInfoState,
      request: this.apiService.getCompany(),
    });
  }


  private getLicenses() {
    let request: GetDriverApplicationLicenseParams = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
    };
    this.executeRequest<GetDriverApplicationLicenseResponse>({
      state: this.licensesState,
      request: this.apiService.getDriverApplicationLicense(request),
      onSuccess: (response) => {
        let licenses = response.data ?? [];
        let currentLicense = licenses.find((license) => license.isCurrent);
        if (currentLicense) {
          let dateUtils = new DateUtils();
          let expiryDate = dateUtils.convertToMMDDYYYY(currentLicense.expirationDate);
          this.completeApplicationForm.controls['licenseExpiryDate'].setValue(expiryDate);
        }
      },
    });
  }

  onSignatureCleared() {
    this.completeApplicationForm.controls['applicantSignature'].setValue(null);
  }

  onSubmitClicked() {

    printInvalidFields(this.completeApplicationForm);
    if (this.completeApplicationForm.invalid) {
      return;
    }
    let dateUtils = new DateUtils();


    const drivingLicenseExpirationDate = this.completeApplicationForm.controls["licenseExpiryDate"].value;
    const signatureDate = this.completeApplicationForm.controls["applicantSignatureDate"].value;
    const medicalCardExpirationDate = this.completeApplicationForm.controls ["medicalCardExpirationDate"].value;

    let request: CompleteDriverApplicationRequest = {
      applicantName: this.completeApplicationForm.controls["applicantName"].value ?? '',
      dmvPrintoutExpiryDate: this.completeApplicationForm.controls["dmvPrintoutExpiryDate"].value ?? '',
      driverApplicationId: this.tokenService.getDriverApplicationId(),
      drivingLicenseExpirationDate: dateUtils.convertToDateTimeString(drivingLicenseExpirationDate) ?? '',
      medicalCardExpirationDate: dateUtils.convertToDateTimeString(medicalCardExpirationDate) ?? '',
      applicantSignature: this.completeApplicationForm.controls["applicantSignature"].value,
      applicantSignatureDate: dateUtils.convertToDateTimeString(signatureDate) ?? '',
    };

    this.executeRequest<CompleteDriverApplicationResponse>({
      state: this.completeApplicationState,
      request: this.apiService.completeDriverApplication(request),
      handleSuccess: true,
      onSuccess: (response => {
        this.router.navigate(['/login']);
      })
    });
  }

  onDMVPrintoutFileSelected($event: File) {

    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
      file: $event,
      type: UploadDriverApplicationFileRequestType.DMVPrintOutOrMVR
    };

    this.executeRequest<UploadDriverApplicationFileResponse>({
      request: this.apiService.uploadDriverApplicationFile(request),
      state: this.uploadDmvPrintoutFileState,
    });
  }


  onDotMedicalCertificatePrintoutFileSelected($event: File) {
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
      file: $event,
      type: UploadDriverApplicationFileRequestType.DOTMedicalCertificate
    };

    this.executeRequest<UploadDriverApplicationFileResponse>({
      request: this.apiService.uploadDriverApplicationFile(request),
      state: this.uploadDotMedicalCertificateFileState,
    });
  }

  onAttachCurrentDrivingLicenseFrontFileSelected($event: File) {
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
      file: $event,
      type: UploadDriverApplicationFileRequestType.CurrentDriverLicenseFront
    };

    this.executeRequest<UploadDriverApplicationFileResponse>({
      request: this.apiService.uploadDriverApplicationFile(request),
      state: this.uploadCurrentDrivingLicenseFrontFileState,
    });
  }

  onAttachCurrentDrivingLicenseBackFileSelected($event: File) {
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
      file: $event,
      type: UploadDriverApplicationFileRequestType.CurrentDriverLicenseBack
    };

    this.executeRequest<UploadDriverApplicationFileResponse>({
      request: this.apiService.uploadDriverApplicationFile(request),
      state: this.uploadCurrentDrivingLicenseBackFileState,
    });
  }

  onAttachHazmatCertificate($event: File) {
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
      file: $event,
      type: UploadDriverApplicationFileRequestType.HAZMATCertificate
    };

    this.executeRequest<UploadDriverApplicationFileResponse>({
      request: this.apiService.uploadDriverApplicationFile(request),
      state: this.uploadHazmatFileState,
    });
  }

  onSignatureSaved(signatureBase64: string) {
    this.completeApplicationForm.controls["signature"].setValue(signatureBase64);
  }

  onDrugAndAlcoholPolicyClicked() {
    this.router.navigate(['/drug-and-alcohol-policy-agreement']);
  }

  onClearingHousePolicyClicked() {
    this.router.navigate(['/clearinghouse-policy-agreement']);

  }

  protected readonly InputDateFormat = InputDateFormat;
}
