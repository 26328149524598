<app-base-dialog [title]="'Upload Test Result'">
  <div body>
    <form [formGroup]="uploadTestResultForm">
      <div class="flex flex-col gap-4">
        <app-outline-date-input formControlName="programTestDate" [label]="'Test Date'"
                                class="w-full" [fullWidth]="true"
                                [inputDateFormat]="InputDateFormat.mmddyyyy"></app-outline-date-input>
        <span class="text-body2">Please provide the drug test result</span>
        <div class="py-2 flex items-center gap-3">
          <app-radio-button formControlName="programTestResult" [groupName]="'programTestResult'" title="Negative"
                            [value]="'Negative'"></app-radio-button>
          <app-radio-button formControlName="programTestResult" [groupName]="'programTestResult'" title="Positive"
                            [value]="'Positive'"></app-radio-button>
        </div>
        <app-file-uploader formControlName="programTestDocumentFilePath"
                           label="Attach Drug Test Result" [isRequired]="true"    (fileSelected)="onAttachFileClicked($event)"
        ></app-file-uploader>
        <span class="text-body2">Notes</span>
        <textarea
          formControlName="notes"
          class="p-2 border rounded-md resize-none h-32 w-full"
          placeholder="Ex: Starting SAP program due to driver's positive drug test result."
        ></textarea>
      </div>
    </form>
  </div>
  <div actions>
    <div class="flex justify-end gap-2">
      <app-button [appearance]="'outline'" [buttonColor]="'border-primary-500'" (click)="onCancelClicked()">Cancel
      </app-button>
      <app-button (click)="onSubmitClicked()" [loading]="uploadTestResultState.loading()">Submit</app-button>
    </div>
  </div>
</app-base-dialog>




