 import { Injectable } from '@angular/core';
 import {BaseApiService} from "@shared/base/base-api.service";
 import {CountriesResponse} from "./countries/countries-response";
 import {ClassOfEquipmentResponse} from "./class-of-equipment/class-of-equipment-response";
 import {LicenseClassesResponse} from "./license-classes/license-classes-response";

@Injectable({
  providedIn: 'root'
})
export class CommonService extends BaseApiService{

  getCountries() {
    return this.fromFile<CountriesResponse>('assets/json/app-data/countries.json');
  }

  getClassOfEquipment() {
    return this.fromFile<ClassOfEquipmentResponse>('assets/json/app-data/class-of-equipment.json');
  }

  getLicenseClasses() {
    return this.fromFile<LicenseClassesResponse>('assets/json/app-data/license-classes.json');
  }
}
