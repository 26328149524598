<app-base-dialog [title]="'Create new user'">
  <div body>
    <form [formGroup]="createUserForm" class="flex flex-col gap-3">
      <app-outline-text-input formControlName="firstName" label="First Name" placeholder="Ex: John" class="w-full"
                              [fullWidth]="true"></app-outline-text-input>
      <app-outline-text-input formControlName="lastName" label="Last Name" placeholder="Ex: Bravo" class="w-full"
                              [fullWidth]="true"></app-outline-text-input>
      <app-outline-text-input formControlName="email" label="Email" placeholder="Ex: john.Bravo@gmail.com"
                              class="w-full" [fullWidth]="true"></app-outline-text-input>
      <app-outline-text-input formControlName="phoneNumber" label="Phone Number" placeholder="Ex: +1 123-456-78XX"
                              class="w-full" [fullWidth]="true"></app-outline-text-input>
      <app-single-selection-field
        formControlName="roleId"
        title="Role"
        [items]="roleListState.response()?.items ?? []"
        [display]="'name'"
        [value]="'id'"
        [noDataMessage]="'No items available'"
        [fullWidth]="true">
      </app-single-selection-field>
    </form>
  </div>
  <div actions>
    <app-button [fullWidth]="true" type="submit" [loading]="createUserState.loading()" (click)="onCreateUserClicked()">
      Create new user
    </app-button>
  </div>
</app-base-dialog>




