import {Component, inject} from '@angular/core';
import {AppSvgIconComponent} from "@shared/components/app-svg-icon/app-svg-icon.component";
import {JsonPipe, NgClass} from "@angular/common";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {TableCustomComponent} from "@shared/components/data-table/table-custom-component/table-custom.component";
import {
  GetDriverApplicationListItem,
  GetDriverApplicationListItemApplicationStatusEnum,
  MarkDriverApplicationRejectedRequest,
  MarkDriverApplicationRejectedResponse,
  MarkDriverApplicationUnderVerificationRequest,
  MarkDriverApplicationUnderVerificationResponse
} from "@core/api/model";
import {DriverApplication} from "@core/services/driver-application/application/driver-application-response";
import {
  DriverApplicationStatusMessageComponent
} from "../driver-application-status-message/driver-application-status-message.component";
import {Constants} from "@core/constants/constants";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {State} from "@shared/base/base-state";
import {TokenStoreService} from "@core/services/token/token-store.service";
import {ApiService} from "@core/api/api.service";
import {BaseRequestService} from "@shared/base/base-request.service";

@Component({
  selector: 'app-table-cell-driver-application-status',
  imports: [
    AppSvgIconComponent,
    MatMenuTrigger,
    NgClass,
    MatMenu,
    MatMenuItem,
  ],
  templateUrl: './table-cell-driver-application-status.component.html',
  styleUrl: './table-cell-driver-application-status.component.scss'
})
export class TableCellDriverApplicationStatusComponent extends TableCustomComponent<GetDriverApplicationListItem> {

  router = inject(Router);
  dialog = inject(MatDialog);
  requestService = inject(BaseRequestService);
  apiService = inject(ApiService);
  tokenService = inject(TokenStoreService);
  markApplicationUnderVerificationState = new State<MarkDriverApplicationUnderVerificationResponse>();

  markApplicationRejectedState = new State<MarkDriverApplicationRejectedResponse>();

  getApplicationStatus(applicationStatus: GetDriverApplicationListItemApplicationStatusEnum): DriverApplicationStatusAction[] {
    switch (applicationStatus) {
      case GetDriverApplicationListItemApplicationStatusEnum.Invited:
        return [
          DriverApplicationStatusAction.reject,
          DriverApplicationStatusAction.requestToResubmit,
        ];
      case GetDriverApplicationListItemApplicationStatusEnum.ApplicationStarted:
        return [
          DriverApplicationStatusAction.reject,
        ];
      case GetDriverApplicationListItemApplicationStatusEnum.DriverVerificationPending:
        return [
          DriverApplicationStatusAction.hire,
          DriverApplicationStatusAction.requestToResubmit,
          DriverApplicationStatusAction.reject
        ];
      case GetDriverApplicationListItemApplicationStatusEnum.NeedsApproval:
        return [
          DriverApplicationStatusAction.hire,
          DriverApplicationStatusAction.underVerification,
          DriverApplicationStatusAction.requestToResubmit,
          DriverApplicationStatusAction.reject
        ];

      case GetDriverApplicationListItemApplicationStatusEnum.UnderVerification:
        return [
          DriverApplicationStatusAction.hire,
          DriverApplicationStatusAction.reject
        ];
      case GetDriverApplicationListItemApplicationStatusEnum.ResubmissionRequested:
        return [
          DriverApplicationStatusAction.hire,
          DriverApplicationStatusAction.reVerificationPending,
          DriverApplicationStatusAction.underVerification,
          DriverApplicationStatusAction.reject
        ]
      case GetDriverApplicationListItemApplicationStatusEnum.ReVerificationPending:
        return [
          DriverApplicationStatusAction.hire,
          DriverApplicationStatusAction.requestToResubmit,
          DriverApplicationStatusAction.markUnderVerification,
          DriverApplicationStatusAction.reject
        ];
      case GetDriverApplicationListItemApplicationStatusEnum.Hired:
        return [
          DriverApplicationStatusAction.reject
        ];
      case GetDriverApplicationListItemApplicationStatusEnum.Rejected:
        return [];
      default:
        return [];

    }
  }

  onStatusChange(item: DriverApplication, status: string) {

    switch (status) {
      case DriverApplicationStatusAction.hire: {
        this.router.navigate(['/hire-driver'], {queryParams: {applicationId: item.id},});
        break;
      }
      case DriverApplicationStatusAction.reVerificationPending:

        break;
      case DriverApplicationStatusAction.underVerification: {
        this.markApplicationUnderVerification(item.id);
        break;
      }
      case DriverApplicationStatusAction.reject: {
        this.markApplicationRejected(item.id);
        break;
      }
    }

    if (status === "Invite") {
      item.applicationStatus = "Invited";
    } else if (status === "Hire") {
      item.applicationStatus = "Hired";
    } else if (status === "Reject") {
      item.applicationStatus = "Rejected";
      this.markApplicationRejected(item.id);
    } else if (status === "Under Verification") {
      item.applicationStatus = "Under Verification";
      this.markApplicationUnderVerification(item.id);
    }

    if (status === "Hire") {
      this.router.navigate(['/hire-driver'], {
        queryParams: {applicationId: item.id},
      });
    } else {
      this.dialog
        .open(DriverApplicationStatusMessageComponent, {
          ...Constants.defaultDialogConfig,
        })
        .afterClosed()
        .subscribe((isSuccess) => {
          if (isSuccess) {
            item.applicationStatus = status;
            this.actionPerformed.emit({item: item, actionKey: 'statusChange'});
          }
        });
    }
  }

  private markApplicationUnderVerification(driverApplicationId: string) {
    let request: MarkDriverApplicationUnderVerificationRequest = {
      driverApplicationId: this.tokenService.getDriverApplicationId()
    };
    this.requestService.executeRequest({
      state: this.markApplicationUnderVerificationState,
      request: this.apiService.markDriverApplicationUnderVerification(request)
    })
  }

  private markApplicationRejected(driverApplicationId: string) {
    let request: MarkDriverApplicationRejectedRequest = {
      driverApplicationId: this.tokenService.getDriverApplicationId()
    }
    this.requestService.executeRequest({
      state: this.markApplicationRejectedState,
      request: this.apiService.markDriverApplicationRejected(request)
    });
  }

  protected readonly GetDriverApplicationListItemApplicationStatus = GetDriverApplicationListItemApplicationStatusEnum;
}

enum DriverApplicationStatusAction {
  hire = 'Hire',
  underVerification = 'Under Verification',
  requestToResubmit = 'Request to Re-submit',
  reVerificationPending = 'Re-verification Pending',
  markUnderVerification = 'Mark under verification',
  reject = 'Reject',
}
