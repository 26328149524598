<div class="flex justify-center container mx-auto h-full w-full p-4">

  <div class="flex flex-col bg-white w-full rounded-2xl">

    <div class="flex items-center justify-between w-full px-4 py-4 mx-auto border-b border-neutral-100">
      <p class="text-body1 text-info-500">SAP Program</p>
      <app-button class="px-2"
                  appearance="outline"
                  [iconSrc]="'assets/icons/history.svg'"
                  (buttonClick)="onViewSAPProgramHistoryClicked()">View SAP Program History
      </app-button>
    </div>

    <div class="px-4 py-4">

      @if (getActiveSAPProgramState.loading()) {
        <div class="flex flex-col gap-3">
          <app-shimmer type="list"></app-shimmer>
          <app-shimmer type="list"></app-shimmer>
          <app-shimmer type="list"></app-shimmer>
        </div>
      }

      @if (getActiveSAPProgramState.success() && getActiveSAPProgramState.response()?.data == undefined) {
        <div class="flex justify-center mx-auto">
          <div class="flex flex-col items-center gap-4">
            <app-svg-icon [src]="'assets/icons/info.svg'" class="text-neutral-400"></app-svg-icon>
            <p class="text-caption text-neutral-500">There is no active SAP program for this driver at the moment.</p>
            <app-button class="px-6"
                        appearance="outline"
                        (buttonClick)="onStartSAPProgramClicked()">Start SAP Program
            </app-button>
          </div>
        </div>
      }

      @if (getActiveSAPProgramState.success() && getActiveSAPProgramState.response()?.data) {
        <div class="flex justify-between items-center border-b border-neutral-100">
          <div class="flex flex-col mx-4">
            <div class="flex justify-between text-neutral-500">
              <div class="w-1/5 px-5 text-body2 text-left text-nowrap">Code</div>
              <div class="w-1/5 px-5 text-body2 text-left text-nowrap">Started At</div>
              <div class="w-1/5 px-5 text-body2 text-left text-nowrap">Duration</div>
              <div class="w-1/5 px-5 text-body2 text-left text-nowrap">Frequency</div>
              <div class="w-1/5 px-5 text-body2 text-left text-nowrap">Next Test Date</div>
            </div>

            <div class="flex justify-between items-center">
              <div
                class="w-1/5 p-5 text-body2 text-nowrap">{{ getActiveSAPProgramState.response()?.data?.code ?? '' }}
              </div>
              <div class="w-1/5 p-5 text-body2 text-nowrap">
                <p>{{ getActiveSAPProgramState.response()?.data?.startDate | date:'dd/MM/yyyy' }}</p>
              </div>
              <div
                class="w-1/5 p-5 text-body2 text-nowrap">{{ getActiveSAPProgramState.response()?.data?.duration ?? '' }}
              </div>
              <div
                class="w-1/5 p-5 text-body2 text-nowrap">{{ getActiveSAPProgramState.response()?.data?.frequency ?? '' }}
              </div>
              <div class="w-1/5 p-5 text-body2 text-nowrap text-warning-500">
                {{ getActiveSAPProgramState.response()?.data?.nextTestDate | date:'dd/MM/yyyy' }}
              </div>
            </div>
          </div>
          <div class="flex gap-2">
            <app-button class="px-2"
                        appearance="outline"
                        [iconSrc]="'assets/icons/upload.svg'"
                        (buttonClick)="onUploadClicked()">Upload Test Result
            </app-button>
            <app-button class="px-2"
                        appearance="outline"
                        [outlineColor]="'border-error-500'"
                        [textButtonColor]="'text-error-500'"
                        (buttonClick)="onEndSapProgramClicked()">End Program
            </app-button>
          </div>
        </div>
      }

    </div>

    <div class="grow overflow-auto">

      @if (getActiveSAPProgramState.success() && getActiveSAPProgramState.response()?.data) {
        <app-data-table
          class="w-full flex-grow"
          [state]="getSapProgramTestResultListState"
          [columnDefs]="columnDefs"
          [enableColumnsConfig]="true"
          [enableSearch]="false">
        </app-data-table>
      }

    </div>
  </div>
</div>
