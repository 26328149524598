import {Component, inject, OnInit} from '@angular/core';
import {
  InputDateFormat,
} from "@shared/inputs/outline-date-input/outline-date-input.component";
import {BaseDialogComponent} from "@shared/components/_base/base-dialog/base-dialog.component";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {OutlineTextInputComponent} from "@shared/inputs/outline-text-input/outline-text-input.component";
import {ButtonComponent} from "@shared/components/button/button.component";
import {MatDialogRef} from "@angular/material/dialog";
import {DropdownComponent} from "@shared/inputs/dropdown/dropdown.component";
import type {
  CreateTerminalRequest,
  CreateTerminalResponse,
  GetCountryListItem,
  GetCountryListResponse,
  GetStateListItem,
  GetStateListResponse
} from "@core/api/model";
import {BaseComponent} from "@shared/base/base-component";
import {State} from "@shared/base/base-state";
import {ApiService} from "@core/api/api.service";

@Component({
  selector: 'app-create-terminal',
  imports: [
    BaseDialogComponent,
    ReactiveFormsModule,
    OutlineTextInputComponent,
    ButtonComponent,
    DropdownComponent
  ],
  templateUrl: './create-terminal.component.html',
  standalone: true,
  styleUrl: './create-terminal.component.scss'
})
export class CreateTerminalComponent extends BaseComponent implements OnInit {

  formBuilder = inject(FormBuilder);
  dialog = inject(MatDialogRef);
  apiService = inject(ApiService);

  createTerminalForm!: FormGroup;

  countryListState = new State<GetCountryListResponse>();
  stateListState = new State<GetStateListResponse>();
  createTerminalState = new State<CreateTerminalResponse>();

  filteredStates: GetStateListItem[] = [];

  ngOnInit() {
    this.initForm();
    this.getCountries();
    this.getStateList();
  }

  initForm() {
    this.createTerminalForm = this.formBuilder.group({
      name: ['', Validators.required],
      addressLine1: ['', Validators.required],
      addressLine2: [''],
      city: ['', Validators.required],
      countryId: ['', Validators.required],
      stateId: ['', Validators.required],
      zipCode: ['', Validators.required],
    })
  }

  getCountries() {
    this.executeRequest<GetCountryListResponse>({
      state: this.countryListState,
      request: this.apiService.getCountryList(),
    });
  }

  getStateList() {
    this.executeRequest<GetStateListResponse>({
      state: this.stateListState,
      request: this.apiService.getStateList(),
    });
  }

  onCountrySelected(selectedItem: GetCountryListItem | null) {
    const stateList = this.stateListState.response()?.data ?? [];
    this.filteredStates = stateList.filter(
      (data) => data.countryId == selectedItem?.id
    );
  }

  onCreateTerminalClicked() {
    if (!this.createTerminalForm.valid) {
      return;
    }
    let createTerminalInfo = this.createTerminalForm.value;
    let request: CreateTerminalRequest = {
      addressLine1: createTerminalInfo.addressLine1,
      addressLine2: createTerminalInfo.addressLine2,
      city: createTerminalInfo.city,
      country: createTerminalInfo.countryId,
      name: createTerminalInfo.name,
      state: createTerminalInfo.stateId,
      zipCode: createTerminalInfo.zipCode,
    }
    this.executeRequest({
      state: this.createTerminalState,
      request: this.apiService.createTerminal(request),
      onSuccess: (response) => {
        this.dialog.close(true);
      }
    });
  }

  closeDialog() {
    this.dialog.close(true);
  }

  protected readonly InputDateFormat = InputDateFormat;
}
