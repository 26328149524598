<div class="app-container !p-0">
  <div class="flex p-4 gap-5 justify-between bg-white items-center">
    <app-page-header title="Out of Service Reasons" description="View and manage all Out of Service reasons."></app-page-header>
    <div class="flex gap-3 items-center">
      <app-button (click)="onNewOutOfServiceReasonClicked()" [iconSrc]="'assets/icons/plus.svg'">
        New Out Of Service Reason
      </app-button>
    </div>
  </div>
  <div class="grow m-4 overflow-y-scroll">
    <app-data-table
      [state]="getOutOfServiceReasonListState"
      [columnDefs]="columnDefs"
      (onActionPerformed)="onActionClicked($event)"
      [enableColumnsConfig]="true"
      (tableStateChanged)="getOutOfServiceReasonList($event)">
    </app-data-table>
  </div>
</div>

