<app-base-input [title]="title()">
  <div input class="relative flex items-center w-full text-neutral-400 bg-white shadow-1 rounded-md">
    <p class="absolute pointer-events-none ml-3 text-neutral-900">+91</p>

    <!--    <p>{{  formControl.getError('required') ? placeholder + ' ' : placeholder }}</p>-->

    <input
      class="h-[46px] pl-12 placeholder-neutral-400 text-black rounded-md border-none outline-none focus:border-none ring-1 ring-neutral-300 focus:ring-primary-500 focus:ring-2 transition-all duration-300"
      [class.text-neutral-400]="formControl.disabled"
      [class.disabled-placeholder]="formControl.disabled"
      [formControl]="formControl"
      type="text"
      [placeholder]="placeholder()"
      (focus)="onFocus()"
      (blur)="onBlur()"
      (input)="onValueChange($event)">
  </div>
</app-base-input>

@if (formControl.errors && hasErrors) {
  <div class="mb-3">
    <span class="text-caption text-red-500">{{ formControl.errors | humanizeFormMessages: errorMessages() }}</span>
  </div>
} @else {
  @if (showErrorSpace()) {
    <div class="mb-3">
      <span class="text-caption text-transparent">.</span>
    </div>
  }
}
