import {Component, Input, output} from '@angular/core';
import {TableActionEvent} from "@shared/components/data-table/data-table.component";

@Component({
  selector: 'lib-table-custom-component',
  imports: [],
  templateUrl: './table-custom.component.html',
  styleUrl: './table-custom.component.scss'
})
export class TableCustomComponent<T> {
  @Input() rowData!: T;

  actionPerformed = output<TableActionEvent>();
}
