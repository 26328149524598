<app-base-dialog [title]="'Choose a Terminal'">
  <div body>
    <div class="flex flex-col gap-3">

      @for (terminal of companyUserTerminals.response()?.data ?? []; track $index) {
        <div class="flex">
          <p class="text-body2">{{ terminal.name }}</p>
        </div>
      }
    </div>
  </div>
</app-base-dialog>
