import {Component, inject, OnInit} from '@angular/core';
import {BaseComponent} from "@shared/base/base-component";
import {State} from "@shared/base/base-state";
import {GetDriverApplicationParams, GetDriverApplicationResponse, GetSapProgramResponse} from "@core/api/model";
import {ApiService} from "@core/api/api.service";
import {ButtonComponent} from "@shared/components/button/button.component";
import {ColumnDef, DataTableComponent} from "@shared/components/data-table/data-table.component";
import {DateUtils} from "@shared/utils/date-utils";
import {TokenStoreService} from "@core/services/token/token-store.service";

@Component({
  selector: 'app-view-sap-program-history-results',
  imports: [
    ButtonComponent,
    DataTableComponent,
  ],
  templateUrl: './view-sap-program-results.component.html',
  standalone: true,
  styleUrl: './view-sap-program-results.component.scss'
})
export class ViewSapProgramResultsComponent extends BaseComponent implements OnInit {


  columnDefs: ColumnDef[] = [
    {
      title: 'Date', type: 'date', displayTemplate: '$startDate', alignment: 'center', dateConfig: {
        dateFormat: 'dd/ mm/ yyyy', showIcon: false
      }
    },
    {
      title: 'Status', type: 'badge', displayTemplate: '$programStatus', alignment: 'center', badgeConfig: {
        properties: [
          {
            data: 'Active',
            displayText: 'Active',
            backgroundColorClass: 'bg-success-50',
            indicatorColorClass: 'bg-success-500',
            borderColorClass: 'border-success-200',
            textColorClass: 'text-success-500'
          },
          {
            data: 'Disabled',
            displayText: 'Disabled',
            backgroundColorClass: 'bg-error-50',
            indicatorColorClass: "bg-error-500",
            borderColorClass: 'border-error-200',
            textColorClass: 'text-error-500'
          },
        ]
      }
    },
    {title: 'Notes', type: 'text', displayTemplate: '$notes', alignment: 'center'},
    {
      title: 'Actions', type: 'text', displayTemplate: 'View Results', alignment: 'center'

    }


  ]

  driverId: string | null = null;

  apiService = inject(ApiService);
  tokenService = inject(TokenStoreService);

  getSapProgramState = new State<GetSapProgramResponse>();


  ngOnInit() {
    this.getSapProgram();
  }

  getSapProgram() {
    this.route.queryParams.subscribe(params => {
      this.driverId = params['driverId'];
      console.log('Driver ID from query params:', this.driverId);
      this.executeRequest({
        state: this.getSapProgramState,
        request: this.apiService.getSapProgram(this.driverId ?? ''),
        onSuccess: (response) => {
          let dateUtils = new DateUtils();
          let sapInfo = response.data;
          sapInfo.startDate = dateUtils.convertToMMDDYYYY(sapInfo.startDate) ?? '';
        }
      });
    });
  }


}


