<div class="app-container bg-white m-4 flex flex-col h-screen">
  <div class="flex justify-between w-full m-4">
    <p class="text-h6 text-info-500">SAP Program</p>
    <app-button class="px-2"
                appearance="textType"
                textButtonColor="text-neutral-600" [iconColor]="'text-neutral-500'"
                [iconSrc]="'assets/icons/arrow-left.svg'">Back
    </app-button>
  </div>
  <div class="line"></div>
  <div class="flex justify-between items-center m-4">
    <table class="w-1/2 border-collapse">
      <thead class="text-neutral-500">
      <tr>
        <th class="p-3 text-body2 text-left">Code</th>
        <th class="p-3 text-body2 text-left">Started At</th>
        <th class="p-3 text-body2 text-left">Duration</th>
        <th class="p-3 text-body2 text-left">Frequencies</th>
      </tr>
      </thead>
      <tbody>
      <td class="p-3 text-body2 text-nowrap">{{ getSapProgramState.response()?.data?.code ?? '' }}</td>
      <td class="p-3 text-body2 text-nowrap">
        {{ getSapProgramState.response()?.data?.startDate}}
      </td>

      <td class="p-3 text-body2 text-nowrap">{{ getSapProgramState.response()?.data?.duration ?? '' }}</td>
      <td class="p-3 text-body2 text-nowrap">{{ getSapProgramState.response()?.data?.frequency ?? '' }}</td>
      </tbody>
    </table>
  </div>
  <div class="flex-grow flex flex-col m-4 overflow-auto">
    <app-data-table
      class="w-full flex-grow"
      [state]="getSapProgramState"
      [columnDefs]="columnDefs"
      [enableColumnsConfig]="true">
    </app-data-table>
  </div>
</div>
