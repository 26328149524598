<div class="app-container !p-0">

  <div class="flex p-4 gap-5 justify-between bg-white items-center">
    <app-page-header title="Drivers" description="View and manage all drivers."></app-page-header>

    <div class="flex gap-3 items-center">
      <app-button
        (click)="onExportClicked()"
        [loading]="exportXlsxDriverState.loading()"
        [iconSrc]="'assets/icons/export.svg'"
        [buttonColor]="'bg-white'"
        [appearance]="'outline'"
        [textButtonColor]="'text-primary-500'">
        Export
      </app-button>
      <app-button (click)="onNewDriverClicked()" [iconSrc]="'assets/icons/plus.svg'">
        New Driver
      </app-button>
    </div>
  </div>

  <div class="grow m-4 overflow-y-scroll">
    <app-data-table
      [state]="driverListState"
      [columnDefs]="columnDefs"
      (onActionPerformed)="onActionClicked($event)"
      (tableStateChanged)="getDriverList($event)"
      [expandableComponent]="DriverTableExpandedInfoComponent"
      [enableColumnsConfig]="true"
      (filterChanged)="onFilterChanged($event)">
    </app-data-table>
  </div>
</div>
