import {
  Component,
  inject,
  input,
  OnInit,
  signal,
  WritableSignal,
} from "@angular/core";
import {
  MatTab,
  MatTabChangeEvent,
  MatTabGroup,
  MatTabLabel,
} from "@angular/material/tabs";
import {SaveApplicantGeneralInfoComponent} from "./save-applicant-general-info/save-applicant-general-info.component";
import {
  SaveApplicantResidencyInfoComponent
} from "./save-applicant-residency-info/save-applicant-residency-info.component";
import {AppSvgIconComponent} from "@shared/components/app-svg-icon/app-svg-icon.component";
import {ButtonComponent} from "@shared/components/button/button.component";
import {BaseComponent} from "@shared/base/base-component";
import {TokenStoreService} from "@core/services/token/token-store.service";
import {SaveApplicantLicenseInfoComponent} from "./save-applicant-license-info/save-applicant-license-info.component";
import {
  SaveDrivingExperienceInfoComponent
} from "./save-driving-experience-info/save-driving-experience-info.component";
import {
  SaveApplicantAccidentInfoComponent
} from "./save-applicant-accident-info/save-applicant-accident-info.component";
import {
  SaveApplicantTrafficConvictionsComponent
} from "./save-applicant-traffic-convictions/save-applicant-traffic-convictions.component";
import {
  SaveApplicantEmploymentsInfoComponent
} from "./save-applicant-employments-info/save-applicant-employments-info.component";
import {
  SaveApplicantEducationInfoComponent
} from "./save-applicant-education-info/save-applicant-education-info.component";
import {ApiService} from "@core/api/api.service";
import {State} from "@shared/base/base-state";
import {
  DriverApplicationInfo,
  DriverApplicationInfoResponse,
} from "@core/services/driver-application/driver-application-info/driver-application-info-response";
import {
  DriverApplicationInfoRequest
} from "@core/services/driver-application/driver-application-info/driver-application-info-request";
import {MatDialog, MatDialogModule} from "@angular/material/dialog";
import {
  DriverIncompleteApplicationComponent
} from "./driver-incomplete-application/driver-incomplete-application.component";
import {Constants} from "@core/constants/constants";
import {NgClass} from "@angular/common";
import {
  type DriverApplicationStartedRequest,
  DriverApplicationStartedResponse,
} from "@core/api/model";

@Component({
  selector: "app-driver-application",
  standalone: true,
  imports: [
    MatTabGroup,
    MatTab,
    MatTabLabel,
    MatDialogModule,
    AppSvgIconComponent,
    ButtonComponent,
    SaveApplicantGeneralInfoComponent,
    SaveApplicantResidencyInfoComponent,
    SaveApplicantLicenseInfoComponent,
    SaveDrivingExperienceInfoComponent,
    SaveApplicantAccidentInfoComponent,
    SaveApplicantTrafficConvictionsComponent,
    SaveApplicantEmploymentsInfoComponent,
    SaveApplicantEducationInfoComponent,
    NgClass,
  ],
  templateUrl: "./driver-application.component.html",
  styleUrl: "./driver-application.component.scss",
})
export class DriverApplicationComponent
  extends BaseComponent
  implements OnInit {
  token = input<string>();
  applicationId = input<string>();

  apiService = inject(ApiService);
  tokenService = inject(TokenStoreService);
  readonly dialog = inject(MatDialog);

  driverApplicationState = new State<DriverApplicationInfoResponse>();
  driverApplicationStartedState = new State<DriverApplicationStartedResponse>();

  completedApplicationClicked = signal(false);

  isGeneralInfoCompleted = signal(false);
  isResidencyInfoCompleted = signal(false);
  isLicenseInfoCompleted = signal(false);
  isDrivingExperienceInfoCompleted = signal(false);
  isAccidentInfoCompleted = signal(false);
  isTrafficConvictionsInfoCompleted = signal(false);
  isEmploymentsInfoCompleted = signal(false);
  isEducationInfoCompleted = signal(false);

  steps: DriverApplicationStep[] = [
    {
      title: "General",
      completed: this.isGeneralInfoCompleted,
      type: "general",
    },
    {
      title: "Residency",
      completed: this.isResidencyInfoCompleted,
      type: "residency",
    },
    {
      title: "License",
      completed: this.isLicenseInfoCompleted,
      type: "license",
    },
    {
      title: "Driving Experience",
      completed: this.isDrivingExperienceInfoCompleted,
      type: "driving-experience",
    },
    {
      title: "Accidents",
      completed: this.isAccidentInfoCompleted,
      type: "accidents",
    },
    {
      title: "Traffic Convictions",
      completed: this.isTrafficConvictionsInfoCompleted,
      type: "traffic-convictions",
    },
    {
      title: "Employments",
      completed: this.isEmploymentsInfoCompleted,
      type: "employments",
    },
    {
      title: "Education",
      completed: this.isEducationInfoCompleted,
      type: "education",
    },
  ];

  activeStep = signal<DriverApplicationStep>(this.steps[0]);

  activeStepIndex = signal(0);

  ngOnInit(): void {
    this.handleDriverApplicationId();
    this.handleDriverApplicationInfo(false);
  }

  private handleDriverApplicationId() {
    if (this.token() != null) {
      this.handleToken();
    } else if (this.applicationId() != null) {
      this.tokenService.saveDriverApplicationId(this.applicationId()!);
    }
  }

  private handleApplicationStartStatus(applicationStatus: string) {
    if (applicationStatus != "Invited") {
      return;
    }

    let request: DriverApplicationStartedRequest = {
      id: this.tokenService.getDriverApplicationId() ?? "",
    };
    this.executeRequest<DriverApplicationStartedResponse>({
      request: this.apiService.driverApplicationStarted(request),
      state: this.driverApplicationStartedState,
    });
  }

  handleToken() {
    let isValidToken = this.tokenService.isValidJWTToken(this.token());
    if (isValidToken) {
      this.tokenService.saveDriverApplicationToken(this.token());
    } else {
      this.toasterService.error("Invalid Driver Application");
      this.router.navigate(["/login"]);
    }
  }

  onTabChange($event: MatTabChangeEvent) {
    this.activeStep.set(this.steps[$event.index]);
    this.activeStepIndex.set($event.index);
  }

  onPreviousClicked() {
    const activeStepIndex = this.steps.indexOf(this.activeStep());
    if (activeStepIndex === 0) {
      return;
    }
    const index = activeStepIndex - 1;
    this.activeStep.set(this.steps[index]);
    this.activeStepIndex.set(index);
  }

  onNextClicked() {

    if (this.activeStep().type === 'education') {
      this.isEducationInfoCompleted.set(true);
    } else {
      this.activeStep().completed.set(true);
    }

    const activeStepIndex = this.steps.indexOf(this.activeStep());

    if (activeStepIndex === this.steps.length - 1) {
      return;
    }

    const index = activeStepIndex + 1;
    this.activeStep.set(this.steps[index]);
    this.activeStepIndex.set(index);
  }


  onCompleteApplicationClicked() {
    this.handleDriverApplicationInfo(true);
    this.completedApplicationClicked.set(true);
  }

  private handleDriverApplicationInfo(completeApplication: boolean) {
    let driverApplicationId = this.tokenService.getDriverApplicationId();
    let request: DriverApplicationInfoRequest = {
      driverApplicationId: driverApplicationId,
    };

    this.executeRequest<DriverApplicationInfoResponse>({
      state: this.driverApplicationState,
      request: this.apiService.getDriverApplication(request),
      onSuccess: (response) => {
        this.handleApplicationStatus(response.data);
        this.handleApplicationStartStatus(response.data?.applicationStatus);
        if (completeApplication) {
          this.handleCompleteApplication(response.data);
        }
      },
    });
  }

  private handleApplicationStatus(info: DriverApplicationInfo) {
    this.isGeneralInfoCompleted.set(info.isGeneralCompleted ?? false);
    this.isResidencyInfoCompleted.set(info?.isResidencyCompleted ?? false);
    this.isLicenseInfoCompleted.set(info?.isLicenseCompleted ?? false);
    this.isDrivingExperienceInfoCompleted.set(
      info?.isExperienceCompleted ?? false
    );
    this.isAccidentInfoCompleted.set(info?.isAccidentCompleted ?? false);
    this.isTrafficConvictionsInfoCompleted.set(
      info?.isTrafficConvictionCompleted ?? false
    );
    this.isEmploymentsInfoCompleted.set(info?.isEmploymentCompleted ?? false);
    this.isEducationInfoCompleted.set(info?.isEducationCompleted ?? false);
  }

  private handleCompleteApplication(info: DriverApplicationInfo) {
    let canCompleteApplication = this.canCompleteApplication(info);
    if (canCompleteApplication) {
      this.router.navigate([`/complete-driver-application`]);
    } else {
      this.dialog.open(DriverIncompleteApplicationComponent, {
        data: info,
        ...Constants.defaultDialogConfig,
      });
    }
  }

  canCompleteApplication(info: DriverApplicationInfo) {
    if (!info.isGeneralCompleted) {
      return false;
    }

    if (!info.isResidencyCompleted) {
      return false;
    }

    if (!info.isLicenseCompleted) {
      return false;
    }

    if (!info.isAccidentCompleted) {
      return false;
    }

    if (!info.isTrafficConvictionCompleted) {
      return false;
    }

    if (!info.isEmploymentCompleted) {
      return false;
    }

    return info.isEducationCompleted;
  }
}

export interface DriverApplicationStep {
  title: string;
  completed: WritableSignal<boolean>;
  type:
    | "general"
    | "residency"
    | "license"
    | "driving-experience"
    | "accidents"
    | "traffic-convictions"
    | "employments"
    | "education";
}
