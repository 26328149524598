import { Component } from '@angular/core';
import {NoDataComponent} from "@shared/components/no-data/no-data.component";

@Component({
  selector: 'app-company-settings',
  standalone: true,
  imports: [
    NoDataComponent
  ],
  templateUrl: './company-settings.component.html',
  styleUrl: './company-settings.component.scss'
})
export class CompanySettingsComponent {

}
