import {Component, inject, OnInit} from '@angular/core';
import {BaseDialogComponent} from "@shared/components/_base/base-dialog/base-dialog.component";
import {ButtonComponent} from "@shared/components/button/button.component";
import {DropdownComponent} from "@shared/inputs/dropdown/dropdown.component";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {OutlineTextInputComponent} from "@shared/inputs/outline-text-input/outline-text-input.component";
import {BaseComponent} from "@shared/base/base-component";
import {
  GetCountryListItem,
  GetCountryListResponse,
  GetStateListItem,
  GetStateListResponse, type GetTerminalListItem, UpdateTerminalRequest, UpdateTerminalResponse
} from "@core/api/model";
import {State} from "@shared/base/base-state";
import {ApiService} from "@core/api/api.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";


@Component({
  selector: 'app-update-terminal',
  imports: [
    BaseDialogComponent,
    ButtonComponent,
    DropdownComponent,
    FormsModule,
    OutlineTextInputComponent,
    ReactiveFormsModule
  ],
  templateUrl: './update-terminal.component.html',
  standalone: true,
  styleUrl: './update-terminal.component.scss'
})
export class UpdateTerminalComponent extends BaseComponent implements OnInit {

  dialogRef = inject(MatDialogRef);
  data: GetTerminalListItem = inject(MAT_DIALOG_DATA);
  formBuilder = inject(FormBuilder);
  apiService = inject(ApiService);

  updateTerminalForm!: FormGroup;

  countryListState = new State<GetCountryListResponse>();
  stateListState = new State<GetStateListResponse>();
  updateTerminalState = new State<UpdateTerminalResponse>();

  filteredStates: GetStateListItem[] = [];

  ngOnInit() {
    this.initForm();
    this.getCountries();
  }

  initForm() {
    this.updateTerminalForm = this.formBuilder.group({
        name: [this.data?.name, Validators.required],
        addressLine1: [this.data?.addressLine1, Validators.required],
        addressLine2: [this.data?.addressLine2],
        city: [this.data?.city, Validators.required],
        countryId: [this.data?.country, Validators.required],
        stateId: [null, Validators.required],
        zipCode: [this.data?.zipCode, Validators.required]
      }
    );
  }

  getCountries() {
    this.executeRequest<GetCountryListResponse>({
      state: this.countryListState,
      request: this.apiService.getCountryList(),
      onSuccess: response => {
        this.getStateList();
      }
    });
  }

  getStateList() {
    this.executeRequest<GetStateListResponse>({
      state: this.stateListState,
      request: this.apiService.getStateList(),
      onSuccess: response => {
        let stateList = response.data ?? [];
        let state = stateList?.find(value => value.id === this.data.state);

        this.filteredStates = stateList.filter(
          (data) => data.countryId == this.data.country
        );

        this.updateTerminalForm.controls['stateId'].setValue(state?.id);
      }
    });
  }

  onCountrySelected(selectedItem: GetCountryListItem | null) {
    const stateList = this.stateListState.response()?.data ?? [];
    this.filteredStates = stateList.filter(
      (data) => data.countryId == selectedItem?.id
    );
  }

  onUpdateTerminalClicked() {
    if (!this.updateTerminalForm.valid) {
      return;
    }

    let id = this.data.id;
    let request: UpdateTerminalRequest = {
      addressLine1: this.updateTerminalForm.controls['addressLine1'].value,
      addressLine2: this.updateTerminalForm.controls['addressLine2'].value,
      city: this.updateTerminalForm.controls['city'].value,
      country: this.updateTerminalForm.controls['countryId'].value,
      name: this.updateTerminalForm.controls['name'].value,
      state: this.updateTerminalForm.controls['stateId'].value,
      zipCode: this.updateTerminalForm.controls['zipCode'].value,
    }
    this.executeRequest({
      state: this.updateTerminalState,
      request: this.apiService.updateTerminal(id, request),
      onSuccess: (response) => {
        this.dialogRef.close();
      }
    });
  }

  closeDialog() {
    this.dialogRef.close(true);
  }

}

