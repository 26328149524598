import {Component, inject, OnInit} from '@angular/core';
import {
  ColumnDef,
  DataTableComponent,
  TableActionEvent,
  TableStateEvent
} from "@shared/components/data-table/data-table.component";
import {OutlineTextInputComponent} from "@shared/inputs/outline-text-input/outline-text-input.component";
import {ButtonComponent} from "@shared/components/button/button.component";
import {MatDialog} from "@angular/material/dialog";
import {Constants} from "@core/constants/constants";
import {
  CreateOrUpdateOutOfServiceReasonComponent
} from "./create-out-of-service-reason/create-or-update-out-of-service-reason.component";
import type {
  DeleteOutOfServiceReasonResponse,
  GetOutOfServiceReasonListItem,
  GetOutOfServiceReasonListParams,
  GetOutOfServiceReasonListResponse,
  GetServiceTypeListParams,
} from "@core/api/model";
import {BaseComponent} from "@shared/base/base-component";
import {ApiService} from "@core/api/api.service";
import {State} from "@shared/base/base-state";
import {PageHeaderComponent} from "@core/components/page-header/page-header.component";

@Component({
  selector: 'app-out-of-service-reasons',
  imports: [
    ButtonComponent,
    DataTableComponent,
    PageHeaderComponent
  ],
  templateUrl: './out-of-service-reasons.component.html',
  standalone: true,
  styleUrl: './out-of-service-reasons.component.scss'
})
export class OutOfServiceReasonsComponent extends BaseComponent implements OnInit {

  dialog = inject(MatDialog);
  apiService = inject(ApiService);


  deleteOutOfServiceReasonState = new State<DeleteOutOfServiceReasonResponse>();
  getOutOfServiceReasonListState = new State<GetOutOfServiceReasonListResponse>();

  columnDefs: ColumnDef[] = [
    {
      title: 'Code',
      type: 'text',
      alignment: 'center',
      displayTemplate: '$code',
      sortKey: 'code'
    },
    {
      title: 'Name',
      type: 'text',
      alignment: 'center',
      displayTemplate: '$name',
      sortKey: 'name'
    },
    {
      title: 'Description',
      type: 'text',
      displayTemplate: '$description',
      alignment: 'center',
      sortKey: 'description',
      pinned: 'left'
    },
    {
      title: 'Status', type: 'badge', displayTemplate: '$status', alignment: 'center', badgeConfig: {
        properties: [
          {
            data: 'Active',
            displayText: 'Active',
            backgroundColorClass: 'bg-success-50',
            indicatorColorClass: 'bg-success-500',
            borderColorClass: 'border-success-200',
            textColorClass: 'text-success-500'
          },
          {
            data: 'InActive',
            displayText: 'Inactive',
            backgroundColorClass: 'bg-error-50',
            indicatorColorClass: "bg-error-500",
            borderColorClass: 'border-error-200',
            textColorClass: 'text-error-500'
          },
        ]
      }
    },
    {
      title: 'Actions',
      type: 'actions',
      alignment: 'center',
      actionsConfig: {
        iconActions: [{
          iconPath: 'assets/icons/edit.svg',
          actionKey: 'edit', label: 'Edit Out of Service Reason Type'
        },
          {
            iconPath: 'assets/icons/bin.svg',
            actionKey: 'delete', label: 'Delete Out of Service Reason Type'
          }
        ],
      }
    },
  ];

  ngOnInit() {
  }

  getOutOfServiceReasonList($event: TableStateEvent) {
    let request: GetOutOfServiceReasonListParams = {
      pageNumber: $event?.paginationEvent?.pageNumber ?? 1,
      pageSize: $event?.paginationEvent?.pageSize ?? 50,
      search: $event.searchText
    };
    this.executeRequest({
      state: this.getOutOfServiceReasonListState,
      request: this.apiService.getOutOfServiceReasonList(request),
    });
  }

  onNewOutOfServiceReasonClicked() {
    this.dialog.open(CreateOrUpdateOutOfServiceReasonComponent, {
      ...Constants.defaultDialogConfig,
    }).afterClosed().subscribe((result) => {
      if (result) {
        this.getOutOfServiceReasonList(Constants.defaultTableStateEvent);
      }
    });
  }

  onActionClicked($event: TableActionEvent) {
    let item: GetOutOfServiceReasonListItem = $event.item;
    switch ($event.actionKey) {
      case 'edit':
        this.onUpdateOutOfServiceClicked(item);
        break;
      case 'delete':
        this.onDeleteOutOfServiceReasonClicked(item);
        break;
    }
  }

  onDeleteOutOfServiceReasonClicked(item: GetOutOfServiceReasonListItem) {
    let id = item.id;
    this.alertDialog.show('Delete Out Of Service Reason', 'Are you sure you want to delete the out of service reason?').subscribe((result) => {
      if (result) {
        this.executeRequest({
          state: this.deleteOutOfServiceReasonState,
          request: this.apiService.deleteOutOfServiceReason(id),
          onSuccess: (response) => {
            this.getOutOfServiceReasonList(Constants.defaultTableStateEvent);
          }
        });
      }
    });
  }

  onUpdateOutOfServiceClicked(item: GetOutOfServiceReasonListItem) {
    this.dialog.open(CreateOrUpdateOutOfServiceReasonComponent, {
      ...Constants.defaultDialogConfig,
      data: item
    }).afterClosed().subscribe((success) => {
        if (success) {
          this.getOutOfServiceReasonList(Constants.defaultTableStateEvent);
        }
      }
    );
  }

}
