<div
  class="inline-flex items-center justify-center gap-2 bg-info-50 rounded-md py-2 px-4 w-min text-info-500 text-nowrap cursor-pointer select-none"
  [ngClass]="{
              'bg-info-50 text-info-500': rowData.applicationStatusEnum === GetDriverApplicationListItemApplicationStatus.Invited,
              'bg-amber-50 text-amber-500' : rowData.applicationStatusEnum === GetDriverApplicationListItemApplicationStatus.ApplicationStarted,
              'bg-primary-50 text-primary-500' : rowData.applicationStatusEnum === GetDriverApplicationListItemApplicationStatus.DriverVerificationPending,
              'bg-orange-50 text-orange-500' : rowData.applicationStatusEnum === GetDriverApplicationListItemApplicationStatus.NeedsApproval,
              'bg-green-50 text-green-500' : rowData.applicationStatusEnum === GetDriverApplicationListItemApplicationStatus.UnderVerification,
              'bg-success-50 text-success-500': rowData.applicationStatusEnum === GetDriverApplicationListItemApplicationStatus.Hired,
              'bg-error-50 text-error-500' : rowData.applicationStatusEnum === GetDriverApplicationListItemApplicationStatus.Rejected,
              'bg-orange-100 text-orange-600' : rowData.applicationStatusEnum === GetDriverApplicationListItemApplicationStatus.ResubmissionRequested,
              'bg-green-50 text--500' : rowData.applicationStatusEnum === GetDriverApplicationListItemApplicationStatus.ReVerificationPending,
              }"
  [matMenuTriggerFor]="statusMenu">

  <p class="text-body2">{{ rowData.applicationStatus }}</p>

  @if (getApplicationStatus(rowData.applicationStatusEnum).length > 0) {
    <app-svg-icon src="assets/icons/menu-down.svg" class="text-neutral-400"
                  [size]="18"></app-svg-icon>
  }
</div>

<mat-menu #statusMenu="matMenu" class="mat-elevation-z3 mt-4">
  @for (action of getApplicationStatus(rowData.applicationStatusEnum); track $index) {
    <ng-container>
      <button mat-menu-item class="flex items-center" (click)="onStatusChange(rowData, action)">
        <div class="flex items-center gap-2">
          <p class="text-body2">{{ action }}</p>
        </div>
      </button>
    </ng-container>
  }
</mat-menu>
