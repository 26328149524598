<app-base-dialog [title]="'Create New SAP Program'">
  <div body>
    <form [formGroup]="createSAPProgramForm">
      <div class="flex flex-col gap-4">
        <app-outline-date-input formControlName="sapProgramStartDate" [label]="'SAP Program Start Date'"
                                class="w-full" [fullWidth]="true"
                                [inputDateFormat]="InputDateFormat.mmddyyyy"></app-outline-date-input>
        <app-outline-text-input formControlName="sapProgramDuration" [type]="'number'"
                                label="SAP Program Duration (in days)"
                                placeholder="Ex: 365"
                                class="w-full" [fullWidth]="true"></app-outline-text-input>
        <app-outline-text-input formControlName="testFrequency" [type]="'number'" label="Test Frequency (in days)"
                                placeholder="Ex: 30"
                                class="w-full" [fullWidth]="true"></app-outline-text-input>
        <div class="flex flex-col gap-2">
          <span class="text-body2">Notes</span>
          <textarea
            formControlName="notes"
            class="p-2 border rounded-md resize-none h-32 w-full"
            placeholder="Ex: Starting SAP program due to driver's positive drug test result."
          ></textarea>
        </div>
      </div>
    </form>
  </div>
  <div actions>
    <div class="flex justify-end gap-2">
      <app-button [appearance]="'outline'" [buttonColor]="'border-primary-500'" (click)="onCancelClicked()">Cancel
      </app-button>
      <app-button (click)="onSaveClicked()" [loading]="createSAPProgramState.loading()">Save</app-button>
    </div>
  </div>
</app-base-dialog>




