<div class="app-container">
  <div class="grow overflow-auto mx-8 px-4 overflow-y-scroll">
    <div class="py-6 text-center">
      <p class="text-body1">Drug & Alcohol Policy</p>
    </div>
    <p class="py-4 text-body2 text-neutral-800">In 1988, Congress enacted the Drug Free Workplace Act to require federal
      contractors to establish and maintain a work environment that is free from the effects of drug use and abuse.
      Federal Regulations 49 CFR Part 40 (§382) present the general terms of this program and its guidelines.
      We agree with that goal and believe that the company has responsibility to its employees and those who
      use or come in contact with its products/services, to ensure a safe and productive work environment.
      To satisfy these responsibilities, it is the policy our policy and a condition of employment that an employee
      be present and able to perform their job free from the effects of alcohol, narcotics, depressants, stimulants,
      hallucinogens and cannabis or any other substances, which can impair job performance.</p>
    <p class="py-4 text-h6 text-neutral-800 underline">
      Our Commitment
    </p>
    <p class="py-4 text-body2 text-neutral-800">We recognize that drug and alcohol abuse may be a sign of chemical
      dependency and that substance abuse can be successfully treated with professional help.</p>
    <p class="py-4 text-body2 text-neutral-800">
      We provide an Employee Assistance Program (EAP) through SapList.Com for employees to deal with substance
      abuse and other personal problems that can affect work performance. Our commitment is to help employees
      remain productive members of our team. In certain circumstances, the company may insist upon a mandatory
      referral to our EAP as a condition of continued employment. No employee will be disciplined or discriminated
      against simply for seeking help.</p>
    <p class="py-4 text-h6 text-neutral-800 underline">
      Employee Responsibility
    </p>
    <p class="py-4 text-body2 text-neutral-800">
      The employee is responsible for following all of our work and safety rules, and for observing the standards of
      behavior and employer, co-workers, and customers have the right to expect from you. In addition,
      if you believe you may have a problem with drugs or alcohol, you are responsible for seeking assistance,
      whether from or through the company or any other resource, before a drug or alcohol problem adversely affects
      your work performance or results in a violation of this policy. The time to seek help is BEFORE you are in
      “trouble”,
      NOT AFTER. If a professional assessment is made that you have a problem with Drugs or Alcohol, your continued
      employment may be conditioned upon:
    </p>
    <ul class="mx-6 py-4 list-disc">
      <li class="text-body2 text-neutral-800">Entering into and completing a treatment program approved by the
        company.
      </li>
      <li class="text-body2 text-neutral-800">Signing and living up to a last chance performance agreement.</li>
      <li class="text-body2 text-neutral-800">Undergoing a Follow-up Testing Program at companies’ discretion.</li>
    </ul>
    <p class="py-4 text-h6 text-neutral-800 underline">
      Scope of Our Policy</p>
    <p class="py-4 text-body2 text-neutral-800">
      This Policy and each of its rules apply whenever an employee is on or in Company Property, surrounding grounds
      and parking lots, leased or rented space. Company time (including breaks and meal periods), in any vehicle used
      on Company business, and in other circumstances (such as on customer premises or at business/sales functions) we
      believe may adversely affect our operations, safety, reputation or the administration of this policy.</p>
    <p class="py-4 text-h6 text-neutral-800 underline">Our Drug and Alcohol Rules</p>
    <p class="py-4 text-body2 text-neutral-800">The following rules are extremely important and an employee who violates
      any one of them will be subject to disciplinary action, up to and including termination.</p>
    <ul class="list-decimal py-4 mx-6">
      <li class="text-body2 text-neutral-800 py-4"><span class="underline">Alcohol</span> An employee may not possess,
        use, transfer, offer, or be under
        the influence of any intoxicating liquor while at work or on company business. This rule prohibits using any
        alcohol
        prior to reporting to work, during breaks or meal periods, or in conjunction with any Company activity, except
        social
        or business events where a Corporate Officer has authorized the moderate consumption of Alcoholic Beverages.
      </li>
      <li class="text-body2 text-neutral-800 py-4">
        An employee will be removed from a Safety Sensitive Position for 24 hours if your BA is more than .02 and less
        than .04. A Breath Test over .04 is a DOT Violation, and a referral will be required to a Substance Abuse
        Professional before being released back to a safety sensitive position.
      </li>
      <li class="text-body2 text-neutral-800 py-4"><span class="underline">Drugs</span>
        An Employee may not possess, use, transfer, offer, share, attempt to sell or obtain, manufacture, or be under
        the influence of any drug or similar substance and also may not have any drugs of similar substances present
        in the body. Thus, an employee who tests positive for any illegal-drug violates this rule. This rule also
        pertains to Prescription drugs being taken without doctors authorization.
      </li>
      <li class="text-body2 text-neutral-800 py-4"><span
        class="underline">Drug Paraphernalia and Alcohol Containers</span>
        An Employee may not possess any Drug Paraphernalia or Alcohol Containers.
      </li>
      <li class="text-body2 text-neutral-800 py-4"><span
        class="underline">Prescriptions/ Over–the-counter Medications</span>
        It is the employees responsibility to check the potential effects of prescribed drugs and over-the
        counter Medications with your doctor or pharmacists before starting work, and to immediately let your
        supervisor know when such use makes it unsafe for you to report to work or do your job.
      </li>
      <li class="text-body2 text-neutral-800 py-4"><span
        class="underline">Adulterants</span>
        Any substance that is used for the purpose of Manipulating a drug test by adding to the specimen or ingesting.
      </li>
    </ul>
    <p class="py-4 text-h6 text-neutral-800 underline">
      Pre-Employment Testing.</p>
    <p class="py-4 text-body2 text-neutral-800">
      All safety sensitive employees are required to pass a DOT pre-employment urine drug test before being hired.</p>
    <p class="py-4 text-h6 text-neutral-800 underline">
      Random Testing Program.</p>
    <p class="py-4 text-body2 text-neutral-800">
      The Random-testing program is implemented by a third party and/or a computerized Selection
      Processthroughouttheyear.
      TheThirdPartyAdministrator(TPA)combinesthedriversfrom our company with drivers from other companies.
      The TPA selects 4 times per year and notifies the DER, Designated Employee Representative.
      The DER can notify the Driver within the selection period. When the driver is notified, they must test ASAP.
      The Federal Motor Carrier Safety Administration does not allow testing delays due to convenience or movement of
      freight. (FMCSA).</p>
    <p class="py-4 text-h6 text-neutral-800 underline">Mandatory Post Accident Testing.</p>
    <p class="py-4 text-body2 text-neutral-800">Post accident drug and/or alcohol testing will be at supervisor or
      company request,
      or as Defined in 49 CFR Part 40. See Chart</p>
    <table class="table-auto border-4 border-neutral-500 w-full">
      <thead class="text-body1">
      <tr>
        <th class="border border-neutral-300 px-4 py-2">Type of accident involved</th>
        <th class="border border-neutral-300 px-4 py-2">Citation issued to the Test must be CMV driver? (Class
          Performed. A or B)
        </th>
        <th class="border border-neutral-300 px-4 py-2">Test must be performed</th>
      </tr>
      </thead>
      <tbody class="text-body2">
      <tr>
        <td class="border border-neutral-300 px-4 py-2">i. Human Fatality</td>
        <td class="border border-neutral-300 px-4 py-2">Yes No</td>
        <td class="border border-neutral-300 px-4 py-2">Yes No</td>
      </tr>
      <tr>
        <td class="border border-neutral-300 px-4 py-2">ii. Bodily injury with immediate medical treatment away from
          scene..
        </td>
        <td class="border border-neutral-300 px-4 py-2">Yes No</td>
        <td class="border border-neutral-300 px-4 py-2">Yes No</td>
      </tr>
      <tr>
        <td class="border border-neutral-300 px-4 py-2">iii. Disabling damage to any motor vehicle requiring tow away.
        </td>
        <td class="border border-neutral-300 px-4 py-2">Yes No</td>
        <td class="border border-neutral-300 px-4 py-2">Yes No</td>
      </tr>
      </tbody>
    </table>
    <p class="py-4 text-h6 text-neutral-800 underline">Reasonable Suspicion Testing or Reasonable Cause</p>
    <p class="py-4 text-body2 text-neutral-800">At least one Supervisor will be trained in accordance to 49 CFR 382.603
      of the Federal Register to make these observations of Work Performance, Behavior, and Physical Indicators.
    </p>
    <ul class="list-disc py-4 mx-6">
      <li class="text-body2 text-neutral-800">Observable Symptoms or Unusual Behavior.</li>
      <li class="text-body2 text-neutral-800">The Odor or Smell of Alcohol or Drugs on the employee’s breath or clothes
        or in
        an area (such as in a vehicle, office, work area, or restroom) immediately
        controlled or occupied by the employee.
      </li>
      <li class="text-body2 text-neutral-800">Alcohol, alcohol containers, illegal drugs or drug paraphernalia in the
        employee’s
        possession or in an area controlled or occupied by the employee (vehicle, office,
        desk restroom. ) ;
      </li>
      <li class="text-body2 text-neutral-800">Unexplained or Significant deterioration in job performance.</li>
      <li class="text-body2 text-neutral-800">Unexplained significant changes in behavior (e.g., abusive behavior,
        repeated
        disregard of safety rules or procedures, insubordination, etc.);
      </li>
      <li class="text-body2 text-neutral-800">Evidence that the employee may have tampered with a previous drug test.
      </li>
      <li class="text-body2 text-neutral-800">Criminal citations, arrests or convictions involving drugs and alcohol.
      </li>
      <li class="text-body2 text-neutral-800">Unexplained absenteeism or tardiness</li>
      <li class="text-body2 text-neutral-800">Employee admissions regarding drug or alcohol use;</li>
      <li class="text-body2 text-neutral-800">Any involvement in any work-related accident or near misses.</li>
      <li class="text-body2 text-neutral-800">Any type of Paraphernalia discover on your person or Company Property</li>
    </ul>
    <p class="py-4 text-h5 text-neutral-800">Fit for Duty</p>
    <p class="py-4 text-body2 text-neutral-800">The company could require a fit for duty exam by a certified Medical
      Practitioner; this exam can be administered along with Drug and Alcohol Screen to determine if employee is fit
      for Duty. This could be requested in addition to the DOT Medical card Certificate.</p>
    <p class="py-4 text-h5 text-neutral-800">Duty to Cooperate</p>
    <p class="py-4 text-body2 text-neutral-800">An employee who fails to cooperate in the administration of this policy
      generally will be terminated and is in violation of §49 CFR Part 40. This includes such things as:</p>
    <ul class="list-disc py-4 mx-6">
      <li class="text-body2 text-neutral-800">Refusing to consent to testing, to submit a sample, or to sign required
        forms.
      </li>
      <li class="text-body2 text-neutral-800">Refusing to cooperate in any way (for example, refusing to courteously and
        candidly cooperate in any
        interview or investigation, including any form of
        truthfulness, misrepresentation or misleading statements or omissions.);
      </li>
      <li class="text-body2 text-neutral-800">Any form of dishonesty in the investigation or testing process.</li>
      <li class="text-body2 text-neutral-800">Refusing to test again at a time of the Company’s choosing whenever any
        test
        results in a finding of a dilute sample or reasonable suspicion.
      </li>
      <li class="text-body2 text-neutral-800">Failure to accept the referral, to enter into and complete an approved
        treatment
        Agreement.
        program, or to sign or adhere to the commitments in the Last Chance Performance
      </li>
    </ul>
    <p class="text-h6 text-center text-neutral-800 underline mx-auto py-4">
      EMPLOYEE ACKNOWLEDGEMENT AND CONSENT TO TESTING
    </p>
    <form [formGroup]="drugAndAlcoholPolicyAgreementForm">
      <ul class="list-decimal py-4 mx-6">
        <li class="text-body2 text-neutral-800 py-4">
          I, <span><input type="text" formControlName="name"
                          class="border-b border-neutral-800 outline-none bg-transparent w-auto text-neutral-800"></span>
          acknowledge receiving a copy of the Company’s Drug and Alcohol Policy. Date
          <span><input type="text" formControlName="date"
                       class="border-b border-neutral-800 outline-none bg-transparent w-auto text-neutral-800"></span>
        </li>
        <li class="text-body2 text-neutral-800 py-4">
          I voluntarily agree to provide a sample of my Urine for Testing and to submit to any related physical or other
          examination when I have been requested to do so.
        </li>
        <li class="text-body2 text-neutral-800 py-4">
          I authorize the release of the Test Result (and any other relevant medical information) to the Company for its
          use evaluation and suitability for continued employment. I also release the Company from all liability arising
          out of or connected with the testing.
        </li>
        <li class="text-body2 text-neutral-800 py-4">
          I understand that if I refuse to submit to the testing, to give a requested sample(s), to authorize release
          of the results to the company, and/or if the test results indicate that I do not meet the Company’s standards,
          I may be terminated.
        </li>
        <li class="text-body2 text-neutral-800 py-4">
          I understand that any attempt to switch, adulterate or in any way tamper with the requested sample(s) or to
          other wise manipulate the testing process will result in termination of employment. I also understand that if
          my test results are dilute on the second testing, I may be terminated.
        </li>
      </ul>

      <div class="gap-3 flex items-center">
        <span class="text-body2">I have read this entire policy and each of the above statements</span>
        <div class="py-2 flex items-center gap-3">
          <app-radio-button formControlName="readPolicy" [groupName]="'readPolicy'" title="Yes"
                            [value]="true"></app-radio-button>
          <app-radio-button formControlName="readPolicy" [groupName]="'readPolicy'" title="No"
                            [value]="false"></app-radio-button>
        </div>
      </div>
      <div class="flex flex-col gap-1 py-3">
        <label class="text-body2 text-neutral-800">Signature</label>
        <app-signature-pad [width]="'500'" [height]="'200'"
                           [backgroundColor]="'rgba(255, 255, 255, 0)'"
                           [penColor]="'black'"
                           (signatureChanged)="onSignatureSaved($event)"
                           (signatureCleared)="onSignatureCleared()">
        </app-signature-pad>
        <app-outline-text-input formControlName="signatureDate" [label]="'Date'"></app-outline-text-input>
      </div>
      <app-checkbox class="col-span-2" formControlName="acknowledgement"
                    title="I hereby acknowledge and sign solely as the candidate for the position outlined, confirming my understanding and acceptance."></app-checkbox>
      <div class="py-2">
        <app-button [fullWidth]="true" type="submit">Submit
        </app-button>
      </div>
    </form>
  </div>
</div>
