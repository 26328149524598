<div class="flex items-center gap-4 py-2">
  <div class="border border-neutral-300 rounded-lg relative">
    <canvas
      #canvas
      width="500"
      height="200"
      class="border-neutral-300 bg-neutral-50 rounded-lg">
    </canvas>


    @if (signaturePad?.isEmpty() ?? true) {
      <div
        class="pointer-events-none flex items-center gap-2 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-neutral-500">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-[20px] w-[20px] text-neutral-500" viewBox="0 0 24 24"
             fill="none">
          <path
            d="M9.74996 20.8499C11.53 20.1499 11.14 18.2199 10.24 16.9999C9.34995 15.7499 8.11996 14.8899 6.87996 14.0599C5.99996 13.4999 5.18996 12.7999 4.53996 11.9999C4.25996 11.6699 3.68996 11.0599 4.26996 10.9399C4.85996 10.8199 5.87996 11.3999 6.39996 11.6199C7.30996 11.9999 8.20996 12.4399 9.04996 12.9599L10.06 11.2599C8.49996 10.2299 6.49996 9.31994 4.63996 9.04994C3.57996 8.88994 2.45996 9.10994 2.09996 10.2599C1.77996 11.2499 2.28996 12.2499 2.86996 13.0299C4.23996 14.8599 6.36996 15.7399 7.95996 17.3199C8.29996 17.6499 8.70996 18.0399 8.90996 18.4999C9.11996 18.9399 9.06996 18.9699 8.59996 18.9699C7.35996 18.9699 5.80996 17.9999 4.79996 17.3599L3.78996 19.0599C5.31996 19.9999 7.87996 21.4699 9.74996 20.8499ZM20.84 5.24994C21.06 5.02994 21.06 4.66994 20.84 4.45994L19.54 3.15994C19.33 2.94994 18.97 2.94994 18.76 3.15994L17.74 4.17994L19.82 6.25994M11 10.9199V12.9999H13.08L19.23 6.84994L17.15 4.76994L11 10.9199Z"
            fill="currentColor"
          />
        </svg>
        <span class="text-caption text-neutral-500">Please draw your signature</span>
      </div>
    }

    <div class="absolute top-50 bottom-2 right-2 flex gap-3">
      <span (click)="clearPad()"
            class="text-caption bg-white text-primary-500 cursor-pointer px-2 py-0.5 rounded border border-primary-500">Clear</span>
    </div>
  </div>
</div>

