import {Component, inject, OnInit} from '@angular/core';
import {
  InputDateFormat,
  OutlineDateInputComponent
} from "@shared/inputs/outline-date-input/outline-date-input.component";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ButtonComponent} from "@shared/components/button/button.component";
import {FileUploaderComponent} from "@shared/inputs/file-uploader/file-uploader.component";
import {RadioButtonComponent} from "@shared/inputs/radio-button/radio-button.component";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {BaseDialogComponent} from "@shared/components/_base/base-dialog/base-dialog.component";
import {BaseComponent} from "@shared/base/base-component";
import {
  UploadSapProgramTestResultRequest,
  UploadSapProgramTestResultResponse, UploadTempFileRequest, type UploadTempFileResponse
} from "@core/api/model";
import {State} from "@shared/base/base-state";
import {ApiService} from "@core/api/api.service";

@Component({
  selector: 'app-upload-test-result',
  imports: [
    ButtonComponent,
    FileUploaderComponent,
    RadioButtonComponent,
    OutlineDateInputComponent,
    ReactiveFormsModule,
    BaseDialogComponent
  ],
  templateUrl: './upload-test-result.component.html',
  standalone: true,
  styleUrl: './upload-test-result.component.scss'
})
export class UploadTestResultComponent extends BaseComponent implements OnInit {

  dialogRef = inject(MatDialogRef);
  formBuilder = inject(FormBuilder);
  apiService = inject(ApiService);
  data = inject(MAT_DIALOG_DATA);

  uploadTestResultForm!: FormGroup;

  uploadTestResultState = new State<UploadSapProgramTestResultResponse>();
  attachFileState = new State<UploadTempFileResponse>();

  protected readonly InputDateFormat = InputDateFormat;

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.uploadTestResultForm = this.formBuilder.group({
      programTestDate: ["", Validators.required],
      programTestResult: ["", Validators.required],
      programTestDocumentFilePath: ["", Validators.required],
      notes: ["", Validators.required],
    });
  }

  onCancelClicked() {
    this.dialogRef.close();
  }


  onAttachFileClicked($event: File) {
    let request: UploadTempFileRequest = {
      file: $event
    }

    this.executeRequest({
        state: this.attachFileState,
        request: this.apiService.uploadTempFile(request),
      }
    );
  }

  onSubmitClicked(): void {
    let request: UploadSapProgramTestResultRequest = {
      notes: this.uploadTestResultForm.value.notes,
      programTestDate: this.uploadTestResultForm.value.programTestDate,
      programTestDocumentFilePath: this.attachFileState.response()?.filePath ?? '',
      programTestResult: this.uploadTestResultForm.value.programTestResult,
      sapProgramId: this.data.id
    }
    this.executeRequest({
      state: this.uploadTestResultState,
      request: this.apiService.uploadSapProgramTestResult(request),
      handleSuccess: true,
      successMessage: 'The Test result uploaded successfully',
      onSuccess: (response) => {
        this.dialogRef.close(true);

      }
    });
  }

}
