import {Component, inject, OnInit} from "@angular/core";
import {BaseDialogComponent} from "@shared/components/_base/base-dialog/base-dialog.component";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import {
  SingleSelectionFieldComponent
} from "@shared/inputs/single-selection-field/single-selection-field.component";
import {ButtonComponent} from "@shared/components/button/button.component";
import {
  GetRoleListResponse, UpdateUserRequest,

} from "@core/api/model";
import {BaseComponent} from "@shared/base/base-component";
import {State} from "@shared/base/base-state";
import {ApiService} from "@core/api/api.service";
import {TokenStoreService} from "@core/services/token/token-store.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {OutlineTextInputComponent} from "@shared/inputs/outline-text-input/outline-text-input.component";

@Component({
  selector: "app-update-user",
  standalone: true,
  imports: [
    OutlineTextInputComponent,
    BaseDialogComponent,
    ReactiveFormsModule,
    SingleSelectionFieldComponent,
    ButtonComponent,
  ],
  templateUrl: "./update-user.component.html",
  styleUrl: "./update-user.component.scss",
})
export class UpdateUserComponent extends BaseComponent implements OnInit {

  data = inject(MAT_DIALOG_DATA);
  formBuilder = inject(FormBuilder);
  apiService = inject(ApiService);
  dialogRef = inject(MatDialogRef);
  tokenService = inject(TokenStoreService);

  roleListState = new State<GetRoleListResponse>();
  updateUserState = new State();

  updateUserForm!: FormGroup;

  ngOnInit() {
    this.initForm();
    this.updateForm();
    this.getRole();
  }

  private initForm(): void {
    this.updateUserForm = this.formBuilder.group({
      id: [""],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      email: ["", Validators.required],
      phoneNumber: ["", Validators.required],
      roleId: ["", Validators.required],
    });
  }

  private updateForm(): void {
    this.updateUserForm.controls["id"].setValue(this.data?.id);
    this.updateUserForm.controls["firstName"].setValue(this.data?.firstName);
    this.updateUserForm.controls["lastName"].setValue(this.data?.lastName);
    this.updateUserForm.controls["email"].setValue(this.data?.email);
    this.updateUserForm.controls["email"].disable();
    this.updateUserForm.controls["phoneNumber"].setValue(this.data?.phoneNumber);
    this.updateUserForm.controls["phoneNumber"].disable();
    this.updateUserForm.controls["roleId"].setValue(this.data?.roleId);
  }


  getRole() {
    this.executeRequest<GetRoleListResponse>({
      state: this.roleListState,
      request: this.apiService.getRoleList(),
    });
  }

  onEditUserClicked() {
    this.validateForm(this.updateUserForm);
    if (this.updateUserForm.invalid) {
      return;
    }
    let id = this.data?.id ?? '';

    let formValue = this.updateUserForm.value;
    const updateUserRequest: UpdateUserRequest = {
      firstName: formValue.firstName,
      lastName: formValue.lastName,
      phoneNumber: this.data?.phoneNumber ?? formValue.phoneNumber,
      roleId: formValue.roleId,
      terminalIds: []
    };

    this.executeRequest({
      request: this.apiService.updateUser(id, updateUserRequest),
      state: this.updateUserState,
      handleSuccess: true,
      onSuccess: (response) => {
        this.dialogRef.close(true);
      }
    });
  }

}
