import {Component, OnInit, inject} from "@angular/core";
import {ButtonComponent} from "@shared/components/button/button.component";
import {State} from "@shared/base/base-state";
import {BaseComponent} from "@shared/base/base-component";
import {CommonModule} from "@angular/common";
import {MatMenuModule} from "@angular/material/menu";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatDialog, MatDialogModule} from "@angular/material/dialog";
import {ReactiveFormsModule} from "@angular/forms";
import {Constants} from "@core/constants/constants";
import type {
  ExportXlsxDriverSignedUrlResponse,
  GetDriverListItem,
  GetDriverListParams,
  GetDriverListResponse
} from "@core/api/model";
import {ApiService} from "@core/api/api.service";
import {CreateNewDriverComponent} from "../../overlays/create-new-driver/create-new-driver.component";
import {DriverTableExpandedInfoComponent} from "./driver-table-expanded-info/driver-table-expanded-info.component";
import {
  ColumnDef,
  DataTableComponent,
  TableActionEvent, TableStateEvent
} from "@shared/components/data-table/data-table.component";
import {PageHeaderComponent} from "@core/components/page-header/page-header.component";

@Component({
  selector: "app-drivers-list",
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatDialogModule,
    DataTableComponent,
    PageHeaderComponent
  ],
  templateUrl: "./drivers-list.component.html",
  styleUrl: "./drivers-list.component.scss",
})
export class DriversListComponent extends BaseComponent implements OnInit {

  dialog = inject(MatDialog);
  apiService = inject(ApiService);

  driverListState = new State<GetDriverListResponse>();
  exportXlsxDriverState = new State<ExportXlsxDriverSignedUrlResponse>();

  protected readonly DriverTableExpandedInfoComponent = DriverTableExpandedInfoComponent;

  columnDefs: ColumnDef[] = [
    {
      title: 'Code',
      type: 'text',
      alignment: 'center',
      displayTemplate: '$code',
      sortKey: 'code'
    },
    {
      title: 'Name',
      type: 'text',
      displayTemplate: '$fullName',
      alignment: 'center',
      sortKey: 'firstName',
      pinned: 'left'
    },
    {
      title: 'Email',
      type: 'text',
      displayTemplate: '$email',
      alignment: 'center',
      sortKey: 'email',
      pinned: 'left'
    },
    {title: 'Phone', type: 'text', displayTemplate: '$phoneNumber', alignment: 'center', visible: false},
    {
      title: 'Hired date', type: 'date', displayTemplate: '$hiredDate', alignment: 'center', dateConfig: {
        dateFormat: 'dd MMM, yyyy'
      }
    },
    {
      title: 'Status', type: 'badge', displayTemplate: '$driverStatus', alignment: 'center', badgeConfig: {
        properties: [
          {
            data: 'Active',
            displayText: 'Active',
            backgroundColorClass: 'bg-success-50',
            indicatorColorClass: 'bg-success-500',
            borderColorClass: 'border-success-200',
            textColorClass: 'text-success-500'
          },
          {
            data: 'InActive',
            displayText: 'Inactive',
            backgroundColorClass: 'bg-error-50',
            indicatorColorClass: "bg-error-500",
            borderColorClass: 'border-error-200',
            textColorClass: 'text-error-500'
          },
          {
            data: 'Terminated',
            displayText: 'Terminated',
            backgroundColorClass: 'bg-error-50',
            indicatorColorClass: "bg-error-500",
            borderColorClass: 'border-error-200',
            textColorClass: 'text-error-500'
          },
        ]
      }
    },
    {
      title: 'Actions',
      type: 'actions',
      alignment: 'center',
      actionsConfig: {
        iconActions: [
          {iconPath: 'assets/icons/eye-outline.svg', actionKey: 'view', label: 'View Driver'},
        ]
      }
    }
  ];


  ngOnInit(): void {

  }

  getDriverList(event: TableStateEvent) {
    const request: GetDriverListParams = {
      pageNumber: event.paginationEvent?.pageNumber,
      pageSize: event.paginationEvent?.pageSize,
      sortingDirection: event.tableSortEvent?.direction ?? 'asc',
      orderByPropertyName: event.tableSortEvent?.key ?? undefined,
      search: event.searchText
    };
    this.executeRequest<GetDriverListResponse>({
      state: this.driverListState,
      request: this.apiService.getDriverList(request),
    });
  }

  onActionClicked($event: TableActionEvent) {
    let item = $event.item as GetDriverListItem;
    switch ($event.actionKey) {
      case 'view':
        this.router.navigate([`drivers/${item.id}/general-info`], {queryParams: {tab: 'general'}}).then();
        break;
    }
  }

  onNewDriverClicked() {
    this.dialog.open(CreateNewDriverComponent, {
      ...Constants.defaultDialogConfig
    }).afterClosed()
      .subscribe(() => {
        this.getDriverList(Constants.defaultTableStateEvent);
      });
  }

  onExportClicked() {
    this.executeRequest<ExportXlsxDriverSignedUrlResponse>({
      request: this.apiService.exportXlsxDriverSignedUrl(),
      state: this.exportXlsxDriverState,
      onSuccess: response => {
        if (response.data?.downloadUrl) {
          window.open(response.data.downloadUrl, '_blank');
        }
      }
    });
  }

  protected readonly console = console;

  onFilterChanged($event: any) {
    console.log($event);
  }
}
