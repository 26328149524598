<div class="flex justify-center container mx-auto h-full w-full p-4">
  <div class="flex flex-col bg-white w-full rounded-md px-4">
    <div class="grow overflow-auto overflow-y-scroll">
      <div class="mx-auto py-4">
        <p class="p-2 text-body1 text-center">HIRE APPLICANT</p>
        <p class="p-2 text-body2 text-neutral-800 text-center">Please upload the required documents to complete the
          hiring
          process</p>
      </div>
      <form [formGroup]="driverHiringForm">
        <div class="flex flex-col p-6 gap-6">
          <app-file-uploader formControlName="preEmploymentClearingHouseFilePath"
                             label="Attach Pre-employment Clearinghouse query" [isRequired]="true"
                             [uploading]="clearingHouseQueryState.loading()"
                             (fileSelected)="onClearingHouseFileSelected($event)"></app-file-uploader>
          <div class="flex flex-col gap-4">
            <p class="text-body2 text-neutral-800">Please complete full clearing house using the provided link below</p>
            <a
              href="https://clearinghouse.fmcsa.dot.gov"
              class="text-body2 text-info-500 cursor-pointer underline"
              target="_blank"
              rel="noopener noreferrer">
              https://clearinghouse.fmcsa.dot.gov
            </a>

          </div>
          <div class="grid lg:grid-cols-2 gap-4">
            <app-outline-date-input formControlName="preEmploymentClearingHouseExpiryDate"
                                    [label]="'Pre-employment clearinghouse  query expiry date'"
                                    class="w-full" [fullWidth]="true"
                                    [inputDateFormat]="InputDateFormat.mmddyyyy"></app-outline-date-input>
          </div>
          <div class="grid lg:grid-cols-2 gap-4">
            <app-outline-date-input formControlName="preEmploymentClearingHouseCompletedDate"
                                    [label]="'Full clearinghouse query completed date'"
                                    class="w-full" [fullWidth]="true"
                                    [inputDateFormat]="InputDateFormat.mmddyyyy"></app-outline-date-input>

            <app-outline-text-input formControlName="preEmploymentClearingHouseUploadFrequency"
                                    [label]="'Clearinghouse query upload frequency'" placeholder="Ex: Every 90 days"
                                    class="w-full" [fullWidth]="true"></app-outline-text-input>

          </div>
          <app-file-uploader formControlName="drugAndAlcoholPolicyFilePath" label="Drug & Alcohol policy"
                             [isRequired]="true" [uploading]="drugAlcoholPolicyState.loading()"
                             (fileSelected)="onDrugAlcoholPolicyFileSelected($event)"></app-file-uploader>
          <div class="grid lg:grid-cols-2 gap-4">
            <app-outline-date-input formControlName="drugAndAlcoholPolicyDate"
                                    [label]="'Drug & Alcohol policy date'"
                                    class="w-full" [fullWidth]="true"
                                    [inputDateFormat]="InputDateFormat.mmddyyyy"></app-outline-date-input>
          </div>
          <app-file-uploader formControlName="drugTestResultFilePath" label="Attach drug test result"
                             [isRequired]="true" [uploading]="drugTestResultState.loading()"
                             (fileSelected)="onDrugTestResultFileSelected($event)"></app-file-uploader>

          <div class="grid lg:grid-cols-2 gap-6">
            <app-outline-date-input formControlName="drugTestResultCompletedDate"
                                    [label]="'Drug test completed date'"
                                    class="w-full" [fullWidth]="true"
                                    [inputDateFormat]="InputDateFormat.mmddyyyy"></app-outline-date-input>
          </div>

          <app-file-uploader formControlName="drugTestCCFFilePath" label="Attach drug test CCF (Custody Control Form)"
                             [isRequired]="true" [uploading]="drugTestCCFState.loading()"
                             (fileSelected)="onDrugTestCCFFileSelected($event)"></app-file-uploader>
          <div class="grid lg:grid-cols-2 gap-4">
            <app-outline-date-input formControlName="drugTestCCFCompletedDate"
                                    [label]="'Drug test CCF result completed date'"
                                    class="w-full" [fullWidth]="true"
                                    [inputDateFormat]="InputDateFormat.mmddyyyy"></app-outline-date-input>
          </div>

          <div class="grid lg:grid-cols-2 gap-4">

            <app-outline-date-input formControlName="mroVerificationDate" [label]="'MRO Verification Date'"
                                    class="w-full" [fullWidth]="true"
                                    [inputDateFormat]="InputDateFormat.mmddyyyy"></app-outline-date-input>
          </div>
          <div class="flex flex-col py-4 gap-3">
            <app-base-input [title]="'Please provide the drug test result'">
              <div input class="flex gap-3">
                <app-radio-button formControlName="isDrugTestResultPositive" groupName="isDrugTestResultPositive"
                                  title="Positive"
                                  [value]="true"></app-radio-button>
                <app-radio-button formControlName="isDrugTestResultPositive" groupName="isDrugTestResultPositive"
                                  title="Negative"
                                  [value]="false"></app-radio-button>
              </div>
            </app-base-input>
          </div>
          <div class="flex flex-col py-3 gap-3">
            <app-base-input [title]="'Do you want to start the SAP program for this applicant?'">
              <div input class="flex gap-3">
                <app-radio-button formControlName="hasStartedSAPProgram" groupName="hasStartedSAPProgram" title="Yes"
                                  [value]="true"></app-radio-button>

                <app-radio-button formControlName="hasStartedSAPProgram" groupName="hasStartedSAPProgram" title="No"
                                  [value]="false"></app-radio-button>
              </div>
            </app-base-input>
            @if (driverHiringForm.get('hasStartedSAPProgram')?.value) {
              <div class="grid lg:grid-cols-2 gap-3 py-2">
                <app-outline-text-input formControlName="sapTestFrequency"
                                        [label]="'SAP test frequency'" placeholder="Ex: Every 30 days"
                                        class="w-full" [fullWidth]="true"></app-outline-text-input>
                <app-outline-text-input formControlName="sapTestDuration"
                                        [label]="'SAP test duration'" placeholder="Ex: Every 365 days"
                                        class="w-full" [fullWidth]="true"></app-outline-text-input>
              </div>
            }
            <div class="flex flex-col py-3 gap-6">
              <p class="text-body2 text-neutral-800">Please upload the "Return to Duty" if the applicant was already in
                SAP Program</p>
              <app-file-uploader formControlName="returnToDutyFilePath"
                                 label="Return to Duty document" [uploading]="returnToDutyState.loading()"
                                 (fileSelected)="onReturnToDutyFileSelected($event)"></app-file-uploader>
              <app-file-uploader formControlName="clearingHouseLimitedQueryPolicyFilePath"
                                 label="Clearinghouse Limited Query Policy"
                                 [uploading]="clearingHouseLimitedQueryPolicyState.loading()"
                                 (fileSelected)="onClearingHouseLimitedQueryPolicyFileSelected($event)"></app-file-uploader>


            </div>
            <div class="grid lg:grid-cols-2 gap-4">
              <app-outline-date-input formControlName="clearingHouseLimitedQueryPolicyDate"
                                      [label]="'Clearinghouse limited query policy  date'"
                                      class="w-full" [fullWidth]="true"
                                      [inputDateFormat]="InputDateFormat.mmddyyyy"></app-outline-date-input>

              <app-outline-date-input formControlName="clearingHouseLimitedQueryPolicyExpiryDate"
                                      [label]="'Clearinghouse limited query policy expiry date'"
                                      class="w-full" [fullWidth]="true"
                                      [inputDateFormat]="InputDateFormat.mmddyyyy"></app-outline-date-input>
            </div>
            <div class="flex flex-col py-3 gap-6">
              <app-file-uploader formControlName="dmvPrintOutOrMVRFilePath"
                                 label="DMV Printout / Motor Vehicle Record (MVR) "
                                 [isRequired]="true" [uploading]="dmvPrintOutOrMVRState.loading()"
                                 (fileSelected)="onDMVPrintOutOrMVRFileSelected($event)"></app-file-uploader>
              <app-file-uploader formControlName="medicalExaminerCertificateFilePath"
                                 label="Medical examiner certificate"
                                 [isRequired]="true" [uploading]="medicalExaminerCertificateState.loading()"
                                 (fileSelected)="onMedicalExaminerCertificateFileSelected($event)"
              ></app-file-uploader>
              <p class="text-body2 text-neutral-800">Please verify the medical examiner's listed on the National
                Registry</p>
              <a
                href="https://nationalregistry.fmcsa.dot.gov/search-medical-examiners"
                class="text-body2 text-info-500 cursor-pointer underline"
                target="_blank"
                rel="noopener noreferrer">
                https://nationalregistry.fmcsa.dot.gov/search-medical-examiners
              </a>


              <app-file-uploader formControlName="medicalExaminerNationalRegistryScreenshotFilePath"
                                 label="Screenshot of National Registry Reference"
                                 [uploading]="screenshotOfNationalRegistryReferenceState.loading()"
                                 (fileSelected)="onScreenshotOfNationalRegistryReferenceFileSelected($event)"></app-file-uploader>
            </div>
            <div class="grid lg:grid-cols-2 gap-4">
              <app-outline-date-input formControlName="medicalExaminerCertificateExpirationDate"
                                      [label]="'Medical examiner certificate expiration date'"
                                      class="w-full" [fullWidth]="true"
                                      [inputDateFormat]="InputDateFormat.mmddyyyy"></app-outline-date-input>
            </div>

            <div class="flex flex-col py-6 gap-6">

              <app-file-uploader formControlName="proficiencyTestResultFilePath"
                                 label="Proficiency Test Result (Road test)"
                                 [isRequired]="true" [uploading]="proficiencyTestResultState.loading()"
                                 (fileSelected)="onProficiencyTestResultFileSelected($event)"></app-file-uploader>
              <p class="mx-auto items-center text-body2">OR</p>
              <div
                class="p-2 flex justify-center items-center w-full border rounded-lg  border-success-500 cursor-pointer"
                (click)="onStartProficiencyTest()">
                <app-svg-icon src="assets/icons/menu-right.svg" class="text-success-500" [size]="32"></app-svg-icon>
                <a class="text-success-500 text-body1">Start Proficiency Test (Road Test)</a>
              </div>
            </div>

            @if (companyInfoState.response()?.data?.stateId === 'CALIFORNIA') {
              <div class="line my-2"></div>
              <p class="text-body1 text-primary-600">California company related documents</p>
              <app-file-uploader formControlName="employerPullNoticeFilePath"
                                 label="Employer Pull Notice (EPN)" [uploading]="employerPullNoticeState.loading()"
                                 (fileSelected)="onEmployerPullNoticeFileSelected($event)"></app-file-uploader>

            }
            <div class="flex flex-col py-4 gap-6">
              <div class="line my-2"></div>
              <p class="text-body1 text-primary-600">Required for SPAB (or) School Bus Company</p>

              <app-base-input [title]="'Does the driver has a SPAB Certificate?'">
                <div input class="flex py-2 gap-3">
                  <app-radio-button formControlName="hasSPABCertificate" groupName="hasSPABCertificate" title="Yes"
                                    [value]="true"></app-radio-button>
                  <app-radio-button formControlName="hasSPABCertificate" groupName="hasSPABCertificate" title="No"
                                    [value]="false"></app-radio-button>
                </div>
              </app-base-input>

              @if (companyInfoState.response()?.data?.businessTypeId === 'BUS' &&
              driverHiringForm.get('hasSPABCertificate')?.value ?? false) {

                <app-file-uploader formControlName="spabCertificateFilePath" label="SPAB Certificate"
                                   [isRequired]="true" [uploading]="spabCertificateState.loading()"
                                   (fileSelected)="onSPABCertificateFileSelected($event)"></app-file-uploader>
                <div class="grid lg:grid-cols-2 gap-4">
                  <app-outline-date-input formControlName="spabCertificateIssuedDate"
                                          [label]="'SPAB certificate issued date'"
                                          class="w-full" [fullWidth]="true"
                                          [inputDateFormat]="InputDateFormat.mmddyyyy"></app-outline-date-input>
                </div>
                <app-file-uploader formControlName="t01CertificateFilePath"
                                   label="T-01 Certification (Optional)" [uploading]="t01CertificateState.loading()"
                                   (fileSelected)="onT01CertificateFileSelected($event)"></app-file-uploader>

                <div class="grid lg:grid-cols-2 gap-4">
                  <app-outline-date-input formControlName="t01CertificateIssuedDate"
                                          [label]="'T-01 certificate issued date'"
                                          class="w-full" [fullWidth]="true"
                                          [inputDateFormat]="InputDateFormat.mmddyyyy"></app-outline-date-input>
                </div>
                <app-file-uploader formControlName="t02CertificateFilePath"
                                   label="T-02 Certification (Optional)" [uploading]="t02CertificateState.loading()"
                                   (fileSelected)="onT02CertificateFileSelected($event)"></app-file-uploader>

                <div class="grid lg:grid-cols-2 gap-4">
                  <app-outline-date-input formControlName="t02CertificateIssuedDate"
                                          [label]="'T-02 certificate issued date'"
                                          class="w-full" [fullWidth]="true"
                                          [inputDateFormat]="InputDateFormat.mmddyyyy"></app-outline-date-input>
                </div>
              }
            </div>
            <div class="line my-2"></div>
            <p class="text-body1 text-primary-600">HAZMAT related documents</p>
            <div class="flex flex-col py-2  gap-3">
              <app-base-input [title]="'Would this driver transports HAZMAT material?'">
                <div input class="flex gap-3">
                  <app-radio-button formControlName="driverTransportsHazmat" groupName="hazMat" title="Yes"
                                    [value]="true"></app-radio-button>
                  <app-radio-button formControlName="driverTransportsHazmat" groupName="hazMat" title="No"
                                    [value]="false"></app-radio-button>
                </div>
              </app-base-input>
            </div>
            @if (companyInfoState.response()?.data?.transportsHazmat === true &&
            companyInfoState.response()?.data?.transportsHazwaste === true &&
            driverHiringForm.get('driverTransportsHazmat')?.value ?? false) {
              <div class="py-4">
                <app-file-uploader formControlName="hazmatCertificateFilePath" label="HAZMAT Certificate"
                                   [isRequired]="true" [uploading]="onHAZMATCertificateState.loading()"
                                   (fileSelected)="onHAZMATCertificateFileSelected($event)"></app-file-uploader>
              </div>
              <div class="grid lg:grid-cols-2 gap-4">
                <app-outline-date-input formControlName="hazmatCertificateIssuedDate"
                                        [label]="'HAZMAT certificate issued date'"
                                        class="w-full" [fullWidth]="true"
                                        [inputDateFormat]="InputDateFormat.mmddyyyy"></app-outline-date-input>

                <app-outline-date-input formControlName="hazmatCertificateExpiryDate"
                                        [label]="'HAZMAT expiration date'"
                                        class="w-full" [fullWidth]="true"
                                        [inputDateFormat]="InputDateFormat.mmddyyyy"></app-outline-date-input>
              </div>
            }
            <app-checkbox formControlName="confirmationOfRequirements"
                          title="I confirm that all details provided by the job applicant have been verified, and the candidate meets the necessary requirements."></app-checkbox>
          </div>
        </div>
      </form>
    </div>

    <div class="flex justify-end border-t border-neutral-200 px-4 py-4">

      <div class="flex items-center gap-4">
        <app-button [fullWidth]="true" [textButtonColor]="'text-primary-600'" [outlineColor]="'border-primary-500'"
                    appearance="outline"
                    (click)="onSaveChangesClicked()"
                    [loading]="saveDriverHiringState.loading()">SAVE CHANGES
        </app-button>
        <app-button [fullWidth]="true" type="submit" [loading]="hireDriverState.loading()"
                    (click)="onHireDriverClicked()">HIRE APPLICANT
        </app-button>
      </div>

    </div>
  </div>
</div>


