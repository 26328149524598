import {Component, inject, OnInit} from '@angular/core';
import {BaseDialogComponent} from "@shared/components/_base/base-dialog/base-dialog.component";
import {ButtonComponent} from "@shared/components/button/button.component";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {
  SingleSelectionFieldComponent
} from "@shared/inputs/single-selection-field/single-selection-field.component";
import {BaseComponent} from "@shared/base/base-component";
import {ClassOfEquipmentResponse} from "@core/services/common/class-of-equipment/class-of-equipment-response";
import {State} from "@shared/base/base-state";
import {CommonService} from "@core/services/common/common.service";
import {OutlineTextInputComponent} from "@shared/inputs/outline-text-input/outline-text-input.component";

@Component({
  selector: 'app-create-new-equipment',
  standalone: true,
  imports: [
    BaseDialogComponent,
    ButtonComponent,
    FormsModule,
    ReactiveFormsModule,
    SingleSelectionFieldComponent,
    OutlineTextInputComponent
  ],
  templateUrl: './create-new-equipment.component.html',
  styleUrl: './create-new-equipment.component.scss'
})
export class CreateNewEquipmentComponent extends BaseComponent implements OnInit {

  commonService = inject(CommonService);
  formBuilder = inject(FormBuilder);

  classOfEquipmentState = new State<ClassOfEquipmentResponse>();

  createEquipmentForm!: FormGroup;


  ngOnInit() {
    this.initForm();
    this.getClassOfEquipment();
  }

  private initForm() {
    return this.createEquipmentForm = this.formBuilder.group(
      {
        classOfEquipment: ["", Validators.required],
        unit: ["", Validators.required],
        year: ["", Validators.required],
        make: ["", Validators.required],
        model: ["", Validators.required],
        vin: ["", Validators.required],
        licensePlate: ["", Validators.required],
        licensePlateExpiryDate: ["", Validators.required],
        registrationState: ["", Validators.required],
        tireSize: ["", Validators.required],
        gvwrInIBS: ["", Validators.required],
        currentMilage: ["", Validators.required],
        ownerIfLeased: ["", Validators.required],
        lastServiceDate: ["", Validators.required],
        nextServiceDate: ["", Validators.required],
      }
    )
  }

  private getClassOfEquipment() {
    this.executeRequest({
      state: this.classOfEquipmentState,
      request: this.commonService.getClassOfEquipment()
    });
  }

}
