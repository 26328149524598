/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Api
 * OpenAPI spec version: 1.0
 */

export type GetDriverApplicationListItemApplicationStatusEnum = typeof GetDriverApplicationListItemApplicationStatusEnum[keyof typeof GetDriverApplicationListItemApplicationStatusEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetDriverApplicationListItemApplicationStatusEnum = {
  Invited: 'Invited',
  ApplicationStarted: 'ApplicationStarted',
  DriverVerificationPending: 'DriverVerificationPending',
  NeedsApproval: 'NeedsApproval',
  UnderVerification: 'UnderVerification',
  ResubmissionRequested: 'ResubmissionRequested',
  ReVerificationPending: 'ReVerificationPending',
  Hired: 'Hired',
  Rejected: 'Rejected',
} as const;
