import {Component, inject, OnInit} from '@angular/core';
import {ButtonComponent} from "@shared/components/button/button.component";
import {MatDialog} from "@angular/material/dialog";
import {BaseDialogComponent} from "@shared/components/_base/base-dialog/base-dialog.component";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {State} from "@shared/base/base-state";

@Component({
  selector: 'app-driver-application-status-message',
  standalone: true,
  imports: [
    ButtonComponent,
    BaseDialogComponent,
    ReactiveFormsModule,
    FormsModule
  ],
  templateUrl: './driver-application-status-message.component.html',
  styleUrl: './driver-application-status-message.component.scss'
})
export class DriverApplicationStatusMessageComponent implements OnInit {

  readonly dialog = inject(MatDialog);
  formBuilder = inject(FormBuilder);

  changeDriverApplicationMessageState = new State<any>();

  changeDriverApplicationMessageForm!: FormGroup;

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.changeDriverApplicationMessageForm = this.formBuilder.group(
      {
        messageChange: ["", Validators.required],
      });
  }

  onSubmitClicked() {
    if (this.changeDriverApplicationMessageForm.valid) {
      this.dialog.closeAll();
    }
  }
}
