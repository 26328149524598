<div class="flex items-center gap-3">
  <label class="relative flex items-center rounded-full cursor-pointer py-3" [for]="id()">
    <input type="checkbox"
           (change)="onCheckboxSelected($event)"
           [formControl]="formControl"
           class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-sm border-2 border-neutral-600 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-primary-200 before:opacity-0 before:transition-opacity checked:border-primary-500 checked:bg-primary-500 checked:before:bg-neutral-900"
           [id]="id()"
           (keydown)="handleKeydown($event)"/>
    <span
      class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
           stroke="currentColor" stroke-width="1">
        <path fill-rule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clip-rule="evenodd"></path>
      </svg>
    </span>
  </label>
  @if(title()) {
    <label class="m-0 text-body2 cursor-pointer select-none" [for]="id()">{{ title() }}</label>
  }
</div>
