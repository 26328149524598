<div class="app-container !p-0">

  <div class="flex p-4 gap-5 justify-between bg-white items-center">

    <app-page-header title="Job Applications" description="View and manage all job applications."></app-page-header>

    <div class="flex gap-3 items-center">
      <app-button
        (click)="onExportClicked()"
        [iconSrc]="'assets/icons/export.svg'"
        [buttonColor]="'bg-white'"
        [appearance]="'outline'"
        [textButtonColor]="'text-primary-500'"
        [loading]="exportXlsxDriverApplicationSignedUrlState.loading()">
        Export
      </app-button>
      <app-button [iconSrc]="'assets/icons/plus.svg'" [matMenuTriggerFor]="menu">New Application</app-button>
    </div>
  </div>

  <div class="app-container-padding h-full overflow-auto">
    <app-data-table [columnDefs]="columnDefs" [state]="applicationState"
                    (onActionPerformed)="onAction($event)"></app-data-table>
  </div>
</div>

<mat-menu #menu="matMenu" class="mat-elevation-z3 mt-4">
  <button mat-menu-item class="flex items-center" (click)="onSendDriverApplicationClicked()">
    <div class="flex items-center gap-2">
      <app-svg-icon class="text-neutral-400" [src]="'assets/icons/share.svg'"></app-svg-icon>
      <p class="text-body2">Send Driver Application</p>
    </div>
  </button>
  <button (click)="onDownloadDriverApplicationClicked()" mat-menu-item class="flex items-center">
    <div class="flex items-center gap-2">
      <app-svg-icon class="text-neutral-400" [src]="'assets/icons/download.svg'"></app-svg-icon>
      <p class="text-body2">Download Application</p>
    </div>
  </button>
</mat-menu>
