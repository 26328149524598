import {Component, inject, signal} from "@angular/core";
import {AppSvgIconComponent} from "@shared/components/app-svg-icon/app-svg-icon.component";
import {CommonModule} from "@angular/common";
import {
  SidebarMenuComponent,
  SideMenuItem,
} from "./sidebar-menu/sidebar-menu.component";
import {LayoutService} from "../../layout.service";
import {AppDataService} from "@core/services/app-data/app-data.service";

@Component({
  selector: "app-sidebar",
  standalone: true,
  imports: [
    AppSvgIconComponent,
    CommonModule,
    SidebarMenuComponent,
  ],
  templateUrl: "./sidebar.component.html",
  styleUrl: "./sidebar.component.scss",
})
export class SidebarComponent {
  layoutService = inject(LayoutService);
  appDataService = inject(AppDataService);

  menus = signal<SideMenuItem[]>([
    {
      iconPath: "assets/icons/dashboard.svg",
      link: "/dashboard",
      label: "Dashboard",
      isEnabled: true,
    },
    {
      iconPath: "assets/icons/format-list-checkbox.svg",
      link: "/driver-applications",
      label: "Job Applications",
      isEnabled: true,
    },
    {
      iconPath: "assets/icons/drivers.svg",
      link: "/drivers",
      label: "Drivers",
      isEnabled: true,
    },
    {
      iconPath: "assets/icons/equipment.svg",
      link: "/equipments",
      label: "Equipment",
      isEnabled: false
    },
    {
      iconPath: "assets/icons/terminals.svg",
      link: "/terminals",
      label: "Terminals",
      isEnabled: true,
    },
    {
      iconPath: "assets/icons/account.svg",
      link: "/users",
      label: "Users",
      isEnabled: true,
    },
    {
      iconPath: 'assets/icons/settings.svg',
      link: '',
      label: 'Maintenance Settings',
      isEnabled: false,
      submenus: [
        {
          iconPath: '',
          link: 'maintenance-settings/service-types',
          label: 'Service Types',
          isEnabled: true,
        },
        {
          iconPath: '',
          link: 'maintenance-settings/repair-types',
          label: 'Repair Types',
          isEnabled: true,
        },
        {
          iconPath: '',
          link: 'maintenance-settings/out-of-service-reasons',
          label: 'Out of Service Reasons',
          isEnabled: true,
        }
      ]
    }
  ]);

  settingsMenu = signal<SideMenuItem>({
    iconPath: "assets/icons/settings.svg",
    link: "/company-settings",
    label: "Settings",
    isEnabled: true,
  });

  toggleSidebar() {
    this.layoutService.toggleSidebar();
  }

  onMenuClick(menu: { label: string; iconPath: string }) {
    this.layoutService.setCurrentMenu(menu);
  }
}
