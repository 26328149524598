import {Component, inject, OnInit} from '@angular/core';
import {BaseComponent} from "@shared/base/base-component";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {BaseDialogComponent} from "@shared/components/_base/base-dialog/base-dialog.component";
import {ButtonComponent} from "@shared/components/button/button.component";
import {OutlineTextInputComponent} from "@shared/inputs/outline-text-input/outline-text-input.component";
import {
  InputDateFormat,
  OutlineDateInputComponent
} from "@shared/inputs/outline-date-input/outline-date-input.component";
import {RadioButtonComponent} from "@shared/inputs/radio-button/radio-button.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {State} from "@shared/base/base-state";
import {ApiService} from "@core/api/api.service";
import {
  CreateDriverRequest,
  CreateDriverResponse,
  GetCountryListItem,
  GetCountryListResponse, GetStateListItem,
  GetStateListResponse
} from "@core/api/model";
import {DateUtils} from "@shared/utils/date-utils";
import {DropdownComponent} from "@shared/inputs/dropdown/dropdown.component";
import {LicenseClassesResponse} from "@core/services/common/license-classes/license-classes-response";
import {CommonService} from "@core/services/common/common.service";

@Component({
  selector: 'app-create-new-driver',
  standalone: true,
  imports: [
    BaseDialogComponent,
    ButtonComponent,
    OutlineTextInputComponent,
    ReactiveFormsModule,
    OutlineDateInputComponent,
    RadioButtonComponent,
    DropdownComponent
  ],
  templateUrl: './create-new-driver.component.html',
  styleUrl: './create-new-driver.component.scss'
})
export class CreateNewDriverComponent extends BaseComponent implements OnInit {

  formBuilder = inject(FormBuilder);
  dialog = inject(MatDialog);
  dialogRef = inject(MatDialogRef);
  apiService = inject(ApiService);
  commonService = inject(CommonService);

  createDriverState = new State<CreateDriverResponse>();
  countryListState = new State<GetCountryListResponse>();
  stateListState = new State<GetStateListResponse>();
  licenseClassesState = new State<LicenseClassesResponse>();

  filteredStates: GetStateListItem[] = [];

  createDriverForm!: FormGroup;

  ngOnInit(): void {
    this.initForm();
    this.getCountries();
    this.getLicenseClasses();
  }

  private initForm() {
    this.createDriverForm = this.formBuilder.group({
      firstName: [null, Validators.required],
      middleName: [null],
      lastName: [null, Validators.required],
      phoneNumber: [null, Validators.required],
      email: [null, Validators.required],
      dateOfBirth: [null, Validators.required],
      dateOfHire: [null],
      hasUsWorkLegality: [true, Validators.required],
      licenseNo: [null, Validators.required],
      country: [null, Validators.required],
      state: [null, Validators.required],
      class: [null, Validators.required],
      issueDate: [null, Validators.required],
      expirationDate: [null, Validators.required]


    });
  }


  onSaveClicked() {

    if (this.createDriverForm.invalid) {
      return;
    }

    let dateUtils = new DateUtils();

    let formValue = this.createDriverForm.value;

    let request: CreateDriverRequest = {
      firstName: formValue.firstName,
      middleName: formValue.middleName,
      lastName: formValue.lastName,
      phoneNumber: formValue.phoneNumber,
      email: formValue.email,
      dateOfBirth: dateUtils.convertToDateTimeString(formValue.dateOfBirth) ?? '',
      hiredDate: dateUtils.convertToDateTimeString(formValue.dateOfHire) ?? '',
      hasUsWorkLegality: formValue.hasUsWorkLegality,
      licenseNo: formValue.licenseNo,
      country: formValue.country,
      state: formValue.state,
      class: formValue.class,
      issueDate: formValue.issueDate,
      expirationDate: formValue.expirationDate
    };

    this.executeRequest<CreateDriverResponse>({
      state: this.createDriverState,
      request: this.apiService.createDriver(request),
      handleSuccess: true,
      handleError: true,
      onSuccess: response => {
        this.dialogRef.close(true);
      }
    });

  }

  getCountries() {
    this.executeRequest<GetCountryListResponse>({
      state: this.countryListState,
      request: this.apiService.getCountryList(),
      onSuccess: response => {
        this.getStateList();
      }
    });
  }

  getStateList() {
    this.executeRequest<GetStateListResponse>({
      state: this.stateListState,
      request: this.apiService.getStateList(),
    });
  }

  onCountrySelected(selectedItem: GetCountryListItem | null) {
    const stateList = this.stateListState.response()?.data ?? [];
    this.filteredStates = stateList.filter(
      (data) => data.countryId == selectedItem?.id
    );
  }

  private getLicenseClasses() {
    this.executeRequest<LicenseClassesResponse>({
      state: this.licenseClassesState,
      request: this.commonService.getLicenseClasses(),
    });
  }

  onCancelClicked() {
    this.dialog.closeAll()
  }

  protected readonly InputDateFormat = InputDateFormat;
}
