import { Component } from '@angular/core';
import {NoDataComponent} from "@shared/components/no-data/no-data.component";

@Component({
  selector: 'app-equipments-info',
  standalone: true,
  imports: [
    NoDataComponent
  ],
  templateUrl: './equipments-info.component.html',
  styleUrl: './equipments-info.component.scss'
})
export class EquipmentsInfoComponent {

}
