import { Component } from '@angular/core';

@Component({
  selector: 'app-context-menu-icon',
  standalone: true,
    imports: [
    ],
  templateUrl: './context-menu-icon.component.html',
  styleUrl: './context-menu-icon.component.scss'
})
export class ContextMenuIconComponent {

}
