import {Component} from '@angular/core';
import {GetDriverListItem} from "@core/api/model";
import {TableCustomComponent} from "@shared/components/data-table/table-custom-component/table-custom.component";

@Component({
  selector: 'app-driver-table-expanded-info',
  standalone: true,
  imports: [],
  templateUrl: './driver-table-expanded-info.component.html',
  styleUrl: './driver-table-expanded-info.component.scss'
})
export class DriverTableExpandedInfoComponent extends TableCustomComponent<GetDriverListItem>{

}
