<app-base-dialog [title]="dialogData ? 'Update Repair Type' : 'Create Repair Type'">
  <div body>
    <form [formGroup]="createOrUpdateRepairTypeForm">
      <div class="flex flex-col gap-4 justify-center items-center my-6 w-full">
        <app-outline-text-input formControlName="name" [label]="'Name'" type="text"
                                class="w-full mx-6" placeholder="Ex: Engine Repair" [fullWidth]="true"></app-outline-text-input>
        <app-text-area-input formControlName="description" [label]="'Description'" type="text"
                             class="w-full mx-6" placeholder="Ex: Diagnosis and repair of engine-related issues." [fullWidth]="true"></app-text-area-input>
      </div>
    </form>
  </div>
  <div actions>
    <div class="flex justify-end gap-2">
      <app-button [buttonColor]="'bg-primary-600'" [appearance]="'outline'" [fullWidth]="true"
                  [textButtonColor]="'text-primary-600'" [outlineColor]="'border-primary-500'" (click)="closeDialog()">
        Cancel
      </app-button>
      <app-button [buttonColor]="'bg-primary-600'" [fullWidth]="true" (click)="onSaveClicked()"
                  [loading]="createRepairTypeState.loading() || updateRepairTypeState.loading()">
        {{ dialogData ? 'Update Repair Type' : 'Create Repair Type' }}
      </app-button>
    </div>
  </div>
</app-base-dialog>
