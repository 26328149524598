import { Component, inject, OnInit } from "@angular/core";
import { OutlineTextInputComponent } from "@shared/inputs/outline-text-input/outline-text-input.component";
import { ButtonComponent } from "@shared/components/button/button.component";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { State } from "@shared/base/base-state";
import { BaseComponent } from "@shared/base/base-component";
import { RouterLink } from "@angular/router";
import { TokenStoreService } from "@core/services/token/token-store.service";
import {ApiService} from "@core/api/api.service";
import {type Login, LoginResponse} from "@core/api/model";

@Component({
  selector: "app-login",
  standalone: true,
  templateUrl: "./login.component.html",
  styleUrl: "./login.component.scss",
  imports: [
    OutlineTextInputComponent,
    ButtonComponent,
    ReactiveFormsModule,
    RouterLink,
  ],
})
export class LoginComponent extends BaseComponent implements OnInit {
  tokenStoreService = inject(TokenStoreService);
  apiService = inject(ApiService);
  formBuilder = inject(FormBuilder);

  loginForm!: FormGroup;

  loginState = new State<LoginResponse>();

  ngOnInit(): void {
    this.handleLogin();
    this.loginForm = this.formBuilder.group({
      username: ["", Validators.required],
      password: ["", Validators.required],
    });
  }

  handleLogin() {
    const isLoggedIn = this.tokenStoreService.isLoggedIn();
    if (isLoggedIn) {
      this.router.navigate(["/dashboard"]);
    }
  }

  onForgotPasswordClicked() {
    this.router.navigate(["/forgot-password"]);
  }

  onLoginClicked() {
    if (this.loginForm.invalid) {
      return;
    }
    let request = this.loginForm.value;
    this.executeRequest<LoginResponse>({
      state: this.loginState,
      request: this.apiService.login(request),
      handleSuccess: false,
      onSuccess: (response) => {
        this.handleLoginSuccess(response.data);
      }
    });
  }

  private handleLoginSuccess(loginResponse: Login) {
    this.loginForm.reset();
    this.tokenStoreService.saveToken(
      loginResponse.refreshToken,
      loginResponse.accessToken
    );
    if (loginResponse.userDetail.isProfileCompleted) {
      this.router.navigate(["/dashboard"]);
    } else {
      this.router.navigate(["/setup-company-profile"]);
    }
  }
}
