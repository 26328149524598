import {Component, inject, input} from '@angular/core';
import {MatDialogActions, MatDialogContent, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-base-dialog',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogActions
  ],
  templateUrl: './base-dialog.component.html',
  styleUrl: './base-dialog.component.scss'
})
export class BaseDialogComponent {
  title = input.required<String>();
  showTitle = input(true);
  showAction = input(true);
  dialogRef = inject(MatDialogRef<any>);

  onCloseClicked() {
    this.dialogRef.close();
  }
}
