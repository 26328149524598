<div class="mx-4 p-6">
  <div class="flex flex-col gap-4 bg-white p-4 rounded-md">
    <div class="mx-auto py-4">
      <p class="p-2 text-body1 text-center">PROFICIENCY / ROAD TEST</p>
    </div>
    <form [formGroup]="proficiencyTestForm" class="p-4">
      <div class="grid grid-cols gap-3">
        <span class="text-body1 text-primary-600">Type of Equipment</span>
        <div class="py-2 flex items-center gap-3">
          <app-radio-button formControlName="proficiencyTestEquipmentType" [groupName]="'typeofEquipment'" title="Truck"
                            [value]="'truck'"></app-radio-button>
          <app-radio-button formControlName="proficiencyTestEquipmentType" [groupName]="'typeofEquipment'" title="Bus"
                            [value]="'bus'"></app-radio-button>
        </div>
      </div>
      <div class="py-6 w-full text-body1 text-neutral-800">
        Instructions: If the road test is successfully completed, the person who gave it shall complete a certificate of
        the driver’s road test.
        The original or copy of the certificate shall be retained in the employing motor carrier’s driver qualification
        file of
        the person examined and a copy given to the person who was examined.<span
        class="text-info-500"> (49 CFR 391.31</span>(e)(f)(g))
      </div>
      <div class="grid lg:grid-cols-1 py-2 gap-4 ">
        <app-outline-text-input formControlName="driversName" [label]="'Driver’s name'" placeholder="Ex: John"
                                class="w-full" [fullWidth]="true"></app-outline-text-input>
      </div>
      @if (proficiencyTestForm.get('proficiencyTestEquipmentType')?.value === 'truck') {
        <div class="grid grid-cols py-2 gap-3">
          <span class="text-body1 text-primary-600">Equipment Category</span>
          <div class="py-1 flex items-center gap-3">
            <app-radio-button formControlName="equipmentCategory" [groupName]="'equipmentCategory'"
                              title="Straight Truck"
                              [value]="'straightTruck'"></app-radio-button>
            <app-radio-button formControlName="equipmentCategory" [groupName]="'equipmentCategory'"
                              title="Tractor & trailer combination"
                              [value]="'tractorAndTrailerCombination'"></app-radio-button>
            <app-radio-button formControlName="equipmentCategory" [groupName]="'equipmentCategory'"
                              title="Double | Triple"
                              [value]="'doubleOrTriple'"></app-radio-button>
            <app-radio-button formControlName="equipmentCategory" [groupName]="'equipmentCategory'"
                              title="Tank Vehicle"
                              [value]="'tankVehicle'"></app-radio-button>
          </div>
          <div class="py-1 flex items-center gap-3">
            <app-radio-button formControlName="equipmentCategory" [groupName]="'equipmentCategory'"
                              title="Vehicle less than 10,000 pounds GVWR"
                              [value]="'vehicleLessThan10000Pounds'"></app-radio-button>
            <app-radio-button formControlName="equipmentCategory" [groupName]="'equipmentCategory'"
                              title="Vehicles 10,000 pounds to 26,000 pounds GVWR"
                              [value]="'vehiclesMoreThan10000Pounds'"></app-radio-button>
          </div>
          <div class="py-1 flex items-center gap-3">
            <app-radio-button formControlName="equipmentCategory" [groupName]="'equipmentCategory'"
                              title="Vehicles over 26,000 pounds and more GVWR"
                              [value]="'vehiclesOver26000PoundsAndMore'"></app-radio-button>
          </div>
        </div>
      }
      @if (proficiencyTestForm.get('proficiencyTestEquipmentType')?.value === 'bus') {
        <div class="grid grid-cols gap-3">
          <span class="text-body1 text-primary-600">Bus with air brakes</span>
          <div class="py-1 flex items-center gap-3">
            <app-radio-button formControlName="busWithAirBrakes" [groupName]="'busWithAirBrakes'" title="Yes"
                              [value]="true"></app-radio-button>
            <app-radio-button formControlName="busWithAirBrakes" [groupName]="'busWithAirBrakes'" title="No"
                              [value]="false"></app-radio-button>
          </div>
        </div>
        <div class="grid grid-cols py-4 gap-3">
          <span class="text-body1 text-primary-600">Bus with hydraulic brakes</span>
          <div class="py-1 flex items-center gap-3">
            <app-radio-button formControlName="busWithHydraulicBrakes" [groupName]="'busWithHydraulicBrakes'"
                              title="Yes"
                              [value]="true"></app-radio-button>
            <app-radio-button formControlName="busWithHydraulicBrakes" [groupName]="'busWithHydraulicBrakes'" title="No"
                              [value]="false"></app-radio-button>
          </div>
        </div>
        <div class="grid grid-cols py-4 gap-3">
          <span class="text-body1 text-primary-600">Bus with wheelchair</span>
          <div class="py-1 flex items-center gap-3">
            <app-radio-button formControlName="busWithWheelChair" [groupName]="'busWithWheelChair'"
                              title="Yes"
                              [value]="true"></app-radio-button>
            <app-radio-button formControlName="busWithWheelChair" [groupName]="'busWithWheelChair'" title="No"
                              [value]="false"></app-radio-button>
          </div>
        </div>
        <div class="grid lg:grid-cols-1 py-2 gap-4 ">
          <app-outline-text-input formControlName="numberofPassengers" [label]="'No. of passengers'"
                                  placeholder="Ex: 40"
                                  class="w-full" [fullWidth]="true"></app-outline-text-input>
        </div>
      }
      @if (proficiencyTestForm.get('proficiencyTestEquipmentType')?.value === 'truck') {
        <div class="grid grid-cols py-2 gap-3">
          <span class="text-body1 text-primary-600">Transmission</span>
          <div class="py-1 flex items-center gap-3">
            <app-radio-button formControlName="transmission" [groupName]="'transmission'"
                              title="Standard shift transmission"
                              [value]="'standardShiftTransmission'"></app-radio-button>
            <app-radio-button formControlName="transmission" [groupName]="'transmission'"
                              title="Automatic transmission only"
                              [value]="'automaticTransmissionOnly'"></app-radio-button>
          </div>
        </div>
      }
      <div class="grid grid-cols py-2 gap-3">
        <span class="text-body1 text-primary-600">Air Brakes</span>
        <div class="py-1 flex items-center gap-3">
          <app-radio-button formControlName="airBrakes" [groupName]="'airBrakes'" title="Yes"
                            [value]="true"></app-radio-button>
          <app-radio-button formControlName="airBrakes" [groupName]="'airBrakes'" title="No"
                            [value]="false"></app-radio-button>
        </div>
      </div>
      @if (proficiencyTestForm.get('proficiencyTestEquipmentType')?.value === 'truck') {
        <div class="grid grid-cols py-2 gap-3">
          <span class="text-body1 text-primary-600">Hazardous Materials Endorsement</span>
          <div class="py-1 flex items-center gap-3">
            <app-radio-button formControlName="hazmatEndorsement" [groupName]="'hazmatEndorsement'" title="Yes"
                              [value]="true"></app-radio-button>
            <app-radio-button formControlName="hazmatEndorsement" [groupName]="'hazmatEndorsement'" title="No"
                              [value]="false"></app-radio-button>
          </div>
        </div>
      }
      <div class="grid grid-cols gap-3">
        <span class="text-body1 text-primary-600">Special Equipment</span>
        <div class="py-1 flex items-center gap-3">
          <app-radio-button formControlName="specialEquipment" [groupName]="'specialEquipment'" title="Yes"
                            [value]="true"></app-radio-button>
          <app-radio-button formControlName="specialEquipment" [groupName]="'specialEquipment'" title="No"
                            [value]="false"></app-radio-button>
        </div>
      </div>
      <div class="grid lg:grid-cols-1 py-4 gap-4 ">
        <app-outline-text-input formControlName="specialEquipmentName" [label]="'Specify if (Special equipment)'"
                                placeholder="Ex: Truck"
                                class="w-full" [fullWidth]="true"></app-outline-text-input>
      </div>
      <div class="line my-4"></div>
      <div class="grid lg:grid-cols-2 py-4 gap-4">
        <app-outline-text-input formControlName="distanceDriven" [label]="'Distance Driven (in miles)'"
                                placeholder="Ex: 3500" class="w-full" [fullWidth]="true"></app-outline-text-input>
        <app-outline-date-input formControlName="proficiencyTestConductedDate" [label]="'Date'"
                                class="w-full" [fullWidth]="true"
                                [inputDateFormat]="InputDateFormat.mmddyyyy"></app-outline-date-input>
      </div>
      <div class="grid lg:grid-cols-2 gap-4">
        <app-outline-text-input formControlName="proficiencyTestExaminerFirstName" [label]="'Examiner first name'"
                                placeholder="Ex: John" class="w-full" [fullWidth]="true"></app-outline-text-input>
        <app-outline-text-input formControlName="proficiencyTestExaminerLastName" [label]="'Examiner Last name'"
                                placeholder="Ex: Bravo" class="w-full" [fullWidth]="true"></app-outline-text-input>
      </div>
      <p class="py-4 text-body2 text-neutral-800">Signature of Examiner</p>
      <app-signature-pad [width]="'500'" [height]="'200'" [backgroundColor]="'rgba(255, 255, 255, 0)'"
                         (signatureChanged)="onSignatureSaved($event)" (signatureCleared)="onSignatureCleared()"
                         [penColor]="'black'">
      </app-signature-pad>
      <div class="grid lg:grid-cols-2 gap-4 py-4">
        <app-checkbox class="col-span-2" formControlName="proficiencyTestExaminerSignature"
                      title="This is to certify that the above-named driver was given a road test under my supervision. It is my considered opinion that this driver possesses sufficient driving skill to safely operate the type of commercial motor vehicle listed above.">
        </app-checkbox>
      </div>
      <app-button class="my-2" [fullWidth]="true" type="submit" [loading]="proficiencyTestState.loading()"
                  (click)="onCompleteTest()">COMPLETE
        TEST
      </app-button>
    </form>
  </div>
</div>
