<div class="app-container w-full">
  <div class="grow overflow-auto mx-8 px-4 overflow-y-scroll">
    <div class="py-6 text-center">
      <p class="text-body1">Clearinghouse Policy</p>
    </div>
    <p class="py-4 text-body1 text-neutral-800">I hereby provide my unconditional consent to conduct a limited query
      of the FMCSA Commercial Driver’s License Drug and Alcohol Clearinghouse to determine whether drug or alcohol
      violation information about me exists in the Clearinghouse. I further understand and consent to allow my
      employer
      to conduct limited queries at intervals as determined at their exclusive discretion or as required or requested
      by any regulatory and/or
      law enforcement agency for the entire term of my employment.</p>
    <p class="py-4 text-body1 text-neutral-800">I understand that if the limited query conducted indicates that drug
      or alcohol violation information about me exists in the Clearinghouse, FMCSA will not disclose that information
      to my employer without first obtaining additional specific consent from me.</p>
    <p class="py-4 text-body1 text-neutral-800">In the event that any limited query reveals that the Clearinghouse
      contains information about me, I agree to grant electronic consent within 24 hours, via the Clearinghouse
      website,
      allowing my employer to obtain my full Clearinghouse record. Refusal to provide such consent as described herein
      will result in my removal from safety-sensitive duties. I further understand that if I refuse to provide consent
      to conduct a limited query of the Clearinghouse, my employer must prohibit me from performing safety-sensitive
      functions,
      including driving a commercial motor vehicle, as required by FMCSA’s drug and alcohol program regulations.</p>
    <form [formGroup]="clearingHousePolicyAgreementForm">
      <p> I, <span><input type="text" formControlName="name"
                          class="border-b border-neutral-800 outline-none bg-transparent w-auto text-neutral-800"></span>
        understand the rules listed above and consent to annual queries during the term of my employment. I understand
        if I refuse any query I will face disciplinary action up to and including termination.</p>
      <div class="flex flex-col gap-1 my-4">
        <label class="text-body2 text-neutral-800">Signature</label>
        <app-signature-pad [width]="'500'" [height]="'200'"
                           [backgroundColor]="'rgba(255, 255, 255, 0)'"
                           [penColor]="'black'"
                           (signatureChanged)="onSignatureSaved($event)"
                           (signatureCleared)="onSignatureCleared()">
        </app-signature-pad>
        <app-outline-text-input formControlName="signatureDate" [label]="'Date'"></app-outline-text-input>
      </div>
      <app-checkbox class="col-span-2" formControlName="acknowledgement"
                    title="I hereby acknowledge and sign solely as the candidate for the position outlined, confirming my understanding and acceptance."></app-checkbox>
      <div class="py-2">
        <app-button [fullWidth]="true" type="submit">Submit
        </app-button>
      </div>
    </form>
  </div>
</div>


