<div class="flex justify-center container mx-auto h-full w-full p-4">
  <div class="flex flex-col bg-white w-full rounded-2xl">
    <div class="flex p-4 items-center justify-between w-full border-b border-neutral-100">
      <p class="text-body1 text-info-500">SAP Program History</p>
      <app-button class="px-2"
                  appearance="textType"
                  textButtonColor="text-neutral-600" [iconColor]="'text-neutral-500'"
                  [iconSrc]="'assets/icons/arrow-left.svg'" (buttonClick)="onBackClicked()">Back
      </app-button>
    </div>
    <div class="flex-grow overflow-auto">
      <app-data-table
        class="w-full"
        [state]="getSapProgramListState"
        (onActionPerformed)="onActionClicked($event)"
        [columnDefs]="columnDefs"
        [enableColumnsConfig]="true">
      </app-data-table>
    </div>
  </div>
</div>
