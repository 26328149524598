import {Component, input, signal} from '@angular/core';
import {BaseInputComponent} from "@shared/inputs/_base/base-input/base-input.component";
import {HumanizeFormMessagesPipe} from "@shared/inputs/humanize-form-messages.pipe";
import {BaseControlValueAccessorV2} from "@shared/base/base-control-value-accessor-v2";
import {ReactiveFormsModule} from "@angular/forms";

@Component({
  selector: 'app-phone-number-input',
  imports: [
    BaseInputComponent,
    HumanizeFormMessagesPipe,
    ReactiveFormsModule,
  ],
  templateUrl: './phone-number-input.component.html',
  styleUrl: './phone-number-input.component.scss'
})
export class PhoneNumberInputComponent extends BaseControlValueAccessorV2<any>{

  title = input<string>();
  errorMessages = signal<{ [key: string]: string }>({});
  showErrorSpace = input<boolean>(false);
  placeholder = input<string>('');

  isFocused = signal(false);

  protected override onValueReady(value: any): void {

  }

  onValueChange(event: any) {
    const target = event.target as HTMLInputElement;
    this.onChange(target.value);
  }

  onFocus() {
    this.isFocused.set(true);
  }

  onBlur() {
    this.onTouched();
    this.isFocused.set(false);
  }
}
