import {Component, inject, OnInit} from '@angular/core';
import {BaseTableComponent} from "@shared/components/_base/base-table/base-table.component";
import {ButtonComponent} from "@shared/components/button/button.component";
import {ContextMenuIconComponent} from "@shared/components/context-menu-icon/context-menu-icon.component";
import {DatePipe} from "@angular/common";
import {OutlineTextInputComponent} from "@shared/inputs/outline-text-input/outline-text-input.component";
import {BaseComponent} from "@shared/base/base-component";
import {DriverApplicationService} from "@core/services/driver-application/driver-application.service";
import {State} from "@shared/base/base-state";
import {equipmentsResponse} from "@core/services/equipments/equipments-response";
import {CreateNewEquipmentComponent} from "../create-new-equipment/create-new-equipment.component";
import {Constants} from "@core/constants/constants";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-equipments',
  standalone: true,
  imports: [
    BaseTableComponent,
    ButtonComponent,
    ContextMenuIconComponent,
    DatePipe,
    OutlineTextInputComponent
  ],
  templateUrl: './equipments-list.component.html',
  styleUrl: './equipments-list.component.scss'
})
export class EquipmentsListComponent extends BaseComponent implements OnInit {

  driverService = inject(DriverApplicationService);
  dialog = inject(MatDialog);

  equipmentsState = new State<equipmentsResponse>();

  ngOnInit() {
    this.getEquipmentsList();
  }

  getEquipmentsList() {
    this.executeRequest(
      {
        state: this.equipmentsState,
        request: this.driverService.getEquipmentsList()
      }
    );
  }

  onCreateNewEquipment() {
    this.dialog.open(CreateNewEquipmentComponent, {
      ...Constants.defaultDialogConfig,
    });
  }

}
