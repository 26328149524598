<div
  class="flex items-center gap-1 px-1 py-2 rounded-md cursor-pointer select-none"
  [matTooltip]="menuItem().label"
  matTooltipPosition="right"
  matTooltipClass="ml-3"
  [matTooltipDisabled]="layoutService.sidebarExpanded"
  [ngClass]="isActive() ? 'bg-primary-600' : 'bg-primary-800'"
  (click)="onMenuClicked(menuItem())">
  <app-svg-icon class="text-white" [src]="menuItem().iconPath" [size]="18"></app-svg-icon>
  @if (layoutService.sidebarExpanded) {
    <p class="w-full text-caption text-neutral-300 px-2">{{ menuItem().label }}</p>
  }

  @if ((menuItem().submenus ?? []).length > 0) {
    <app-svg-icon class="text-white"
                  [src]="menuItem().expanded ? 'assets/icons/chevron-down.svg' : 'assets/icons/chevron-right.svg'"></app-svg-icon>
  }


</div>

<div class="max-h-0 overflow-hidden transition-all duration-700 ease-in-out"
     [ngClass]="{hidden: !layoutService.sidebarExpanded , 'max-h-screen': menuItem().expanded && menuItem().submenus?.length ?? 0}">
  <div class="flex flex-col gap-0.5">
    @for (subMenu of menuItem().submenus ?? []; track subMenu) {
      <div (click)="onMenuClicked(subMenu)" class="flex items-center w-full px-3 py-3  hover:bg-primary-500 transition-all duration-700 rounded-lg">
        <span class="w-[24px]"></span>
        <p class="text-caption cursor-pointer text-neutral-100">{{ subMenu.label }}</p>
      </div>
    }
  </div>
</div>
