import {Component, inject, OnInit} from "@angular/core";
import {FormGroup, Validators, FormBuilder} from "@angular/forms";
import {ReactiveFormsModule} from "@angular/forms";
import {ButtonComponent} from "@shared/components/button/button.component";
import {OutlineTextInputComponent} from "@shared/inputs/outline-text-input/outline-text-input.component";
import {BaseDialogComponent} from "@shared/components/_base/base-dialog/base-dialog.component";
import {BaseComponent} from "@shared/base/base-component";
import {State} from "@shared/base/base-state";
import {
  DriverApplicationSentConfirmationComponent
} from "../driver-application-sent-confirmation/driver-application-sent-confirmation.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Constants} from "@core/constants/constants";
import {ApiService} from "@core/api/api.service";
import {
  type GetTerminalListResponse,
  type SendDriverApplicationRequest,
  SendDriverApplicationResponse
} from "@core/api/model";
import {DropdownComponent} from "@shared/inputs/dropdown/dropdown.component";

@Component({
  selector: "app-send-driver-application",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    ButtonComponent,
    OutlineTextInputComponent,
    BaseDialogComponent,
    DropdownComponent,
  ],
  templateUrl: "./send-driver-application.component.html",
  styleUrl: "./send-driver-application.component.scss",
})
export class SendDriverApplicationComponent
  extends BaseComponent
  implements OnInit {

  formBuilder = inject(FormBuilder);
  dialog = inject(MatDialog);
  dialogRef = inject(MatDialogRef);
  apiService = inject(ApiService);

  sendDriverApplicationState = new State<SendDriverApplicationResponse>();

  sendDriverApplicationForm!: FormGroup;
  terminalState = new State<GetTerminalListResponse>();


  ngOnInit() {
    this.initForm();
    this.getTerminals();
  }

  initForm() {
    this.sendDriverApplicationForm = this.formBuilder.group({
      email: [null, Validators.required],
      terminalId: [null, Validators.required]
    });
  }

  getTerminals() {
    this.executeRequest<GetTerminalListResponse>({
      state: this.terminalState,
      request: this.apiService.getTerminalList(),
      onSuccess: (response) => {
        console.log(response);
      },
    });
  }

  onSendApplicationClicked() {

    this.validateForm(this.sendDriverApplicationForm);

    if(this.sendDriverApplicationForm.invalid) {
      return;
    }

    let formValue = this.sendDriverApplicationForm.value;
    let request: SendDriverApplicationRequest = {
      email: formValue.email,
      terminalId: formValue.terminalId
    };

    this.executeRequest({
      state: this.sendDriverApplicationState,
      request: this.apiService.sendDriverApplication(request),
      onSuccess: (response) => {
        this.dialogRef.close(true);
        this.openApplicationConfirmationDialog(response);
      },
    });
  }

  openApplicationConfirmationDialog(response: SendDriverApplicationResponse) {
    this.dialog.open(DriverApplicationSentConfirmationComponent, {
      data: {message:response},
      ...Constants.defaultDialogConfig,
    });
  }

  onCloseClicked() {
    this.dialogRef.close(false);
  }
}
