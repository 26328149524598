<div class="flex justify-center container mx-auto h-full w-full p-4">
  <div class="flex flex-col bg-white w-full rounded-2xl">

  <div class="flex py-4 px-3 gap-3 border-b border-neutral-50 justify-end">
    <app-outline-text-input
      [debounceSearchEnabled]="false"
      (changeValue)="onDocumentsSearch($event)"
      [iconSrc]="'assets/icons/magnify.svg'"
      placeholder="Search..."
      type="search"
      [fullWidth]="false">
    </app-outline-text-input>
  </div>

    <div class="grow overflow-auto m-8 px-4 overflow-y-scroll">

      <div class="flex flex-col gap-4">

        @if (driverDocumentsState.loading()) {
          <div class="flex flex-col gap-8">
            <app-shimmer type="multiline"></app-shimmer>
            <app-shimmer type="multiline"></app-shimmer>
            <app-shimmer type="multiline"></app-shimmer>
            <app-shimmer type="multiline"></app-shimmer>
            <app-shimmer type="multiline"></app-shimmer>
            <app-shimmer type="multiline"></app-shimmer>
            <app-shimmer type="multiline"></app-shimmer>
            <app-shimmer type="multiline"></app-shimmer>
            <app-shimmer type="multiline"></app-shimmer>
          </div>
        }

        @for (documentSection of driverDocumentsState.response()?.data; track documentSection.sectionName; let last = $last) {
          <span class="text-body1 text-primary-900">{{ documentSection.sectionName }}</span>

          @if (matchedDocumentCount(documentSection) <= 0) {
            <span class="text-caption text-neutral-500">No documents match your search.</span>
          }

          <div class="flex flex-col gap-3">
            @for (document of documentSection.documents; track document) {
              @if (isFiltered(document)) {
                <app-document-view [document]="document"></app-document-view>
              }
            }
          </div>


          @if (!last) {
            <div class="border-b border-neutral-100 w-full"></div>
          }
        }
      </div>


    </div>
  </div>
</div>
