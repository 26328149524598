import {Component, inject, OnInit} from '@angular/core';
import {ButtonComponent} from "@shared/components/button/button.component";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {
  OutlineTextInputComponent
} from "@shared/inputs/outline-text-input/outline-text-input.component";
import type { CreateUserResponse, GetRoleListResponse} from "@core/api/model";
import {State} from "@shared/base/base-state";
import {BaseComponent} from "@shared/base/base-component";

import {BaseDialogComponent} from "@shared/components/_base/base-dialog/base-dialog.component";
import {
  SingleSelectionFieldComponent
} from "@shared/inputs/single-selection-field/single-selection-field.component";
import {MatDialogRef} from "@angular/material/dialog";
import {ApiService} from "@core/api/api.service";

@Component({
  selector: 'app-create-user',
  standalone: true,
  imports: [
    ButtonComponent,
    FormsModule,
    OutlineTextInputComponent,
    ReactiveFormsModule,
    SingleSelectionFieldComponent,
    BaseDialogComponent
  ],
  templateUrl: './create-user.component.html',
  styleUrl: './create-user.component.scss'
})
export class CreateUserComponent extends BaseComponent implements OnInit {

  apiService = inject(ApiService);
  formBuilder = inject(FormBuilder);
  dialogRef = inject(MatDialogRef);

  createUserState = new State<CreateUserResponse>();
  roleListState = new State<GetRoleListResponse>();

  createUserForm!: FormGroup;

  ngOnInit() {
    this.initForm();
    this.getRole();
  }

  private initForm(): void {
    this.createUserForm = this.formBuilder.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      email: ["", Validators.required],
      phoneNumber: ["", Validators.required],
      roleId: ["", Validators.required],
    });
  }

  getRole() {
    this.executeRequest<GetRoleListResponse>({
      state: this.roleListState,
      request: this.apiService.getRoleList(),
    });
  }

  onCreateUserClicked() {
    this.validateForm(this.createUserForm);
    if (this.createUserForm.invalid) {
      return;
    }
    let request = this.createUserForm.value;
    this.executeRequest({
      state: this.createUserState,
      request: this.apiService.createUser(request),
      handleSuccess: true,
      onSuccess: (response) => {
        this.dialogRef.close(true);
      },
    });
  }
}
