import {Component, inject, OnInit} from '@angular/core';
import {ButtonComponent} from "@shared/components/button/button.component";
import {ColumnDef, DataTableComponent, TableActionEvent} from "@shared/components/data-table/data-table.component";
import {State} from "@shared/base/base-state";
import {MatDialog} from "@angular/material/dialog";
import {Constants} from "@core/constants/constants";
import {CreateTerminalComponent} from "./create-terminal/create-terminal.component";
import {BaseComponent} from "@shared/base/base-component";
import {
  GetTerminal, type GetTerminalListItem,
  GetTerminalListResponse,
} from "@core/api/model";
import {ApiService} from "@core/api/api.service";
import {UpdateTerminalComponent} from "./update-terminal/update-terminal.component";
import {PageHeaderComponent} from "@core/components/page-header/page-header.component";


@Component({
  selector: 'app-terminals',
  imports: [
    ButtonComponent,
    DataTableComponent,
    PageHeaderComponent
  ],
  templateUrl: './terminals.component.html',
  standalone: true,
  styleUrl: './terminals.component.scss'
})
export class TerminalsComponent extends BaseComponent implements OnInit {

  dialog = inject(MatDialog);
  apiService = inject(ApiService);
  getTerminalListState = new State<GetTerminalListResponse>();

  columnDefs: ColumnDef[] = [
    {
      title: 'Code',
      type: 'text',
      alignment: 'center',
      displayTemplate: '$code',
      sortKey: 'code'
    },
    {
      title: 'Name',
      type: 'text',
      alignment: 'center',
      displayTemplate: '$name',
      sortKey: 'name'
    },
    {
      title: 'Address',
      type: 'text',
      displayTemplate: '$addressLine1, $addressLine2',
      alignment: 'center',
      sortKey: 'address',
      pinned: 'left'
    },
    {
      title: 'Actions',
      type: 'actions',
      alignment: 'center',
      actionsConfig: {
        iconActions: [{
          iconPath: 'assets/icons/edit.svg',
          actionKey: 'edit', label: 'Update Terminals'
        }
        ],
      }
    },
  ];

  ngOnInit() {
    this.getTerminalList();
  }

  getTerminalList() {
    this.executeRequest<GetTerminalListResponse>({
      state: this.getTerminalListState,
      request: this.apiService.getTerminalList()
    })
  }

  onActionClicked($event: TableActionEvent) {
    let item: GetTerminalListItem = $event.item;
    switch ($event.actionKey) {
      case 'edit':
        this.onUpdateTerminalClicked(item);
        break;
    }
  }

  onUpdateTerminalClicked(item: GetTerminal) {
    this.dialog.open(UpdateTerminalComponent, {
      ...Constants.defaultDialogConfig,
      data: item,
    }).afterClosed().subscribe((success) => {
      if (success) {
        this.getTerminalList();
      }
    });
  }

  onNewTerminalClicked() {
    this.dialog.open(CreateTerminalComponent, {
      ...Constants.defaultDialogConfig,
    }).afterClosed().subscribe((success) => {
      if (success) {
        this.getTerminalList();
      }
    })
  }

}
