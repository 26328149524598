import {TableStateEvent} from "@shared/components/data-table/data-table.component";


export class Constants {

  static defaultDialogConfig = {
    // minWidth: '200px',
    // maxWidth: '600px',
    panelClass: ['w-full', 'sm:w-4/5', 'md:w-3/5', 'lg:w-2/5'],
    maxHeight: '95vh',
  };

  static defaultPaginationConfig = {
    pageNumber: 1,
    pageSize: 50
  };

  static defaultTableStateEvent: TableStateEvent = {
    searchText: '',
    paginationEvent: {
      pageNumber: 1,
      pageSize: 50
    },
    tableSortEvent: {
      key: undefined,
      direction: undefined
    }
  };

}
