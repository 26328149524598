import {Component, inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {OutlineTextInputComponent} from "@shared/inputs/outline-text-input/outline-text-input.component";
import {SignaturePadComponent} from "@core/components/signature-pad/signature-pad.component";
import {ButtonComponent} from "@shared/components/button/button.component";
import {CheckboxComponent} from "@shared/inputs/checkbox/checkbox.component";

@Component({
  selector: 'app-clearinghouse-policy-agreement',
  imports: [
    FormsModule,
    ReactiveFormsModule,
    OutlineTextInputComponent,
    SignaturePadComponent,
    ButtonComponent,
    CheckboxComponent
  ],
  templateUrl: './clearinghouse-policy-agreement.component.html',
  standalone: true,
  styleUrl: './clearinghouse-policy-agreement.component.scss'
})
export class ClearinghousePolicyAgreementComponent implements OnInit {

  formBuilder = inject(FormBuilder);

  clearingHousePolicyAgreementForm!: FormGroup;

  ngOnInit() {
    this.clearingHousePolicyAgreementForm = this.formBuilder.group({
      name: ['', Validators.required],
      signatureDate: ['', Validators.required],
      acknowledgement: ['', Validators.required]
    });
  }

  onSignatureSaved(signatureBase64: string) {
    this.clearingHousePolicyAgreementForm.controls["signature"].setValue(signatureBase64);
  }

  onSignatureCleared() {
    this.clearingHousePolicyAgreementForm.controls['signature'].setValue(null);
  }

}
