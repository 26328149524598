<app-base-dialog [title]="'Create new driver'">
  <div body>
    <form [formGroup]="createDriverForm" class="flex flex-col gap-3">
      <div class="grid grid-cols-2 gap-4">
        <app-outline-text-input formControlName="firstName" label="First Name" placeholder="Ex: John" class="w-full"
                                [fullWidth]="true"></app-outline-text-input>
        <app-outline-text-input formControlName="middleName" label="Middle Name" placeholder="Ex: Michael"
                                class="w-full"
                                [fullWidth]="true"></app-outline-text-input>
      </div>
      <div class="grid lg:grid-cols-2 gap-4">
        <app-outline-text-input formControlName="lastName" label="Last Name" placeholder="Ex:Bravo"
                                class="w-full" [fullWidth]="true"></app-outline-text-input>
      </div>
      <div class="grid grid-cols-2 gap-4">
        <app-outline-text-input formControlName="phoneNumber" label="Phone Number" placeholder="Ex: +1 123-456-78XX"
                                class="w-full" [fullWidth]="true"></app-outline-text-input>
        <app-outline-text-input formControlName="email" label="Email" placeholder="Ex: johnBravo@gmail.com"
                                class="w-full"
                                [fullWidth]="true"></app-outline-text-input>
      </div>
      <div class="grid grid-cols-2 gap-4">
        <app-outline-date-input formControlName="dateOfBirth" [label]="'Date of Birth'"
                                class="w-full" [fullWidth]="true"
                                [inputDateFormat]="InputDateFormat.mmddyyyy"></app-outline-date-input>
        <app-outline-date-input formControlName="dateOfHire" [label]="'Date of Hire'"
                                class="w-full" [fullWidth]="true"
                                [inputDateFormat]="InputDateFormat.mmddyyyy"></app-outline-date-input>
      </div>
      <div class="flex items-center gap-6">
        <span class="text-body2">Have legal right to work in united states?</span>
        <app-radio-button formControlName="hasUsWorkLegality" [groupName]="'hasUsWorkLegality'" title="Yes"
                          [value]="true"></app-radio-button>
        <app-radio-button formControlName="hasUsWorkLegality" [groupName]="'hasUsWorkLegality'" title="No"
                          [value]="false"></app-radio-button>
      </div>
    </form>
  </div>
  <div actions>
    <div class="flex justify-end gap-2">
      <app-button [appearance]="'outline'" [buttonColor]="'border-primary-500'" (click)="onCancelClicked()">Cancel
      </app-button>
      <app-button (click)="onSaveClicked()" [loading]="createDriverState.loading()">Save</app-button>
    </div>
  </div>
</app-base-dialog>




