<div class="app-container !p-0">
  <div class="flex p-4 gap-5 justify-between bg-white items-center">
    <app-page-header title="Repair Types" description="View and manage all Repair Types."></app-page-header>
    <div class="flex gap-3 items-center">
      <app-button (click)="onNewRepairTypeClicked()" [iconSrc]="'assets/icons/plus.svg'">
        New Repair Type
      </app-button>
    </div>
  </div>
  <div class="grow m-4 overflow-y-scroll">
    <app-data-table
      [state]="getRepairTypeState"
      [columnDefs]="columnDefs"
      (onActionPerformed)="onActionClicked($event)"
      [enableColumnsConfig]="true"
      (tableStateChanged)="getRepairTypeList($event)">
    </app-data-table>
  </div>
</div>

