import {Component, inject} from '@angular/core';
import {BaseDialogComponent} from "@shared/components/_base/base-dialog/base-dialog.component";
import {ButtonComponent} from "@shared/components/button/button.component";
import {BaseComponent} from "@shared/base/base-component";
import {State} from "@shared/base/base-state";
import type {EndSapProgramResponse} from "@core/api/model";
import {ApiService} from "@core/api/api.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DialogRef} from "@angular/cdk/dialog";

@Component({
  selector: 'app-end-sap-program',
  imports: [
    BaseDialogComponent,
    ButtonComponent
  ],
  templateUrl: './end-sap-program.component.html',
  standalone: true,
  styleUrl: './end-sap-program.component.scss'
})
export class EndSapProgramComponent extends BaseComponent {

  apiService = inject(ApiService);
  data = inject(MAT_DIALOG_DATA);
  dialogRef = inject(MatDialogRef);

  onEndSAPProgramState = new State<EndSapProgramResponse>();

  onEndSAPProgramClicked() {
    this.executeRequest({
      state: this.onEndSAPProgramState,
      request: this.apiService.endSapProgram(this.data.id),
      handleSuccess: true,
      successMessage: 'The SAP Program ended successfully',
      onSuccess: (response) => {
        this.dialogRef.close(true);
      }
    });
  }

  onCancelClicked() {
    this.dialogRef.close(false);
  }


}
