import {Component, inject, OnInit, output} from "@angular/core";
import {FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators,} from "@angular/forms";
import {
  OutlineTextInputComponent
} from "@shared/inputs/outline-text-input/outline-text-input.component";
import {ButtonComponent} from "@shared/components/button/button.component";
import {RadioButtonComponent} from "@shared/inputs/radio-button/radio-button.component";
import {CommonModule} from "@angular/common";

import {BaseComponent} from "@shared/base/base-component";

import {
  SingleSelectionFieldComponent
} from "@shared/inputs/single-selection-field/single-selection-field.component";
import {State} from "@shared/base/base-state";
import {CommonService} from "@core/services/common/common.service";
import {
  ClassOfEquipmentResponse
} from "@core/services/common/class-of-equipment/class-of-equipment-response";
import {TokenStoreService} from "@core/services/token/token-store.service";
import {DateUtils} from "@shared/utils/date-utils";
import {
  InputDateFormat,
} from "@shared/inputs/outline-date-input/outline-date-input.component";
import {ApiService} from "@core/api/api.service";
import type {
  GetDriverApplicationExperienceItem, GetDriverApplicationExperienceParams,
  GetDriverApplicationExperienceResponse,
  SaveDriverApplicationExperience,
  SaveDriverApplicationExperienceRequest,
  SaveDriverApplicationExperienceResponse
} from "@core/api/model";

@Component({
  selector: "app-save-driving-experience-info",
  templateUrl: "./save-driving-experience-info.component.html",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    OutlineTextInputComponent,
    ButtonComponent,
    RadioButtonComponent,
    CommonModule,
    SingleSelectionFieldComponent
  ],
  styleUrls: ["./save-driving-experience-info.component.scss"],
})
export class SaveDrivingExperienceInfoComponent extends BaseComponent implements OnInit {
  onNextClick = output<void>();
  onPreviousClick = output<void>();

  commonService = inject(CommonService);
  formBuilder = inject(FormBuilder);
  apiService = inject(ApiService);
  tokenService = inject(TokenStoreService);

  drivingExperienceForm!: FormGroup;
  drivingExperienceFormArray!: FormArray;

  classOfEquipmentState = new State<ClassOfEquipmentResponse>();
  drivingExperiencesState = new State<GetDriverApplicationExperienceResponse>();
  saveDrivingExperienceState = new State<SaveDriverApplicationExperienceResponse>();

  ngOnInit(): void {
    this.initForm();
    this.getClassOfEquipment();
    this.getDrivingExperienceInfo();
  }

  private initForm() {
    this.drivingExperienceFormArray = this.formBuilder.array([]);
    this.drivingExperienceForm = this.formBuilder.group({
      hasCommercialDrivingExperience: [false, Validators.required],
      drivingExperiences: this.drivingExperienceFormArray
    });
  }

  private getClassOfEquipment() {
    this.executeRequest({
      state: this.classOfEquipmentState,
      request: this.commonService.getClassOfEquipment()
    });
  }

  getDrivingExperienceInfo() {
    let request: GetDriverApplicationExperienceParams = {
      driverApplicationId: this.tokenService.getDriverApplicationId()
    };
    this.executeRequest<GetDriverApplicationExperienceResponse>({
      state: this.drivingExperiencesState,
      request: this.apiService.getDriverApplicationExperience(request),
      onSuccess: response => {
        let hasCommercialDrivingExperience = response.hasCommercialDrivingExperience;
        this.drivingExperienceForm.controls['hasCommercialDrivingExperience'].setValue(hasCommercialDrivingExperience ?? false);
        this.populateForm(response.data ?? [], hasCommercialDrivingExperience);
      }
    });
  }

  private populateForm(applicantExperiences: GetDriverApplicationExperienceItem[], hasCommercialDrivingExperience: boolean) {
    if (applicantExperiences.length <= 0) {
      let form = this.createDrivingExperienceForm(null);
      this.drivingExperienceFormArray.push(form);
    } else {
      applicantExperiences.forEach((experience, index) => {
        let form = this.createDrivingExperienceForm(experience);
        this.drivingExperienceFormArray.push(form);
      });
    }
  }

  createDrivingExperienceForm(experience: GetDriverApplicationExperienceItem | null): FormGroup {
    let dateUtils = new DateUtils();
    return this.formBuilder.group({
      // id:[experience?.id],
      classOfEquipment: [experience?.classOfEquipment, Validators.required],
      dateFrom: [experience?.dateFrom, Validators.required],
      dateTo: [experience?.dateTo, Validators.required],
      approximateMiles: [experience?.miles, Validators.required],
    });
  }

  onAddExperienceClicked() {
    let newForm = this.createDrivingExperienceForm(null);
    this.drivingExperienceFormArray.push(newForm);
  }

  onDeleteClicked(i: number) {
    this.drivingExperienceFormArray.removeAt(i);
  }

  onSaveAndNextClicked() {
    this.validateForm(this.drivingExperienceForm);
    let hasCommercialDrivingExperience = this.drivingExperienceForm.value.hasCommercialDrivingExperience;
    if (this.drivingExperienceForm.invalid && hasCommercialDrivingExperience) {
      return;
    }

    let request: SaveDriverApplicationExperienceRequest = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
      hasCommercialDrivingExperience: hasCommercialDrivingExperience,
      experiences: []
    };

    if (hasCommercialDrivingExperience) {
      this.drivingExperienceFormArray.controls.forEach((control, index) => {
        let experience = control.value;
        let dateUtils = new DateUtils();
        let drivingExperience: SaveDriverApplicationExperience = {
          displayOrder: index + 1,
          classOfEquipment: experience.classOfEquipment,
          dateFrom: dateUtils.convertToMMYYYYString(experience.dateFrom) ?? '',
          dateTo: dateUtils.convertToMMYYYYString(experience.dateTo) ?? '',
          miles: Number(experience.approximateMiles ?? 0)
        }

        request.experiences.push(drivingExperience);
      });
    }


    this.executeRequest({
      state: this.saveDrivingExperienceState,
      request: this.apiService.saveDriverApplicationExperience(request),
      showLoader: true,
      handleSuccess: true,
      handleError: true,
      onSuccess: response => {
        this.onNextClick.emit();
      }
    });
  }

  onPreviousClicked() {
    this.onPreviousClick.emit();
  }

  protected readonly InputDateFormat = InputDateFormat;
}
