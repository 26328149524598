<div class="flex justify-center container mx-auto h-full w-full p-4">
  <div class="flex flex-col bg-white w-full rounded-md px-4">
    <div class="grow overflow-auto p-4">
      <div class="flex flex-col gap-3">
        <p class="text-h6 text-info-500">Traffic Convictions</p>
        <p class="text-caption text-neutral-500">Please provide us all your traffic convictions</p>
        <form [formGroup]="trafficConvictionForm">
          <div class="flex items-center gap-3 w-full">
            <span class="text-body2">Have you ever been convicted of a traffic violation?</span>
            <app-radio-button formControlName="convictedOfTrafficViolation" groupName="convictedOfTrafficViolation"
                              title="Yes" [value]="true"></app-radio-button>
            <app-radio-button formControlName="convictedOfTrafficViolation" groupName="convictedOfTrafficViolation"
                              title="No" [value]="false"></app-radio-button>
          </div>

          @if (trafficConvictionForm.controls['convictedOfTrafficViolation'].value) {
          <div formArrayName="trafficConvictions">
            @for (group of trafficConvictionFormArray.controls; track group; let i = $index; let isLast = $last; let isFirst = $first)
          {
          <div class="driving-experience-form flex flex-col gap-3 my-4" [formGroupName]="i">
            <span class="text-body2 text-info-500">ADDITIONAL TRAFFIC CONVICTION DETAILS</span>
            <div class="grid lg:grid-cols-2 gap-4">

              <app-outline-date-input formControlName="dateConvicted" [label]="'Date Convicted'"
                                      class="w-full" [fullWidth]="true"
                                      [inputDateFormat]="InputDateFormat.mmddyyyy"></app-outline-date-input>
              <app-outline-text-input formControlName="violation" [label]="'Violation'"
                                      placeholder="Ex: No parking" class="w-full"
                                      [fullWidth]="true"></app-outline-text-input>
            </div>
            <div class="grid grid-cols-2 gap-4">
              <app-outline-text-input formControlName="stateOfViolation" [label]="'State of Violation'"
                                      placeholder="Ex: California" class="w-full"
                                      [fullWidth]="true"></app-outline-text-input>
              <app-outline-text-input formControlName="penalty" [label]="'Penalty'"
                                      placeholder="Ex: No parking violation fine" class="w-full"
                                      [fullWidth]="true"></app-outline-text-input>
            </div>
            @if (!isFirst) {
            <div class="flex justify-end">
              <app-button appearance="outline" outlineColor="border-error-500" textButtonColor="text-error-500"
                          (click)="onDeleteClicked(i)">Delete
              </app-button>
            </div>
            }
          </div>
          @if (!isLast) {
          <div class="w-full h-[1px] bg-neutral-100 my-8"></div>
          }
          }
          <div class="flex flex-col gap-4 my-3">
            <app-button iconSrc="assets/icons/plus.svg" appearance="outline" [fullWidth]="true"
                        (click)="onAddMoreTrafficConvictionsClicked()">ADD MORE TRAFFIC CONVICTIONS
            </app-button>
          </div>
        </div>
        <div class="line my-10"></div>
        <div class="flex flex-col gap-3">
          <app-checkbox
            class="text-body2"
            formControlName="hasMotorLicenseBeenDenied"
            (click) = "subscribeChanges()"
            title="Have you ever been denied a license, permit, or privilege to operate a motor vehicle? Please explain the reason if yes."
          ></app-checkbox>

          @if (trafficConvictionForm.get('hasMotorLicenseBeenDenied')?.value) {
          <app-outline-text-input formControlName="motorLicenseBeenDeniedReason" placeholder="Reason"
                                  class="w-full"
                                  [fullWidth]="true"></app-outline-text-input>
          }
          <app-checkbox class="text-body2" formControlName="hasLicenseBeenSuspendedOrRevoked"
                        title="Has any license, permit, or privilege ever been suspended or revoked? Please explain the reason if yes."></app-checkbox>
          @if (trafficConvictionForm.get('hasLicenseBeenSuspendedOrRevoked')?.value) {
          <app-outline-text-input formControlName="licenseSuspendedOrRevokedReason" placeholder="Reason"
                                  class="w-full" [fullWidth]="true"></app-outline-text-input>
          }
          </div>
          }
        </form>
      </div>
    </div>
    <div class="flex justify-between border-t border-neutral-200 px-4 py-4">
      <app-button appearance="outline" (click)="onPreviousClicked()">Previous</app-button>
      <app-button type="submit" (click)="onSaveAndNextClicked()" [loading]="saveTrafficConvictionsState.loading()">Save
        & Next
      </app-button>
    </div>
  </div>
</div>
