import {Component, inject, OnInit} from '@angular/core';
import {BaseDialogComponent} from "@shared/components/_base/base-dialog/base-dialog.component";
import {BaseComponent} from "@shared/base/base-component";
import {ApiService} from '@core/api/api.service';
import type {GetCompanyTerminalListResponse} from "@core/api/model";
import {State} from '@shared/base/base-state';

@Component({
  selector: 'app-terminal-overlay-selection',
  imports: [
    BaseDialogComponent
  ],
  templateUrl: './terminal-selection-overlay.component.html',
  styleUrl: './terminal-selection-overlay.component.scss'
})
export class TerminalSelectionOverlayComponent extends BaseComponent implements OnInit {

  apiService = inject(ApiService);
  companyUserTerminals = new State<GetCompanyTerminalListResponse>();

  ngOnInit(): void {
    this.getCompanyUserTerminals();
  }

  getCompanyUserTerminals() {
    this.executeRequest<GetCompanyTerminalListResponse>({
      request: this.apiService.getCompanyTerminalList(),
      state: this.companyUserTerminals
    });
  }
}
