import {
  Component,
  inject,
  OnInit,
} from "@angular/core";
import {MatDialog, MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";
import {MatIconModule} from "@angular/material/icon";
import {CommonModule} from "@angular/common";
import {ButtonComponent} from "@shared/components/button/button.component";
import {BaseComponent} from "@shared/base/base-component";
import {
  DriverApplication,
} from "@core/services/driver-application/application/driver-application-response";
import {State} from "@shared/base/base-state";
import {
  SendDriverApplicationComponent
} from "../../../drivers/overlays/send-driver-application/send-driver-application.component";
import {Constants} from "@core/constants/constants";
import type {
  DownloadDriverApplicationFormResponse,
  ExportXlsxDriverApplicationSignedUrlResponse,
  GetDriverApplicationListParams,
  GetDriverApplicationListResponse,
  MarkDriverApplicationRejectedResponse,
  MarkDriverApplicationResubmissionResponse,
  MarkDriverApplicationUnderVerificationResponse
} from "@core/api/model";
import {TokenStoreService} from "@core/services/token/token-store.service";
import {ApiService} from "@core/api/api.service";
import {ColumnDef, DataTableComponent, TableActionEvent} from "@shared/components/data-table/data-table.component";
import {AppSvgIconComponent} from "@shared/components/app-svg-icon/app-svg-icon.component";
import {PageHeaderComponent} from "@core/components/page-header/page-header.component";
import {
  TableCellDriverApplicationStatusComponent
} from "./table-cell-driver-application-status/table-cell-driver-application-status.component";


@Component({
  selector: "app-driver-application-list",
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatDialogModule,
    AppSvgIconComponent,
    PageHeaderComponent,
    DataTableComponent,
  ],
  templateUrl: "./driver-application-list.component.html",
  styleUrl: "./driver-application-list.component.scss",
})
export class DriverApplicationListComponent extends BaseComponent implements OnInit {

  dialog = inject(MatDialog);
  tokenService = inject(TokenStoreService);
  apiService = inject(ApiService);

  applicationState = new State<GetDriverApplicationListResponse>();
  markApplicationUnderVerificationState = new State<MarkDriverApplicationUnderVerificationResponse>();
  markApplicationRejectedState = new State<MarkDriverApplicationRejectedResponse>();
  markDriverApplicationResubmissionState = new State<MarkDriverApplicationResubmissionResponse>()
  exportXlsxDriverApplicationSignedUrlState = new State<ExportXlsxDriverApplicationSignedUrlResponse>();
  exportDriverApplicationSignedUrlState = new State<DownloadDriverApplicationFormResponse>();

  columnDefs: ColumnDef[] = [
    {
      title: 'Email',
      type: 'text',
      alignment: 'left',
      displayTemplate: '$email',
      sortKey: 'email'
    },
    {
      title: 'Application Date',
      type: 'date',
      alignment: 'center',
      displayTemplate: '$applicationDate',
      sortKey: 'applicationDate',
      dateConfig: {
        dateFormat: 'dd MMM, yyyy hh:mm a',
        showIcon: false
      }
    },
    {
      title: 'Invited By',
      type: 'text',
      alignment: 'center',
      displayTemplate: '$invitedBy',
      sortKey: 'invitedBy',
    },
    {
      title: 'Status',
      type: 'custom',
      component: TableCellDriverApplicationStatusComponent,
      alignment: 'left',
    },
    {
      title: 'Actions',
      type: 'actions',
      alignment: 'center',
      actionsConfig: {
        textMenuActions: [
          {
            iconPath: 'assets/icons/eye.svg',
            actionKey: 'view',
            label: 'View',
          },
        ]
      }
    }]

  ngOnInit(): void {
    this.getDriverApplications(1, 10);
  }

  onSendDriverApplicationClicked() {
    let dialogRef = this.dialog.open(SendDriverApplicationComponent, {
      ...Constants.defaultDialogConfig,
    });

    dialogRef.afterClosed().subscribe((isSuccess) => {
      if (isSuccess) {
        this.getDriverApplications(1, 10);
      }
    });
  }

  getDriverApplications(pageNumber: number, pageSize: number) {
    const request: GetDriverApplicationListParams = {
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    this.executeRequest<GetDriverApplicationListResponse>({
      state: this.applicationState,
      request: this.apiService.getDriverApplicationList(request),
    });
  }

  onExportClicked() {
    this.executeRequest<ExportXlsxDriverApplicationSignedUrlResponse>({
      request: this.apiService.exportXlsxDriverApplicationSignedUrl(),
      state: this.exportXlsxDriverApplicationSignedUrlState,
      onSuccess: (response) => {
        if (response.data.downloadUrl) {
          window.open(response.data.downloadUrl, "_blank");
        }
      }
    });
  }

  onDownloadDriverApplicationClicked() {
    this.executeRequest<DownloadDriverApplicationFormResponse>({
      request: this.apiService.downloadDriverApplicationFormSignedUrl(),
      state: this.exportDriverApplicationSignedUrlState,
      onSuccess: (response) => {
        if (response.data.downloadUrl) {
          window.open(response.data.downloadUrl, "_blank");
        }
      }
    });
  }

  onAction($event: TableActionEvent) {
    let item = $event.item as DriverApplication;
    switch ($event.actionKey) {
      case 'view': {
        this.router.navigate([`/driver-application/apply`], {
          queryParams: {applicationId: item.id},
        }).then();
        break;
      }
      case 'statusChange':
        this.getDriverApplications(1, 50);
        break;
    }
  }
}
