import {Component, inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {BaseDialogComponent} from "@shared/components/_base/base-dialog/base-dialog.component";
import {OutlineTextInputComponent} from "@shared/inputs/outline-text-input/outline-text-input.component";
import {ButtonComponent} from "@shared/components/button/button.component";
import {BaseComponent} from "@shared/base/base-component";
import {ApiService} from "@core/api/api.service";
import type {
  CreateOutOfServiceReasonRequest,
  CreateOutOfServiceReasonResponse,
  GetServiceType, UpdateOutOfServiceReasonRequest, UpdateOutOfServiceReasonResponse,
} from "@core/api/model";
import {State} from "@shared/base/base-state";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TextAreaInputComponent} from "@shared/inputs/text-area-input/text-area-input.component";

@Component({
  selector: 'app-create-or-update-out-of-service-reason',
  imports: [
    ReactiveFormsModule,
    BaseDialogComponent,
    OutlineTextInputComponent,
    ButtonComponent,
    TextAreaInputComponent
  ],
  templateUrl: './create-or-update-out-of-service-reason.component.html',
  standalone: true,
  styleUrl: './create-or-update-out-of-service-reason.component.scss'
})
export class CreateOrUpdateOutOfServiceReasonComponent extends BaseComponent implements OnInit {

  formBuilder = inject(FormBuilder);
  apiService = inject(ApiService);
  dialogRef = inject(MatDialogRef);
  dialogData?: GetServiceType = inject(MAT_DIALOG_DATA);

  createOutOfServiceReasonState = new State<CreateOutOfServiceReasonResponse>();
  updateOutOfServiceReasonState = new State<UpdateOutOfServiceReasonResponse>();

  createOrUpdateOutOfServiceReasonForm!: FormGroup;

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.createOrUpdateOutOfServiceReasonForm = this.formBuilder.group({
      name: [this.dialogData?.name ?? '', Validators.required],
      description: [this.dialogData?.description ?? ''],
    });
  }

  onSaveClicked() {
    this.validateForm(this.createOrUpdateOutOfServiceReasonForm);
    if (!this.createOrUpdateOutOfServiceReasonForm.valid) {
      return;
    }

    if (this.dialogData) {
      this.update();
    } else {
      this.create();
    }
  }

  private create() {
    let formValue = this.createOrUpdateOutOfServiceReasonForm.value;
    let request: CreateOutOfServiceReasonRequest = {
      name: formValue.name,
      description: formValue.description,
    }
    this.executeRequest<CreateOutOfServiceReasonResponse>({
      state: this.createOutOfServiceReasonState,
      request: this.apiService.createOutOfServiceReason(request),
      onSuccess: (response) => {
        this.dialogRef.close(true);
      }
    });
  }

  private update() {
    let formValue = this.createOrUpdateOutOfServiceReasonForm.value;
    let request: UpdateOutOfServiceReasonRequest = {
      name: formValue.name,
      description: formValue.description
    };

    this.executeRequest<UpdateOutOfServiceReasonResponse>({
      state: this.updateOutOfServiceReasonState,
      request: this.apiService.updateOutOfServiceReason(this.dialogData?.id ?? '', request),
      onSuccess: (response) => {
        this.dialogRef.close(true);
      }
    });
  }

  closeDialog() {
    this.dialogRef.close(true);
  }
}
