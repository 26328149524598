<div class="flex justify-center container mx-auto h-full w-full p-4">

  <div class="grow overflow-y-scroll">
    <app-data-table
      [state]="remindersState"
      [columnDefs]="columnDefs"
      (onActionPerformed)="onActionsClicked($event)"
      (tableStateChanged)="getReminders($event)"
      [enableColumnsConfig]="false">

      <app-button [iconSrc]="'assets/icons/plus.svg'"
                  [textButtonColor]="'text-primary-500'"
                  (click)="createReminder()">New Reminder
      </app-button>

    </app-data-table>
  </div>
</div>
