import {Component, inject, input, OnInit} from '@angular/core';
import {ButtonComponent} from "@shared/components/button/button.component";
import {BaseComponent} from "@shared/base/base-component";
import {Constants} from "@core/constants/constants";
import {MatDialog} from "@angular/material/dialog";
import {CreateOrUpdateReminderComponent} from "./create-or-update-reminder/create-or-update-reminder.component";
import {State} from "@shared/base/base-state";
import {ApiService} from "@core/api/api.service";
import type {
  DeleteDriverReminderResponse, GetDriverReminderListItem, GetDriverReminderListParams,
  GetDriverReminderListResponse
} from "@core/api/model";
import {
  ColumnDef,
  DataTableComponent,
  TableActionEvent, TableStateEvent
} from "@shared/components/data-table/data-table.component";
import {DateUtils} from "@shared/utils/date-utils";

@Component({
  selector: 'app-driver-reminders',
  standalone: true,
  imports: [
    ButtonComponent,
    DataTableComponent,
  ],
  templateUrl: './driver-reminders.component.html',
  styleUrl: './driver-reminders.component.scss'
})
export class DriverRemindersComponent extends BaseComponent implements OnInit {

  dialog = inject(MatDialog);
  apiService = inject(ApiService);

  remindersState = new State<GetDriverReminderListResponse>();
  deleteReminderState = new State<DeleteDriverReminderResponse>();

  dateUtils = new DateUtils();

  columnDefs: ColumnDef[] = [
    {
      title: 'Reminder Date',
      type: 'date',
      alignment: 'center',
      displayTemplate: '$reminderDate',
      sortKey: 'reminderDate',
      dateConfig: {
        dateFormat: 'dd MMM, yyyy',
        showIcon: false
      }
    },

    {
      title: 'Reminder Time',
      type: 'date',
      alignment: 'center',
      displayTemplate: '$reminderDate',
      sortKey: 'reminderDate',
      dateConfig: {
        dateFormat: 'hh:mm a',
        showIcon: false
      }
    },
    {
      title: 'Note',
      type: 'text',
      alignment: 'center',
      displayTemplate: '$description',
      sortKey: 'description'
    },
    {
      title: 'Actions',
      type: 'actions',
      alignment: 'center',
      actionsConfig: {
        iconActions: [
          {iconPath: 'assets/icons/edit.svg', actionKey: 'edit', label: 'Edit Reminder'},
          {iconPath: 'assets/icons/bin.svg', actionKey: 'delete', label: 'Delete Reminder'}
        ]
      }
    }
  ];

  driverId?: string;

  ngOnInit() {
    this.listenToRoute();
  }

  listenToRoute() {
    this.route.parent?.paramMap.subscribe((params) => {
      this.driverId = params.get('driverId') ?? undefined;
    });
  }

  getReminders(event: TableStateEvent) {
    let request: GetDriverReminderListParams = {
      pageNumber: event.paginationEvent?.pageNumber,
      pageSize: event.paginationEvent?.pageSize,
      sortingDirection: event.tableSortEvent?.direction ?? 'asc',
      orderByPropertyName: event.tableSortEvent?.key ?? undefined,
      search: event.searchText
    }
    this.executeRequest({
      state: this.remindersState,
      request: this.apiService.getDriverReminderList(request),
    });
  }

  createReminder() {
    this.dialog.open(CreateOrUpdateReminderComponent, {
      ...Constants.defaultDialogConfig,
      data: {
        driverId: this.driverId,
      },
    }).afterClosed().subscribe((result) => {
      if (result) {
        this.getReminders(Constants.defaultTableStateEvent);
      }
    });
  }

  onActionsClicked($event: TableActionEvent) {
    let item = $event.item as GetDriverReminderListItem;
    switch ($event.actionKey) {
      case 'edit':
        this.onEditClicked(item);
        break;
      case'delete':
        this.onDeleteClicked(item.id);
        break;
    }
  }

  onEditClicked(item: GetDriverReminderListItem) {
    this.dialog.open(CreateOrUpdateReminderComponent, {
      ...Constants.defaultDialogConfig,
      data: {
        driverId: this.driverId,
        reminder: item
      },
    }).afterClosed().subscribe((result) => {
      if (result) {
        this.getReminders(Constants.defaultTableStateEvent);
      }
    });
  }

  onDeleteClicked(id: string) {
    this.alertDialog.show('Delete Reminder', 'Are you sure you want to delete this reminder?').subscribe((result) => {
      if (result) {
        this.deleteReminder(id);
      }
    });
  }

  deleteReminder(id: string) {
    this.executeRequest({
      request: this.apiService.deleteDriverReminder(id),
      state: this.deleteReminderState,
      onSuccess: response => {
        this.getReminders(Constants.defaultTableStateEvent);
      }
    });
  }

}
