import {Component, inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {RadioButtonComponent} from "@shared/inputs/radio-button/radio-button.component";
import {SignaturePadComponent} from "@core/components/signature-pad/signature-pad.component";
import {OutlineTextInputComponent} from "@shared/inputs/outline-text-input/outline-text-input.component";
import {CheckboxComponent} from "@shared/inputs/checkbox/checkbox.component";
import {ButtonComponent} from "@shared/components/button/button.component";

@Component({
  selector: 'app-drug-and-alcohol-policy-agreement',
  imports: [
    FormsModule,
    RadioButtonComponent,
    ReactiveFormsModule,
    SignaturePadComponent,
    OutlineTextInputComponent,
    CheckboxComponent,
    ButtonComponent
  ],
  templateUrl: './drug-and-alcohol-policy-agreement.component.html',
  standalone: true,
  styleUrl: './drug-and-alcohol-policy-agreement.component.scss'
})
export class DrugAndAlcoholPolicyAgreementComponent implements OnInit {

  formBuilder = inject(FormBuilder);

  drugAndAlcoholPolicyAgreementForm!: FormGroup;

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.drugAndAlcoholPolicyAgreementForm = this.formBuilder.group({
      name: ['', Validators.required],
      date: ['', Validators.required],
      readPolicy: ['', Validators.required],
      signature: ['', Validators.required],
      signatureDate: ['', Validators.required],
      acknowledgement:['', Validators.required]
    })
  }

  onSignatureSaved(signatureBase64: string) {
    this.drugAndAlcoholPolicyAgreementForm.controls["signature"].setValue(signatureBase64);
  }

  onSignatureCleared() {
    this.drugAndAlcoholPolicyAgreementForm.controls['signature'].setValue(null);
  }

}
