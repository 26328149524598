<div class="flex flex-col bg-white rounded-2xl overflow-clip h-full">

  @if (enableSearch() || headerComponents.length > 0) {
    <div class="flex px-6 py-5 border-b border-neutral-100 justify-between">
      @if (enableSearch()) {
        <app-outline-text-input [iconSrc]="'assets/icons/magnify.svg'"
                                placeholder="Search..."
                                type="search"
                                [fullWidth]="false"
                                (changeValue)="onSearchTextChanged($event)">
        </app-outline-text-input>
      }
      <ng-content></ng-content>
    </div>
  }

  <div class="grow h-full w-full min-w-full bg-white overflow-x-auto">
    <table class="h-full w-full"
           [ngClass]="[enableHorizontallyScrollable() ? 'overflow-x-auto' : '']"
           [appTableResizableColumns]="enableResizableColumns()">

      <!--    Table header-->
      <thead class="sticky top-0 bg-white z-10">
      <tr sortableTable (sortChange)="onSortChanged($event)" class="text-overline text-neutral-500 text-nowrap h-min">

        @if (expandableComponent()) {
          <th class="sticky left-0 z-50 bg-white border-b border-gray-200 px-4 py-3"></th>
        }

        @for (column of columnDefs(); track column) {
          @let isColumnVisible = column.visible || column.visible == undefined || false;
          @if (isColumnVisible) {
            <th
              [attr.data-sortable-key]="column.sortKey"
              class="relative cursor-pointer border-b border-gray-200 px-4 py-3 text-caption font-semibold text-gray-700 uppercase tracking-wider"
              [ngClass]="getThTrClass(column)">
              {{ column.title }}
            </th>
          }
        }

        @if (enableColumnsConfig()) {
          <th
            class="sticky right-0 bg-white flex justify-center cursor-pointer items-center border-b border-gray-200 px-1.5 py-3">
            <div
              #columnsConfigTriggerElement
              (click)="onColumnSettingsClicked(columnsConfigTriggerElement)"
              class="flex justify-center items-center h-[28px] w-[28px] cursor-pointer rounded-full hover:bg-neutral-100 transition-all duration-500">
              <svg class="h-[16px] w-[16px] text-neutral-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                   fill="none">
                <path
                  d="M12 8C13.0608 8 14.0782 8.42143 14.8284 9.17157C15.5785 9.92172 16 10.9391 16 12C16 13.0609 15.5785 14.0783 14.8284 14.8284C14.0782 15.5786 13.0608 16 12 16C10.9391 16 9.92168 15.5786 9.17153 14.8284C8.42139 14.0783 7.99996 13.0609 7.99996 12C7.99996 10.9391 8.42139 9.92172 9.17153 9.17157C9.92168 8.42143 10.9391 8 12 8ZM12 10C11.4695 10 10.9608 10.2107 10.5857 10.5858C10.2107 10.9609 9.99996 11.4696 9.99996 12C9.99996 12.5304 10.2107 13.0391 10.5857 13.4142C10.9608 13.7893 11.4695 14 12 14C12.5304 14 13.0391 13.7893 13.4142 13.4142C13.7892 13.0391 14 12.5304 14 12C14 11.4696 13.7892 10.9609 13.4142 10.5858C13.0391 10.2107 12.5304 10 12 10ZM9.99996 22C9.74996 22 9.53996 21.82 9.49996 21.58L9.12996 18.93C8.49996 18.68 7.95996 18.34 7.43996 17.94L4.94996 18.95C4.72996 19.03 4.45996 18.95 4.33996 18.73L2.33996 15.27C2.20996 15.05 2.26996 14.78 2.45996 14.63L4.56996 12.97L4.49996 12L4.56996 11L2.45996 9.37C2.26996 9.22 2.20996 8.95 2.33996 8.73L4.33996 5.27C4.45996 5.05 4.72996 4.96 4.94996 5.05L7.43996 6.05C7.95996 5.66 8.49996 5.32 9.12996 5.07L9.49996 2.42C9.53996 2.18 9.74996 2 9.99996 2H14C14.25 2 14.46 2.18 14.5 2.42L14.87 5.07C15.5 5.32 16.04 5.66 16.56 6.05L19.05 5.05C19.27 4.96 19.54 5.05 19.66 5.27L21.66 8.73C21.79 8.95 21.73 9.22 21.54 9.37L19.43 11L19.5 12L19.43 13L21.54 14.63C21.73 14.78 21.79 15.05 21.66 15.27L19.66 18.73C19.54 18.95 19.27 19.04 19.05 18.95L16.56 17.95C16.04 18.34 15.5 18.68 14.87 18.93L14.5 21.58C14.46 21.82 14.25 22 14 22H9.99996ZM11.25 4L10.88 6.61C9.67996 6.86 8.61996 7.5 7.84996 8.39L5.43996 7.35L4.68996 8.65L6.79996 10.2C6.39996 11.37 6.39996 12.64 6.79996 13.8L4.67996 15.36L5.42996 16.66L7.85996 15.62C8.62996 16.5 9.67996 17.14 10.87 17.38L11.24 20H12.76L13.13 17.39C14.32 17.14 15.37 16.5 16.14 15.62L18.57 16.66L19.32 15.36L17.2 13.81C17.6 12.64 17.6 11.37 17.2 10.2L19.31 8.65L18.56 7.35L16.15 8.39C15.38 7.5 14.32 6.86 13.12 6.62L12.75 4H11.25Z"
                  fill="currentColor"/>
              </svg>
            </div>
          </th>
        }

      </tr>
      </thead>

      <!--    List Items-->
      <tbody class="h-min">
      <ng-content select="[body]"></ng-content>
      </tbody>

      <!--    Shimmer-->
      @if (state().loading()) {
        <tbody class="bg-white text-center py-14">
        <tr>
          <td colspan="100" class="h-min">
            <div class="flex flex-col gap-6 px-6 py-4">
              <app-shimmer type="multiline"></app-shimmer>
              <app-shimmer type="multiline"></app-shimmer>
              <app-shimmer type="multiline"></app-shimmer>
              <app-shimmer type="multiline"></app-shimmer>
              <app-shimmer type="multiline"></app-shimmer>
              <app-shimmer type="multiline"></app-shimmer>
              <app-shimmer type="multiline"></app-shimmer>
              <app-shimmer type="multiline"></app-shimmer>
              <app-shimmer type="multiline"></app-shimmer>
              <app-shimmer type="multiline"></app-shimmer>
              <app-shimmer type="multiline"></app-shimmer>
            </div>
          </td>
        </tr>
        </tbody>
      }

      <!--    No Table Indicator-->
      @if (state().success() && (state().response()?.data ?? []).length <= 0) {
        <tbody>
        <tr>
          <td colspan="1000">
            <div class="flex flex-col justify-center items-center gap-8 px-6 py-8">
              <app-no-data-table></app-no-data-table>
            </div>
          </td>
        </tr>
        </tbody>
      }

      @if (state().success() && (state().response()?.data ?? []).length > 0) {
        <tbody class="">
          @for (item of state().response()?.data ?? []; track i; let i = $index) {
            <tr (click)="_onRowClicked(item)"
                [ngClass]="[enableClickableRows() ? 'hover:bg-neutral-50 cursor-pointer' : '']">

              @if (expandableComponent()) {
                <td class="sticky left-0 bg-white z-50 px-1 py-3 border-b border-gray-100 w-min select-none">
                  <div
                    (click)="onRowExpandedClicked(i)"
                    class="mx-auto flex items-center justify-center h-[40px] w-[40px] rounded-full bg-neutral-50 hover:bg-neutral-100 cursor-pointer transition-all duration-500">
                    <svg class="h-[20px] w-[20px] text-neutral-500" xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 24 24"
                         fill="none">
                      <path d="M7.41 8.58L12 13.17L16.59 8.58L18 10L12 16L6 10L7.41 8.58Z" fill="currentColor"/>
                    </svg>
                  </div>
                </td>
              }

              @for (column of columnDefs(); track column) {

                @let isColumnVisible = column.visible || column.visible == undefined || false;
                @if (isColumnVisible) {
                  <td
                    class="px-4 py-3 border-b border-gray-100 relative text-body2 text-gray-800"
                    [ngClass]="[getThTrClass(column)]">

                    @switch (column.type) {
                      @default {
                        <span class="text-body2 text-neutral-700"
                              [ngClass]="column.textConfig?.textColorClass">{{ getPropertyValue(item, column) }}</span>
                      }
                      @case ('text') {
                        <span class="text-body2 text-neutral-700 text-nowrap"
                              [ngClass]="column.textConfig?.textColorClass">{{ getPropertyValue(item, column) }}</span>
                      }
                      @case ('date') {
                        <div class="flex items-center gap-3" [ngClass]="[getFlexJustify(column)]">
                          @if (column.dateConfig?.showIcon == undefined ? true : (column.dateConfig?.showIcon)) {
                            <svg class="h-4 w-4 text-neutral-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                 fill="none">
                              <path
                                d="M19 19H5V8H19M16 1V3H8V1H6V3H5C3.89 3 3 3.89 3 5V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V5C21 3.89 20.1 3 19 3H18V1M17 12H12V17H17V12Z"
                                fill="currentColor"/>
                            </svg>
                          }
                          <span
                            class="text-body2 text-nowrap">{{ getPropertyValue(item, column) | date:column.dateConfig?.dateFormat != null ? column.dateConfig?.dateFormat : 'MMM d, y, h:mm a' }}</span>
                        </div>
                      }
                      @case ('badge') {
                        @let badgeProperty = getBadgeProperty(item, column);
                        <div class="flex items-center" [ngClass]="getFlexJustify(column)">
                          <app-status-badge [backgroundColorClass]="badgeProperty?.backgroundColorClass ?? null"
                                            [indicatorColor]="badgeProperty?.indicatorColorClass ?? null"
                                            [borderColorClass]="badgeProperty?.borderColorClass ?? null"
                                            [textColorClass]="badgeProperty?.textColorClass ?? null"
                                            [isUpperCase]="true"
                                            [status]="badgeProperty?.displayText ?? 'Unknown'">
                          </app-status-badge>
                        </div>
                      }
                      @case ('custom') {
                        @if (column.component) {
                          <div class="flex items-center">
                            <app-dynamic-renderer
                              [component]="column.component"
                              [rowData]="item"
                              (actionPerformed)="onCellActionPerformed($event)">
                            </app-dynamic-renderer>
                          </div>
                        }
                      }
                      @case ('actions') {

                        <div class="flex items-center gap-3" [ngClass]="getFlexJustify(column)">
                          @if (column.actionsConfig?.iconActions) {
                            @for (iconAction of column.actionsConfig?.iconActions ?? []; track iconAction) {
                              <div
                                (click)="_onActionClicked(iconAction.actionKey, item, $event)"
                                class="flex items-center justify-center h-[36px] w-[36px] cursor-pointer rounded-full hover:bg-neutral-100">
                                <app-svg-icon class="text-neutral-500"
                                              [matTooltip]="iconAction.label"
                                              matTooltipClass="mt-2"
                                              [src]="iconAction.iconPath"
                                              [size]="20"></app-svg-icon>
                              </div>
                            }
                          }
                          @if (column.actionsConfig?.threeDotMenuActions) {
                            <app-context-menu (onActionClicked)="_onActionClicked($event, item, null)"
                                              [actions]="getContextMenuActions(column.actionsConfig?.threeDotMenuActions ?? [])"></app-context-menu>
                          }

                          @if (column.actionsConfig?.textMenuActions) {
                            @for (textAction of column.actionsConfig?.textMenuActions ?? []; track textAction) {
                              <app-text-button-small
                                (click)="_onActionClicked(textAction.actionKey, item, $event)">{{ textAction.label }}
                              </app-text-button-small>
                            }
                          }

                        </div>

                      }

                    }

                  </td>
                }

              }

              @if (enableColumnsConfig()) {
                <td
                  class="px-1 py-3 border-b border-gray-100 w-min">
                </td>
              }
            </tr>

            @if (expandableComponent()) {
              @if (i == expandedRowIndex()) {
                <tr class="transition-all duration-700">
                  <td colspan="100">
                    <div class="mx-4 my-4 p-4 bg-neutral-50 rounded-lg">
                      <app-dynamic-renderer
                        [component]="expandableComponent()"
                        [rowData]="item"
                        (actionPerformed)="onCellActionPerformed($event)">
                      </app-dynamic-renderer>
                    </div>

                  </td>
                </tr>
              }
            }

          }

        </tbody>
      }

      <tbody class="h-full"></tbody>


    </table>
  </div>

  <app-pagination
    [pageSize]="pageSize()"
    [totalItems]="state().response()?.totalCount ?? 0"
    (pageChange)="onPageChange($event)">
  </app-pagination>
</div>

<ng-template cdkPortal>
  <div
    class="flex flex-col gap-3 px-2 py-2 bg-white rounded-md max-h-[400px] border border-neutral-200 shadow-2">
    <div class="flex flex-col overflow-y-scroll">
      @for (column of columnDefs(); track column; let index = $index) {
        @if (column.type != 'actions') {
          <div class="grow flex items-center min-w-[200px] px-1">
            @let isColumnVisible = column.visible || column.visible == undefined || false;
            <app-checkbox [title]="column.title" [ngModel]="isColumnVisible"
                          (valueChanged)="onColumnVisibleOrHide($event, column)"></app-checkbox>
          </div>
        }
      }
    </div>

    <span (click)="onSelectAllColumnClicked()" class="px-4 text-overline text-info-500 cursor-pointer">Select All</span>
  </div>
</ng-template>

