<div class="app-container !p-0">
  <div class="flex p-4 gap-5 justify-between bg-white items-center">
    <app-page-header title="Terminals" description="View and manage all Terminals."></app-page-header>

    <div class="flex gap-3 items-center">
      <app-button (click)="onNewTerminalClicked()" [iconSrc]="'assets/icons/plus.svg'">
        New Terminal
      </app-button>
    </div>
  </div>

  <div class="grow m-4 overflow-y-scroll">
    <app-data-table
      [state]="getTerminalListState"
      [columnDefs]="columnDefs"
      (onActionPerformed)="onActionClicked($event)"
      [enableColumnsConfig]="true">
    </app-data-table>
  </div>
</div>
