import {Component, inject, OnInit, output} from "@angular/core";
import {ButtonComponent} from "@shared/components/button/button.component";
import {AppSvgIconComponent} from "@shared/components/app-svg-icon/app-svg-icon.component";
import {OutlineTextInputComponent} from "@shared/inputs/outline-text-input/outline-text-input.component";
import {DropdownComponent} from "@shared/inputs/dropdown/dropdown.component";
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import {RadioButtonComponent} from "@shared/inputs/radio-button/radio-button.component";
import {BaseComponent} from "@shared/base/base-component";
import {State} from "@shared/base/base-state";
import {CheckboxComponent} from "@shared/inputs/checkbox/checkbox.component";
import {CommonService} from "@core/services/common/common.service";
import {LicenseClassesResponse} from "@core/services/common/license-classes/license-classes-response";
import {
  CountriesResponse,
  Country,
  CountryState,
} from "@core/services/common/countries/countries-response";
import {TokenStoreService} from "@core/services/token/token-store.service";
import {DateUtils} from "@shared/utils/date-utils";
import {
  InputDateFormat,
  OutlineDateInputComponent
} from "@shared/inputs/outline-date-input/outline-date-input.component";
import type {
  GetDriverApplicationGeneralResponse,
  GetDriverApplicationLicenseItem,
  GetDriverApplicationLicenseParams,
  GetDriverApplicationLicenseResponse,
  SaveDriverApplicationLicense,
  SaveDriverApplicationLicenseRequest,
  SaveDriverApplicationLicenseResponse
} from "@core/api/model";
import {ApiService} from "@core/api/api.service";

@Component({
  selector: "app-applicant-license-info",
  templateUrl: "./save-applicant-license-info.component.html",
  standalone: true,
  imports: [
    ButtonComponent,
    AppSvgIconComponent,
    OutlineTextInputComponent,
    DropdownComponent,
    ReactiveFormsModule,
    RadioButtonComponent,
    CheckboxComponent,
    OutlineDateInputComponent,
  ],
  styleUrls: ["./save-applicant-license-info.component.scss"],
})
export class SaveApplicantLicenseInfoComponent
  extends BaseComponent
  implements OnInit {
  onNextClick = output<void>();
  onPreviousClick = output<void>();

  formBuilder = inject(FormBuilder);
  commonService = inject(CommonService);
  tokenService = inject(TokenStoreService);

  countryListState = new State<CountriesResponse>();
  licenseClassesState = new State<LicenseClassesResponse>();
  licensesState = new State<GetDriverApplicationLicenseResponse>();
  saveLicenseState = new State<SaveDriverApplicationLicenseResponse>();
  applicantGeneralInfoState = new State<GetDriverApplicationGeneralResponse>();
  apiService = inject(ApiService);

  licenseForm!: FormGroup;
  currentLicenseForm!: FormGroup;
  licensesFormArray!: FormArray;

  statesList: CountryState[][] = [];

  ngOnInit(): void {
    this.initForm();
    this.getCountries();
    this.getLicenseClasses();
    this.getLicenses();
  }

  private initForm() {
    this.licensesFormArray = this.formBuilder.array([]);
    this.licenseForm = this.formBuilder.group({
      licenses: this.licensesFormArray,
    });
  }

  getApplicantGeneralInfo() {
    this.executeRequest<GetDriverApplicationGeneralResponse>({
      state: this.applicantGeneralInfoState,
      request: this.apiService.getDriverApplicationGeneral(
        this.tokenService.getDriverApplicationId() ?? ""
      ),
      onSuccess: response => {
        let generalInfo = response.data;
        this.currentLicenseForm.controls["firstName"].setValue(this.currentLicenseForm.controls["firstName"].value ?? generalInfo?.firstName);
        this.currentLicenseForm.controls["lastName"].setValue(this.currentLicenseForm.controls["lastName"].value ?? generalInfo?.lastName);
      }
    });
  }

  getCountries() {
    this.executeRequest<CountriesResponse>({
      state: this.countryListState,
      request: this.commonService.getCountries(),
    });
  }

  private getLicenseClasses() {
    this.executeRequest<LicenseClassesResponse>({
      state: this.licenseClassesState,
      request: this.commonService.getLicenseClasses(),
    });
  }

  private getLicenses() {
    let request: GetDriverApplicationLicenseParams = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
    };
    this.executeRequest<GetDriverApplicationLicenseResponse>({
      state: this.licensesState,
      request: this.apiService.getDriverApplicationLicense(request),
      onSuccess: (response) => {
        let licenses = response.data ?? [];
        this.populateForm(licenses);
        this.getApplicantGeneralInfo();
      },
    });
  }

  populateForm(licenses: GetDriverApplicationLicenseItem[]) {
    let currentLicense = licenses.find((license) => license.isCurrent) ?? null;
    let additionalLicenses = licenses.filter((license) => !license.isCurrent);

    this.currentLicenseForm = this.createLicenseForm(currentLicense, true);
    this.licensesFormArray.push(this.currentLicenseForm);

    additionalLicenses.forEach((license) => {
      let newForm = this.createLicenseForm(license, false);
      this.licensesFormArray.push(newForm);
    });
  }

  createLicenseForm(license: GetDriverApplicationLicenseItem | null, isCurrentLicense: boolean): FormGroup {
    let countries = this.countryListState.response()?.data ?? [];
    let matchedCountry = countries.find(
      (country) => country.countryName === license?.country
    );
    if (matchedCountry) {
      this.statesList.push(matchedCountry.states);
    }

    let dateUtils = new DateUtils();
    let generalInfo = this.applicantGeneralInfoState.response()?.data;
    return this.formBuilder.group({
      // TODO
      // id: [license?.id],
      firstName: [license?.firstName ?? generalInfo?.firstName, Validators.required],
      lastName: [license?.lastName ?? generalInfo?.lastName, Validators.required],
      licenseNo: [license?.licenseNo, Validators.required],
      class: [license?.class, Validators.required],
      country: [license?.country, Validators.required],
      state: [license?.state, Validators.required],
      issuedDate: [
        dateUtils.convertToMMDDYYYY(license?.issueDate),
        Validators.required,
      ],
      expirationDate: [
        dateUtils.convertToMMDDYYYY(license?.expirationDate),
        Validators.required,
      ],
      hasPlacardedHazmat: [license?.hasPlacardedHazmat ?? false],
      hasTankVehicles: [license?.hasTankVehicles ?? false],
      hasPassengers: [license?.hasPassengers ?? false],
      hasDoubleOrTripleTrailers: [license?.hasDoubleOrTripleTrailers ?? false],
      hasSchoolBus: [license?.hasSchoolBus ?? false],
      hasPlacardedHazmatAndTankVehicles: [
        license?.hasPlacardedHazmatAndTankVehicles ?? false,
      ],
      hasNameChange: [license?.hasNameChanged ?? false],
      previousName: [license?.previousName],
      isCurrent: [isCurrentLicense],
    });
  }

  onDeleteClicked(index: number): void {
    if (this.licensesFormArray.length > 1)
      this.licensesFormArray.removeAt(index);
  }


  onCountrySelected(selectedItem: Country | null, index: number) {
    let states = selectedItem?.states ?? [];
    const formGroup = this.licensesFormArray.at(index) as FormGroup;
    formGroup.patchValue({state: null});
    this.statesList[index] = states;
  }

  onAddPreviousLicenseClicked() {
    let newForm = this.createLicenseForm(null, false);
    this.licensesFormArray.push(newForm);
  }

  onSaveAndNextClicked() {
    this.validateForm(this.licenseForm);
    if (this.licenseForm.invalid) {
      return;
    }

    let request: SaveDriverApplicationLicenseRequest = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
      licenses: [],
    };

    let dateUtils = new DateUtils();
    this.licensesFormArray.controls.forEach((control, index) => {
      let formValue = control.value;
      let license: SaveDriverApplicationLicense = {
        displayOrder: index + 1,
        firstName: formValue.firstName,
        lastName: formValue.lastName,
        licenseNo: formValue.licenseNo,
        class: formValue.class,
        country: formValue.country,
        state: formValue.state,
        issueDate: dateUtils.convertToDateTimeString(formValue.issuedDate) ?? '',
        expirationDate: dateUtils.convertToDateTimeString(formValue.expirationDate) ?? '',
        isCurrent: formValue.isCurrent,
        hasPlacardedHazmat: formValue.hasPlacardedHazmat,
        hasTankVehicles: formValue.hasTankVehicles,
        hasPassengers: formValue.hasPassengers,
        hasDoubleOrTripleTrailers: formValue.hasDoubleOrTripleTrailers,
        hasSchoolBus: formValue.hasSchoolBus,
        hasPlacardedHazmatAndTankVehicles: formValue.hasPlacardedHazmatAndTankVehicles,
        hasNameChanged: formValue.hasNameChange,
        previousName: formValue.previousName,
      };
      request.licenses.push(license);
    });

    this.executeRequest<SaveDriverApplicationLicenseResponse>({
      state: this.saveLicenseState,
      request: this.apiService.saveDriverApplicationLicense(request),
      handleSuccess: true,
      handleError: true,
      showLoader: true,
      onSuccess: (response) => {
        this.onNextClick.emit();
      },
    });
  }

  onPreviousClicked() {
    this.onPreviousClick.emit();
  }

  protected readonly InputDateFormat = InputDateFormat;
}
