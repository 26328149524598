import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ToasterComponent } from "@shared/components/toast/components/toaster/toaster.component";
import { LoaderComponent } from "@shared/components/loader/loader.component";
import { ResponsiveHelperComponent } from "@shared/components/responsive-helper/responsive-helper.component";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ToasterComponent, LoaderComponent, ResponsiveHelperComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {

}
